import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserDto } from 'src/types/dtos/user.types';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private myRoute: Router) { }

  /**
   * Stores the user's authentication token in the local storage.
   * @param data - The user data object containing the authentication token.
   */
  sendToken(data: UserDto): void {
    localStorage.setItem('loginToken', data.authentication_token);
  }

  getToken(): any {
    return localStorage.getItem('loginToken');
  }
  isLoggedIn(): any {
    return this.getToken() !== null;
  }
  logout(): any {
    localStorage.removeItem('loginToken');
    localStorage.removeItem('userRole');
    this.myRoute.navigate(['/login']);
  }
}
