<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<ngx-spinner name="calculate" type="timer" size="medium"><h4 style="color: #fff;">Data is being calculated...</h4></ngx-spinner>
<div class="simulationMap">
  <div class="row m-0">
    <div class="col-12 p-0">
      <div class="mapCls">
        <agm-map [latitude]="latitude"
                 [longitude]="longitude"
                 [zoom]="zoom"
                 [disableDefaultUI]="false"
                 [gestureHandling]="'cooperative'"
                 [scrollwheel] ="true"
                 [fitBounds]="mapBound">
            <agm-marker
              *ngFor="let m of markers; let i = index"
              [latitude]="m.lat"
              [longitude]="m.lng"
              [animation]="m.animation"
              [iconUrl]="m.icon"
              [agmFitBounds]="true">
              <agm-info-window [disableAutoPan]="false">
                <div>
                  {{m.label}}
                </div>
              </agm-info-window>
            </agm-marker>
            <agm-marker
              *ngFor="let m of vehicleMarkers; let i = index"
              [latitude]="m.lat"
              [longitude]="m.lng"
              [animation]="m.animation"
              [iconUrl]="m.icon"
              [agmFitBounds]="false">
            </agm-marker>
            <agm-polyline *ngFor="let polyline of polylines;let i = index;" [visible]="true" [strokeColor]="polyline.color">
              <agm-polyline-point *ngFor="let point of polyline.path" [latitude]="point.lat" [longitude]="point.lng">
              </agm-polyline-point>
            </agm-polyline>
        </agm-map>
      </div>
    </div>
  </div>
</div>
<ng-template #noResultsTemplate>
  <!--<td [attr.colspan]="columns.length" class="no-results">
    I didn't find any results for you :(
  </td>-->
</ng-template>

<app-modal #downloadReportModal class="modal-demo productFormCls" id="showReportModal" [backdrop]="false" [inViewport]="true">
  <ng-container class="app-modal-header">Download Simulation Report<i (click)="closeDownloadReportModal()"
    class="ui-icon dt-icon-close crossCls"></i></ng-container>
  <ng-container class="app-modal-body">
    <form>
      <div class="form-group">
        <label>Please select the duration you want to generate the report</label>
        <div class="form-row">
          <div class="form-group col-6">
            <label>Start Day</label>
            <input type="number" class="form-control"  id="startDay" name="startDay" [(ngModel)]="startDay">
          </div>
          <div class="form-group col-6">
            <label>End Day</label>
            <input type="number" class="form-control" id="endDay" name="endDay" [(ngModel)]="endDay">
          </div>
        </div>
      </div>
      <div class="form-group choose-facility">
        <label>Choose Facilities:</label>
        <div *ngFor="let facility of facilities" class="report-checkbox-container">
          <input class="report-checkbox" type="checkbox" [(ngModel)]="facility.selected" name="{{facility.name}}"> {{facility.name}}
        </div>
      </div>
      <div class="form-group choose-facility">
        <label>Choose Report Type:</label>
        <!-- <div>
          <input type="radio" id="plReport" name="reportType" [(ngModel)]="reportType" value="pnl">
          <label style="margin-left: 10px;" for="plReport">P&L Report</label>
        </div> -->
        <div>
          <input type="radio" id="missionReport" name="reportType" [(ngModel)]="reportType" value="mission">
          <label style="margin-left: 10px;" for="missionReport">Mission Report</label>
        </div>
      </div>

    </form>
  </ng-container>
  <ng-container class="app-modal-footer">
    <button type="button" class="btn btn-outline-primary" (click)="downloadReport()">Download</button>
  </ng-container>
</app-modal>

<!-- side bar popup -->
<app-modal #sidebarModal id="sidebarPopup" [backdrop]="false"
  [inViewport]="false" class="sidebar-modal">
  <ng-container class="app-modal-header"></ng-container>
  <ng-container class="app-modal-body">
    <div class="p-1">
      <div>
        <div class="head">
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Supply Chain</label>
            <div class="col-sm-8">
              <select class="form-control" [(ngModel)]="selectedSupplyChainId" (change)="supplyChainChange(selectedSupplyChainId)">
                <option *ngFor="let option of supplyChainList" [ngValue]="option.id">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
           <label class="col-sm-4 col-form-label">Speed</label>
           <div class="col-sm-8">
             <select class="form-control" [(ngModel)]="speedMultiplier" (change)="updateSpeedMultiplier(speedMultiplier)">
               <option *ngFor="let option of speedMultipliers" [ngValue]="option">
                 {{option.label}}
               </option>
             </select>
           </div>
         </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Time Interval</label>
            <div class="col-sm-8">
              <select class="form-control" [(ngModel)]="selectedInterval" (change)="speedChange(selectedInterval)">
                <option *ngFor="let option of intervalList" [ngValue]="option">
                  {{option.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-4 col-form-label">Days</label>
            <div class="col-sm-8">
              <input type="number" class="form-control" [(ngModel)]="noOfDays">
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="text-right top-btns">
                <button (click)="createGetSimulationData()" [disabled]="playBtnDisable || simulationFacility?.length <= 0" class="btn btn-sm btn-outline-primary">
                  <i class="fa fa-play" aria-hidden="true"></i>
                  <span *ngIf="!stopClicked"> Play</span>
                  <span *ngIf="stopClicked"> Re-play</span>
                </button>
                <button (click)="pauseSimulation()" [disabled]="pauseBtnDisable" class="btn btn-sm btn-outline-primary">
                  <i class="fa fa-pause" aria-hidden="true"></i>
                  Pause
                </button>
                <button (click)="stopSimulation()" [disabled]="stopBtnDisable" class="btn btn-sm btn-outline-primary">
                  <i class="fa fa-stop" aria-hidden="true"></i>
                  Stop
                </button>
              </div>
            </div>
           </div>
           <br/>
           <div *ngIf="stopClicked" class="row">
             <div class="col-sm-12">
               <div class="text-right top-btns">
                 <button (click)="downloadReportData()" class="btn btn-sm btn-outline-primary">
                   <span> Export Results to Excel</span>
                 </button>
 
                 <button class="btn btn-sm btn-outline-primary" (click)="openDownloadReportModal()">
                   <span>Generate P&L Report</span>
                 </button>
               </div>
 
             </div>
         </div>
        </div>
        <hr>
 
        <div class="sideContent">
          <div class="tabCls">
            <p class="simformatedTime" *ngIf="formatedTime">{{formatedTime}}</p>
            <tabset *ngIf="firstIntervalData && firstIntervalData.TotalFacility?.length > 0">
              <tab heading="Facilities" id="tab1">
                <accordion [isAnimated]="true" [closeOthers]="true">
                  <accordion-group *ngFor="let facility of firstIntervalData.TotalFacility; let i = index;" (isOpenChange)="accordionOpen($event, i)" [isOpen]="i === selectedAccordion">
                    <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
                      <div class="pull-left float-left text-left">
                        {{facility.Name}}
                       <br>
                        Total Carbon: {{facility.CarbonOutput}} kg
                      </div>
                      <span class="badge badge-secondary float-right pull-right" style="font-size: 16px;background: none;">${{facility.OperationCost.toLocaleString()}}</span>
                    </button>
                    <div class="productTable">
                      <ngx-table [id]="facility.ID" [configuration]="configuration1"
                                 [data]="facility.products"
                                 [noResultsTemplate]="noResultsTemplate"
                                 [columns]="columns">
                      </ngx-table>
                    </div>
                    <div class="dataChart">
                      <tabset>
                        <tab heading="OnHand" (selectTab)="onSelectTab(0)" [active]="tabActive0" id="tab11">
                          <div class="chartScroll" style="width: 100%;overflow: auto;" *ngIf="facility && facility.onHandAmount">
                            <div style="min-height: 375px;width: 800px;overflow: auto;">
                              <canvas baseChart width="800" height="400"
                                      [datasets]="facility.onHandAmount"
                                      [labels]="lineChartLabels"
                                      [options]="lineChartOptions"
                                      [colors]="lineChartColors"
                                      [legend]="lineChartLegend"
                                      [chartType]="lineChartType"
                                      [plugins]="lineChartPlugins">
                              </canvas>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </tab>
                        <tab heading="In" (selectTab)="onSelectTab(1)" [active]="tabActive1">
                          <div class="chartScroll" style="width: 100%;overflow: auto;" *ngIf="facility && facility.in">
                            <div style="min-height: 375px;width: 800px;overflow: auto;">
                              <canvas baseChart width="800" height="400"
                                      [datasets]="facility.in"
                                      [labels]="lineChartLabels"
                                      [options]="lineChartOptions"
                                      [colors]="lineChartColors"
                                      [legend]="lineChartLegend"
                                      [chartType]="lineChartType"
                                      [plugins]="lineChartPlugins">
                              </canvas>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </tab>
                        <tab heading="Out" (selectTab)="onSelectTab(2)" [active]="tabActive2">
                          <div class="chartScroll" style="width: 100%;overflow: auto;" *ngIf="facility && facility.out">
                            <div style="min-height: 375px;width: 800px;overflow: auto;">
                              <canvas baseChart width="800" height="400"
                                      [datasets]="facility.out"
                                      [labels]="lineChartLabels"
                                      [options]="lineChartOptions"
                                      [colors]="lineChartColors"
                                      [legend]="lineChartLegend"
                                      [chartType]="lineChartType"
                                      [plugins]="lineChartPlugins">
                              </canvas>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </tab>
                      </tabset>
                    </div>
                  </accordion-group>
                </accordion>
              </tab>
              <tab heading="Vehicles">
               <div class="vehicleTable simulationCls">
                 <ngx-table [id]="firstIntervalData.CurrentTimeStamp" [configuration]="vehicleTableConfig"
                            [data]="firstIntervalData.Vehicles"
                            [noResultsTemplate]="noResultsTemplate"
                            [columns]="vehicleTableColumns">
                 </ngx-table>
               </div>
              </tab>
              <tab heading="Products">
                <div class="productTableCls simulationCls">
                  <ngx-table [id]="firstIntervalData.CurrentTimeStamp" [configuration]="productTableConfig"
                             [data]="firstIntervalData.TotalFacilityScheduleProducts"
                             [noResultsTemplate]="noResultsTemplate"
                             [columns]="productTableColumns">
                  </ngx-table>
                </div>
              </tab>
            </tabset>
          </div>
        </div>
      </div>
     </div>
  </ng-container>
</app-modal>
<!-- side bar popup -->