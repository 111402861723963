<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="libraryCls container-custom">
  <div class="row">
    <div class="col-12">
      <div class="libraryTable">
        <h4> Library
          <!--<a (click)="openAddModal('add')" class="btn btn-primary addButton">
            <i class="fa fa-plus" aria-hidden="true"></i>
            Add New
          </a>-->
        </h4>
        <p>After importing you can go to your Account page and begin editing them.</p>
        <ngx-table #librarytable [configuration]="libraryConfig"
                   [data]="libraryData"
                   [columns]="libraryColumns"
                   (event)="onEvent($event)">
        </ngx-table>

        <ng-template #actionTpl let-row let-rowIndex="rowIndex">
          <div class="btn btn-sm btn-outline-danger waves-effect waves-light pull-right" *ngIf="isAdmin()" (click)="deleteLibrary()">Remove</div>
          <!--<div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right" (click)="openAddModal('edit')">Edit</div>-->
          <div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right" (click)="openImportModal()">Import</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- import library modal start -->
<div id="libraryImportModal" class="modal fade" tabindex="-1" role="dialog" data-backdrop="static" data-keyboard='false' aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-sizes-name2" class="modal-title pull-left">
          <span *ngIf="selectedRowData && selectedRowData.name">Import:  {{selectedRowData.name}}</span>
        </h5>
        <button type="button" class="close pull-right" aria-label="Close" id="supplyChainCloseBtn" (click)="openImportModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Give a Name<span class="required">*</span></label>
          <input type="text" [(ngModel)]="libraryName" placeholder="Enter here" class="form-control">
        </div>
        <button [disabled]="libraryName.length < 3" (click)="importLibrary()" type="button" class="btn btn-success pull-right">
          <span>Save</span>
        </button>
      </div>
    </div>
  </div>
</div>
<!-- import library modal end -->
