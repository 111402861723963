<div class="container" style="height: calc(100vh - 80px);overflow: scroll;">
  <div class="row">
    <div class="col-12">
      <div class="resetPassCls">
        <div>
          <h4>
            Reset your password here.
          </h4>
          <p>Fill out the form below and instructions to reset your password.</p>
          <form [formGroup]="resetForm" (submit)="onSubmit()" autocomplete="off">
            <div class="form-group">
              <div class="input-group">
                <input [(ngModel)]="password" formControlName="password" class="form-control" placeholder="Password" type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" >
                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                  <div *ngIf="f.password.errors.required">Password is required</div>
                </div>
              </div> <!-- input-group.// -->
            </div> <!-- form-group// -->
            <div class="form-group">
              <div class="input-group">
                <input [(ngModel)]="confPassword" formControlName="confirmPassword" class="form-control" placeholder="Confirm Password" type="password" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" >
                <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                  <div *ngIf="f.confirmPassword.errors.required">Password is required</div>
                </div>
              </div> <!-- input-group.// -->
            </div> <!-- form-group// -->
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-block" [disabled]="!password"> Reset My Password  </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!--<img src="assets/pageNotFound.jpeg" alt="page not found...">-->
  </div>
</div>
