<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="facilityTypeCls container-custom">
  <div class="row">
    <div class="col-6">
      <div class="facilityTable">
        <h4> Facility Types
          <a (click)="openTypeAddModal('facility', 'add', '')" class="btn btn-primary addButton">
            <i class="fa fa-plus" aria-hidden="true"></i>
            Add New
          </a>
        </h4>
        <ngx-table #table [configuration]="facilityTypeConfig"
                   [data]="facilityTypeData"
                   [columns]="columns"
                   (event)="onEvent($event , 'facility')">
        </ngx-table>

        <ng-template #actionTpl let-row let-rowIndex="rowIndex">
          <div class="btn btn-sm btn-outline-danger waves-effect waves-light pull-right" (click)="deleteType('facility', rowIndex)">Delete</div>
          <div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right" (click)="openTypeAddModal('facility', 'edit', rowIndex)">Edit</div>
        </ng-template>
      </div>
    </div>
    <div class="col-6">
      <div class="facilityTable">
        <h4> Vehicle Types
          <a (click)="openTypeAddModal('vehicle', 'add', '')" class="btn btn-primary addButton">
            <i class="fa fa-plus" aria-hidden="true"></i>
            Add New
          </a>
        </h4>
        <ngx-table #vehicleTable [configuration]="vehicleTypeConfig"
                   [data]="vehicleTypeData"
                   [columns]="vehicleColumns"
                   (event)="onEvent($event,'vehicle')">
        </ngx-table>

        <ng-template #vehicleActionTpl let-row let-rowIndex="rowIndex">
          <div class="btn btn-sm btn-outline-danger waves-effect waves-light pull-right" (click)="deleteType('vehicle', rowIndex)">Delete</div>
          <div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right" (click)="openTypeAddModal('vehicle', 'edit', rowIndex)">Edit</div>
        </ng-template>
        <ng-template #imgTpl let-row let-rowIndex="rowIndex" let-column="column">
          <div>
            <img [src]="row.icon" loading="lazy" alt="image" style="width: 20%;"/>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- Create Type Add modal start -->
<div bsModal id="typeAddModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-sizes-name2" class="modal-title pull-left">
          <span *ngIf="modalTypeFn === 'add'">Add New </span>
          <span *ngIf="modalTypeFn === 'edit'">Edit </span>
          <span *ngIf="modalType === 'facility'"> Facility Type</span>
          <span *ngIf="modalType === 'vehicle'"> Vehicle Type</span>
        </h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeTypeAddModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Name</label>
          <input type="text" [(ngModel)]="typeName" class="form-control">
        </div>
        <div class="form-group">
          <label>Icon</label>
          <input type="file" #fileUpload id="fileUpload" (change)="preview()" name="fileUpload"
            accept="image/x-png,image/gif,image/jpeg" />
        </div>
        <div *ngIf="imagePreview" class="imgPreview text-center">
          <img id="imgP" [src]="imagePreview" alt="" style="width: 25%">
        </div>
        <div *ngIf="modalType === 'vehicle'">
          <div class="form-group">
            <div>
              <label>Transport Method</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="road" name="transportMethod" [(ngModel)]="transportMethod"
                [value]="true" required />
              <label class="form-check-label" for="road">By Road</label>
            </div>
            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" id="shipAir" name="transportMethod" [(ngModel)]="transportMethod"
                [value]="false" required />
              <label class="form-check-label" for="shipAir">By Ship/Air</label>
            </div>
          </div>
      
          <div class="form-group">
            <label for="speed">Speed</label>
            <input type="number" id="speed" class="form-control" name="speed" [(ngModel)]="speed" required />
          </div>
          <div class="form-group">
            <label for="carryVolume">Carry Volume</label>
            <input type="number" id="carryVolume" class="form-control" name="carryVolume" [(ngModel)]="carryVolume"
              required />
          </div>
          <div class="form-group">
            <label for="maxWeight">Max Weight</label>
            <input type="number" id="maxWeight" class="form-control" name="maxWeight" [(ngModel)]="maxWeight" required />
          </div>
          <div class="form-group">
            <label for="costPerKm">Cost per km</label>
            <input type="number" id="costPerKm" class="form-control" name="costPerKm" [(ngModel)]="costPerKm" required />
          </div>
          <div class="form-group">
            <label for="carbonPerKm">Carbon per km</label>
            <input type="number" id="carbonPerKm" class="form-control" name="carbonPerKm" [(ngModel)]="carbonPerKm"
              required />
          </div>
        </div>
        <div *ngIf="modalType === 'facility'">
          <div class="form-group">
            <label for="speed">Storage capacity</label>
            <input type="number" id="storageCapacity" class="form-control" name="storageCapacity" [(ngModel)]="storageCapacity" required />
          </div>      
          <div class="form-group">
            <label for="speed">Total area</label>
            <input type="number" id="totalArea" class="form-control" name="totalArea" [(ngModel)]="totalArea" required />
          </div>
          <div class="form-group">
            <label for="carryVolume">Labor cost</label>
            <input type="number" id="laborCost" class="form-control" name="laborCost" [(ngModel)]="laborCost"
              required />
          </div>
          <div class="form-group">
            <label for="maxWeight">Energy cost</label>
            <input type="number" id="energyCost" class="form-control" name="energyCost" [(ngModel)]="energyCost" required />
          </div>
          <div class="form-group">
            <label for="costPerKm">Opt cost</label>
            <input type="number" id="optCost" class="form-control" name="optCost" [(ngModel)]="optCost" required />
          </div>
          <div class="form-group">
            <label for="carbonPerKm">Rent cost</label>
            <input type="number" id="rentCost" class="form-control" name="rentCost" [(ngModel)]="rentCost"
              required />
          </div>
          <div class="form-group">
            <label for="carbonPerKm">Carbon</label>
            <input type="number" id="carbon" class="form-control" name="carbon" [(ngModel)]="carbon"
              required />
          </div>
        </div>
      
        <button [disabled]="typeName.length <= 3" (click)="addType(modalType, modalTypeFn)" type="button"
          class="btn btn-success">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Create Type Add modal end -->
