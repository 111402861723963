<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="timeIntervalCls container-custom-time">
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      <div class="timeTable">
        <h4> Time Intervals
          <a (click)="openIntervalAddModal('add')" class="btn btn-primary addButton">
            <i class="fa fa-plus" aria-hidden="true"></i>
            Add New
          </a>
        </h4>
        <ngx-table #table [configuration]="intervalConfig"
                   [data]="intervalData"
                   [columns]="intervalcolumns"
                   (event)="onEvent($event)">
        </ngx-table>

        <ng-template #actionTpl let-row let-rowIndex="rowIndex">
          <div class="btn btn-sm btn-outline-danger waves-effect waves-light pull-right" (click)="deleteInterval()">Delete</div>
          <div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right" (click)="openIntervalAddModal('edit')">Edit</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- Create interval Add modal start -->
<div bsModal id="IntervalAddModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-sizes-name2" class="modal-title pull-left">
          <span *ngIf="modalTypeFn === 'add'">Add New </span>
          <span *ngIf="modalTypeFn === 'edit'">Edit </span>
          Interval
        </h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeIntervalAddModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Name</label>
          <input type="text" [(ngModel)]="name" class="form-control">
        </div>
        <div class="form-group">
          <label>Value (In Minutes)</label>
          <input type="number" [(ngModel)]="value" class="form-control">
        </div>
        <button [disabled]="name.length <= 3" (click)="addUpdateInterval(modalTypeFn)" type="button" class="btn btn-success">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Create interval Add modal end -->
