import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { API, APIDefinition, Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { NgxSpinnerService } from 'ngx-spinner';
import { SupplyChainService } from 'src/app/services/supply-chain.service';
import { SupplyChainFacilities } from 'src/types/services/supply-chain-service.types';
import Swal from 'sweetalert2';
import { DashboardService } from '../../services/dashboard.service';
import { CloneService } from 'src/app/services/clone.service';
declare var $: any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  @ViewChild('table', { static: true }) private table!: APIDefinition;
  @ViewChild('tableHistory', { static: true }) private tableHistory!: APIDefinition;
  @ViewChild('actionTpl', { static: true }) actionTpl!: TemplateRef<any>;
  @ViewChild('actionTplHistory', { static: true }) actionTplHistory!: TemplateRef<any>;

  public configuration1: Config | any;
  public configuration2: Config | any;
  public columns: Columns[] | any;
  public columnsHistory: Columns[] | any;
  public selected = new Set();
  data: any;
  supplyChainHistory: any;
  supplyChainName = '';
  supplyHistoryName = '';
  selectedSupplyChain: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  selectedSupplyRowId: any = [];
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef | any;
  files: any = [];
  filesName = ['facility.csv', 'supplychain.csv', 'product.csv', 'vehicle.csv'];
  selectedHistoryRow: any;
  selectedSupplyRow: any;
  constructor(
    private dashService: DashboardService,
    private spinner: NgxSpinnerService,
    private readonly supplyChainService: SupplyChainService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private clonerService: CloneService
  ) { }

  ngOnInit(): void {
    this.getSupplyChainList();
    this.getSupplyChainHistory();
    this.configuration1 = { ...DefaultConfig };
    this.configuration1.checkboxes = true;
    this.configuration2 = { ...DefaultConfig };
    // ... etc.
    this.columns = [
      { key: 'name', title: 'Supply Chain' },
      { key: 'updated_at', title: 'Date Last Updated', orderEventOnly: true },
      { key: 'action', title: 'Actions', cellTemplate: this.actionTpl },
    ];
    this.columnsHistory = [
      { key: 'name', title: 'Saved Supply Chain' },
      { key: 'created_at', title: 'Date Added' , orderEventOnly: true },
      { key: 'action', title: 'Actions', cellTemplate: this.actionTplHistory },
    ];
  }


  getSupplyChainList(): void {
    this.dashService.getSupplyChainList().subscribe((res: any) => {
      if (res && res.supply_chain) {
        this.data = res.supply_chain.map((obj: { updated_at: any }) => ({
          ...obj,
          updated_at_original: new Date(obj.updated_at),
          updated_at: moment(obj.updated_at).format('MMMM D, YYYY h:mm A')
        }));
        this.cdr.detectChanges();
      }
    });
  }

  

  openSupplyChainModal(): void {
    $('#supplyChainModal').modal('toggle');
  }

  createSupplyChain(): any {
    if (!this.supplyChainName) { return false; }
    const payload = {
      name: this.supplyChainName
    };
    this.spinner.show();
    this.dashService.saveSupplyChainService(payload).subscribe((res: any) => {
      this.spinner.hide();
      this.supplyChainName = '';
      if (res) {
        this.getSupplyChainList();
        this.openSupplyChainModal();
        this.Toast.fire({
          icon: 'success',
          title: 'Created successfully'
        });
      }
    });
  }

  onChangeSupplyChain(event: Event): void {
    this.table.apiEvent({
      type: API.onGlobalSearch,
      value: (event.target as HTMLInputElement).value,
    });
  }

  supplyChainCustomSortByDate(order: string): void {
    const customeSortBydateObj = this.data.sort((a: any, b: any) => {
      const dateA = new Date(a.updated_at);
      const dateB = new Date(b.updated_at);
      if (order === 'asc') {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });
    this.data = this.clonerService.deepClone(customeSortBydateObj);
  }
  eventEmitted($event: { event: string; value: any }): void {
    if ($event.event === 'onOrder' && $event.value.key === 'updated_at') {
      this.supplyChainCustomSortByDate($event.value.order);
    }
    this.selectedSupplyRowId = [];
    this.selectedSupplyChain = $event.value.row;
    switch ($event.event) {
      case 'onCheckboxSelect':
        if (this.selected.has($event.value.row.id)) {
          this.selected.delete($event.value.row.id);
        } else {
          this.selected.add($event.value.row.id);
        }
        break;
      case 'onSelectAll':
        if ($event.value === true) {
          this.data.forEach((_: any, key: any) => {
            if (this.selected.has(this.data[key].id)) {
              // this.selected.delete(this.data[key].id);
            } else {
              this.selected.add(this.data[key].id);
            }
          });
        } else {
          this.data.forEach((_: any, key: any) => {
            if (this.selected.has(this.data[key].id)) {
              this.selected.delete(this.data[key].id);
            }
          });
        }
        break;
    }
    this.selected.forEach(value => {
      this.selectedSupplyRowId.push(value);
    });
    // console.log('selected supply chain::::', this.selectedSupplyRowId);
  }

  deleteSupplyChain(rowIndex: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      position: 'top-right',
      customClass: {
        container: 'swalConfirmCls',
        confirmButton: 'btn btn-outline-danger',
        cancelButton: 'btn btn-outline-primary'
      },
      showCancelButton: true,
      confirmButtonColor: '#32c861',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.data = [...this.data.filter((v: any, k: number) => k !== rowIndex)]; // remove indexed row data
        if (this.selectedSupplyChain.id) {
          const payload = {
            id: this.selectedSupplyChain.id
          };
          this.spinner.show();
          this.dashService.deleteSupplyChainService(payload).subscribe((res: any) => {
            this.spinner.hide();
            if (res) {
              this.Toast.fire(
                'Deleted!',
                'Your data has been deleted.',
                'success'
              );
              this.getSupplyChainList();
            }
          });
        }
      }
    });
  }

  editSupplyChain(rowIndex: number): void {
    setTimeout((): any => {
      if (this.selectedSupplyChain.id) {
        this.router.navigate(['/supplyChain', this.selectedSupplyChain.id]);
      }
    }, 1000);
  }

  onChange(event: Event): void {
    this.tableHistory.apiEvent({
      type: API.onGlobalSearch,
      value: (event.target as HTMLInputElement).value,
    });
  }

  getSupplyChainHistory(): any {
    this.dashService.getSupplyChainHistory().subscribe((res: any) => {
      if (res) {
        this.supplyChainHistory = res.supply_chain_histories;
        if (this.supplyChainHistory && this.supplyChainHistory.length > 0) {
          for (const obj of this.supplyChainHistory) {
            obj.created_at = moment(obj.created_at).format('LLL');
          }
        }
        this.cdr.detectChanges();
      }
    });
  }

  historyIndex(rowIndex: number): any {
    // this.selectedSupplyChain = '';
    // this.selectedSupplyChain = this.data[rowIndex];
  }

  openSupplyHistoryModal(): void {
    $('#supplyHistoryModal').modal('toggle');
  }

  addSupplyHistory(): any {
    if (!this.supplyHistoryName) { return false; }
    const payload = {
      supply_chain_id: this.selectedSupplyChain.id,
      name: this.supplyHistoryName
    };
    this.spinner.show();
    this.dashService.addSupplyHistoryService(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.supplyHistoryName = '';
        this.getSupplyChainHistory();
        this.openSupplyHistoryModal();
        this.Toast.fire({
          icon: 'success',
          title: 'History added successfully'
        });
      }
      this.selectedSupplyChain = '';
    });
  }

  deleteSupplyChainHistory(rowIndex: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      position: 'top-right',
      customClass: {
        container: 'swalConfirmCls',
        confirmButton: 'btn btn-outline-danger',
        cancelButton: 'btn btn-outline-primary'
      },
      showCancelButton: true,
      confirmButtonColor: '#32c861',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        // this.supplyChainHistory = [...this.supplyChainHistory.filter((v: any, k: number) => k !== rowIndex)]; // remove indexed row data
        if (this.supplyChainHistory && this.supplyChainHistory[rowIndex].id) {
          const payload = {
            id: this.supplyChainHistory[rowIndex].id
          };
          this.spinner.show();
          this.dashService.deleteSupplyHistoryService(payload).subscribe((res: any) => {
            this.spinner.hide();
            if (res) {
              this.Toast.fire(
                'Deleted!',
                'Your data has been deleted.',
                'success'
              );
              this.getSupplyChainHistory();
            }
          });
        }
      }
    });
  }

  historyEventEmitted($event: any): void {
    this.selectedHistoryRow = $event.value.row;
    if ($event.event === 'onOrder' && $event.value.key === 'created_at') {
      this.historyCustomSortByDate($event.value.order);
    }
  }


  historyCustomSortByDate(order: string): void {
    const customeSortBydateObj = this.supplyChainHistory.sort((a: any, b: any) => {
      const dateA = new Date(a.created_at);
      const dateB = new Date(b.created_at);
      if (order === 'asc') {
        return dateA.getTime() - dateB.getTime();
      } else {
        return dateB.getTime() - dateA.getTime();
      }
    });

    this.supplyChainHistory = this.clonerService.deepClone(customeSortBydateObj);
  }

  restoreSupplyChain(rowIndex: number): void {
    setTimeout((): any => {
      if (this.selectedHistoryRow.id) {
        const payload = {
          id: this.selectedHistoryRow.id
        };
        this.spinner.show();
        this.dashService.restoreSupplyHistoryService(payload).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.Toast.fire({
              icon: 'success',
              title: 'Success',
              text: 'Restoration completed.'
            });
            this.getSupplyChainList();
          }
        });
      }
    }, 1000);
  }

  download(blob: any, filename: any): any {
    if (window.navigator.msSaveOrOpenBlob) { // IE10+
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else { // Others
      const a = document.createElement('a');
      const url = URL.createObjectURL(blob);
      a.href = url;
      a.download = filename;
      document.body.appendChild(a); // sanitize to prevent xss
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
      }, 10);
    }
  }

  downloadHistory(rowIndex: number): void {
    setTimeout((): any => {
      if (this.selectedHistoryRow.id) {
        const payload = {
          id: this.selectedHistoryRow.id
        };
        this.spinner.show();
        this.dashService.downloadSupplyHistoryService(payload).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            // this.getSupplyChainList();
            console.log('download response::: ', res);
            const c = JSON.stringify(res);
            const file = new Blob([c], { type: 'text/json' });
            const filename = this.selectedHistoryRow.name + '.json';
            this.download(file, filename);
          }
        });
      }
    }, 1000);
  }

  importSchainModal(): void {
    $('#importSupplyChainModal').modal('toggle');
    /*this.Toast.fire({
      icon: 'warning',
      title: 'Oops...',
      text: 'API integration pending!!!'
    });*/
  }

  uploadFile(e: any): any {
    const formData = new FormData();
    for (const file of e.target.files) {
      formData.append('file', file);
    }
    this.spinner.show();
    this.dashService.uploadStateService(formData).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.getSupplyChainHistory();
        this.Toast.fire({
          icon: 'success',
          text: 'Files uploaded successfully.'
        });
      }
    });
  }

  importScFn(): void {
    if (this.fileUpload.nativeElement.files?.length < 4) {
      this.Toast.fire({
        icon: 'warning',
        text: 'Please Select All 4 files supplychain, product, facility and vehicle!'
      });
      return;
    } else {
      for (const file of this.fileUpload.nativeElement.files) {
        // Check the extension exists
        if (file.name) {
          if (!this.filesName.includes(file.name)) {
            this.Toast.fire({
              icon: 'error',
              text: 'Please Select files with name supplychain, product, facility and vehicle!'
            });
            return;
          }
        }
      }
    }
    const formData = new FormData();
    for (const file of this.fileUpload.nativeElement.files) {
      formData.append('supply_chain_file[]', file);
    }
    this.spinner.show();
    this.dashService.sendFormData(formData).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        // console.log(event.body);
        this.Toast.fire({
          icon: 'success',
          text: 'Notice: File is importing. You will receive an email when it completes OR please refresh the page after some time to see newly imported supply chain models.',
          timer: 5000
        });
        this.fileUpload.nativeElement.value = '';
        this.getSupplyChainList();
        this.importSchainModal();
      }
    });
  }

  exportSupplyChain(): void {
    if (this.selectedSupplyRowId?.length > 0) {
      const payload = {
        ids: this.selectedSupplyRowId.join()
      };
      this.spinner.show();
      this.dashService.exportSupplyChainService(payload).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          // console.log(res);
          window.open(res.zip_url);
        }
      });
    } else {
      this.Toast.fire({
        icon: 'warning',
        text: 'Please select at least one supply chain.'
      });
    }
  }

  addToLibrary(): void {
    setTimeout((): any => {
      if (this.selectedSupplyChain.id) {
        const payload = {
          id: this.selectedSupplyChain.id,
          name: this.selectedSupplyChain.name,
          is_library: true
        };
        this.spinner.show();
        this.dashService.updateSupplyChainService(payload).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.Toast.fire({
              icon: 'success',
              title: 'Added to library successfully.'
            });
          }
        });
      }
    }, 500);
  }

  isAdmin(): any {
    if (localStorage.getItem('userRole')) {
      if (localStorage.getItem('userRole') === 'admin') {
        return true;
      } else {
        return false;
      }
    }
  }

  goToSimulation(): void {
    setTimeout((): any => {
      const supplyChainID = this.selectedSupplyChain.id;

      if (supplyChainID) {
        this.spinner.show();

        this.supplyChainService.getFacilitiesBySupplyChainID(supplyChainID)
          .subscribe({
            next: (res: SupplyChainFacilities) => {
              const facilities = res.facilities;

              if (facilities.length > 0) {
                return this.router.navigate([]).then(() => window.open(`/simulation/${this.selectedSupplyChain.id}`, '_blank'));
              }

              return this.Toast.fire({
                icon: 'warning',
                title: 'Facility not created for the simulation.'
              });
            },
            error: err => {
              console.error('Error loading facilities:', err);

              return this.Toast.fire({
                icon: 'error',
                title: 'Error loading facilities'
              });
            },
            complete: () => {
              this.spinner.hide();
            }
          });
      }
    }, 500);
  }
}
