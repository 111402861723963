import { Injectable } from '@angular/core';
// @ts-ignore
import * as clone from 'clone';

@Injectable({
  providedIn: 'root'
})
export class CloneService {

  constructor() { }
  deepClone<T>(value: any): T {
    return clone<T>(value);
  }
}
