import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';

import { AgmCoreModule } from '@agm/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgxCaptchaModule } from 'ngx-captcha';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { SimulationComponent } from './components/simulation/simulation.component';
import { AuthGuard } from './guard/auth.guard';
import { AuthService } from './services/auth.service';
// import { ModalModule } from 'ngx-bootstrap/modal';
import { AgmJsMarkerClustererModule } from '@agm/js-marker-clusterer';
import { AgmDirectionModule } from 'agm-direction';
import { ModalModule } from 'ng-modal-lib';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TableModule } from 'ngx-easy-table';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { ApplicationHeaderComponent } from './components/application-header/application-header.component';
import { FacilityvehicletypeComponent } from './components/facilityvehicletype/facilityvehicletype.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { InlineEditTableComponent } from './components/inline-edit-table/inline-edit-table.component';
import { LibraryComponent } from './components/library/library.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SupplychainComponent } from './components/supplychain/supplychain.component';
import { SupportticketsComponent } from './components/supporttickets/supporttickets.component';
import { TimeintervalComponent } from './components/timeinterval/timeinterval.component';
import { UsersComponent } from './components/users/users.component';
import { ViewticketComponent } from './components/viewticket/viewticket.component';
import { AuthInterceptor } from './interceptors/auth.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    LoginComponent,
    RegisterComponent,
    SimulationComponent,
    SupplychainComponent,
    InlineEditTableComponent,
    FacilityvehicletypeComponent,
    LibraryComponent,
    TimeintervalComponent,
    ForgotpasswordComponent,
    SupportticketsComponent,
    ResetpasswordComponent,
    ViewticketComponent,
    UsersComponent,
    AnnouncementComponent,
    ApplicationHeaderComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot(),
    NgxCaptchaModule,
    // ModalModule.forRoot(),
    TooltipModule.forRoot(),
    TableModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCfVRQWbqBX1El9wFB5bj7IwQ5bgGDJcuM',
      libraries: ['places', 'geometry', 'geocoding', 'drawing', 'routes']
    }),
    ModalModule,
    NgxSpinnerModule,
    AccordionModule.forRoot(),
    TimepickerModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    AgmDirectionModule,
    AgmJsMarkerClustererModule,
    TabsModule.forRoot(),
    ChartsModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    AuthService,
    AuthGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
