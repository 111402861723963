<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="register-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="regiForm">
          <div class="card">
            <article class="card-body">
              <h4 class="card-title text-center mb-4 mt-1">
                Register
                <p>Purchase New or Activate Prepaid Account</p>
              </h4>
              <hr>
              <form [formGroup]="regiForm" (submit)="onRegiSubmit()" autocomplete="off">
                <div class="form-group input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                  </div>
                  <input [(ngModel)]="firstName" formControlName="firstname" class="form-control" placeholder="Full name" type="text" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }">
                  <div *ngIf="regiForm.get('firstname') && ( regiForm.get('firstname')?.touched || regiForm.get('firstname')?.dirty ) && regiForm.get('firstname')?.invalid && regiForm.get('firstname')?.errors"
                  class="errors"> 
                  <div *ngIf="f.firstname.errors.required">Name is required</div>
                 </div>
                </div> <!-- form-group// -->
                <div class="form-group input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="font-size: .7rem;"> <i class="fa fa-envelope"></i> </span>
                  </div>
                  <input [(ngModel)]="email" formControlName="email" class="form-control" placeholder="Email address" type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                  <div *ngIf="regiForm.get('email') && ( regiForm.get('email')?.touched || regiForm.get('email')?.dirty ) && regiForm.get('email')?.invalid && regiForm.get('email')?.errors"
                   class="errors">
                    <div *ngIf="f.email.errors.required">Email is required</div>
                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                  </div>
                </div> <!-- form-group// -->
                <div class="form-group input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                  </div>
                  <input [(ngModel)]="password" formControlName="password" class="form-control" placeholder="Password" type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                  <div *ngIf="regiForm.get('password') && ( regiForm.get('password')?.touched || regiForm.get('password')?.dirty ) && regiForm.get('password')?.invalid && regiForm.get('password')?.errors" class="errors">
                    <div *ngIf="f.password.errors.required">password is required</div>
                    <div *ngIf="f.password.errors.minlength">password is at least 6 characters</div>
                  </div>
                </div> <!-- form-group// -->
                <div class="form-group input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                  </div>
                  <input [(ngModel)]="confirmPass" formControlName="confirmpass" class="form-control" placeholder="Confirm password" type="password" [ngClass]="{ 'is-invalid': submitted && f.confirmpass.errors }">
                  <div *ngIf="regiForm.get('confirmpass') && ( regiForm.get('confirmpass')?.touched || regiForm.get('confirmpass')?.dirty ) && regiForm.get('confirmpass')?.invalid && regiForm.get('confirmpass')?.errors" class="errors">
                    <div *ngIf="f.confirmpass.errors.required">password is required</div>
                    <div *ngIf="f.confirmpass.errors.minlength">password is at least 6 characters</div>
                  </div>
                </div> <!-- form-group// -->
                <div class="form-group input-group">
                  <!-- <ngx-recaptcha2 #captchaElem
                                  [siteKey]="siteKey"
                                  [useGlobalDomain]="false"
                                  [size]="size"
                                  [hl]="lang"
                                  [theme]="theme"
                                  [type]="type"
                                  formControlName="recaptcha">
                  </ngx-recaptcha2> -->
                </div> <!-- form-group// -->
                <div class="form-group">
                  <button [disabled]="regiForm.invalid" type="submit" class="btn btn-primary btn-block"> Create Account  </button>
                  <br>
                  <p>By clicking Create Account you agree to the <a (click)="openTermModal()" class="termsBtn">Terms of Service.</a></p>
                </div> <!-- form-group// -->
                <p class="text-center">Already have an account? <a [routerLink]="['/login']">Login here</a> </p>
                <div class="browserSupport">
                  <span>***Browser Support:- </span>
                  <i class="fa fa-chrome" aria-hidden="true" tooltip="Google Chrome" placement="bottom"></i>
                  <i class="fa fa-firefox" aria-hidden="true" tooltip="Mozilla Firefox" placement="bottom"></i>
                  <i class="fa fa-safari" aria-hidden="true" tooltip="Apple Safari" placement="bottom"></i>
                  <i class="fa fa-edge" aria-hidden="true" tooltip="Edge" placement="bottom"></i>
                  <!--<span class="fa-stack fa-lg" tooltip="No Support For Microsoft Internet Explorer" placement="bottom">
                    <i class="fa fa-edge fa-stack-1x" style="line-height: normal;"></i>
                    <i class="fa fa-ban fa-stack-2x text-danger"></i>
                  </span>-->
                </div>
              </form>
            </article>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Terms popup-->

<div id="termsModal" data-backdrop="static" data-keyboard='false' class="modal fade" tabindex="-1"
     role="dialog" aria-labelledby="dialog-sizes-name1">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h4 id="dialog-sizes-name1" class="modal-title pull-left">Terms of Service and Privacy Policy</h4>
        <button type="button" class="close pull-right" (click)="openTermModal()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-justify">
        SCM Globe Terms of Service and Privacy Policy
        Welcome to SCM Globe!
        Thanks for using our products and services (“Services”). The Services are provided by SCM Globe Inc. (“SCM Globe”), located at 1319 West Granville, Chicago, IL 60660, United States. By using our Services, you are agreeing to these terms. Please read them carefully. You must follow any policies made available to you within these terms of service.
        Don’t misuse our Services: For example, don’t interfere with our Services or try to access them using a method other than the interface and the instructions that we provide. You may use our Services only as permitted by law, including applicable export and re-export control laws and regulations. We may suspend or stop providing our Services to you if you do not comply with our terms or policies or if we are investigating suspected misconduct. Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. You may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These terms do not grant you the right to use any branding or logos used in our Services Our Services display some content that is not SCM Globe’s. This content is the sole responsibility of the entity that makes it available. We may review content to determine whether it is illegal or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But that does not necessarily mean that we review content, so please don’t assume that we do. In connection with your use of the Services, we may send you service announcements, administrative messages, and other information. You may opt out of some of those communications.
        Your SCM Globe Account: You may need a SCM Globe Account in order to use some of our Services. When you purchase your SCM Globe Account you agree it is for your use only. SCM Globe accounts are not designed for multiple users and damage to files in the account will occur when multiple people use a single account. More than one person using the same account is a violation of these terms of service. If you learn of any unauthorized use of your password or account, please contact us.
        Your Content in our Services: Some of our Services allow you to submit content. You retain ownership of any intellectual property rights that you hold in that content. In short, what belongs to you stays yours. When you upload or otherwise submit content to our Services, you give SCM Globe (and those we work with) a worldwide license to use, host, store, reproduce, modify, create derivative works (such as those resulting from translations, adaptations or other changes we make so that your content works better with our Services), communicate, publish, publicly perform, publicly display and distribute such content. The rights you grant in this license are for the limited purpose of operating, promoting, and improving our Services, and to develop new ones. This license continues even if you stop using our Services. Some Services may offer you ways to access and remove content that has been provided to that Service. Also, in some of our Services, there are terms or settings that narrow the scope of our use of the content submitted in those Services. Make sure you have the necessary rights to grant us this license for any content that you submit to our Services. If you submit feedback or suggestions about our Services, we may use your feedback or suggestions without obligation to you.
        About Software in our Services: When a Service requires or includes downloadable software, this software may update automatically on your device once a new version or feature is available. Some Services may let you adjust your automatic update settings. SCM Globe gives you a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to you by SCM Globe as part of the Services. This license is for the sole purpose of enabling you to use and enjoy the benefit of the Services as provided by SCM Globe, in the manner permitted by these terms. You may not copy, modify, distribute, sell, or lease any part of our Services or included software, nor may you reverse engineer or attempt to extract the source code of that software, unless laws prohibit those restrictions or you have our written permission. Open source software is important to us. Some software used in our Services may be offered under an open source license that we will make available to you. There may be provisions in the open source license that expressly override some of these terms. Privacy and Copyright Protection SCM Globe’s privacy policies explain how we treat your personal data and protect your privacy when you use our Services. By using our Services, you agree that SCM Globe can use such data in accordance with our privacy policies. We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act.
        Modifying and Terminating our Services: We are constantly changing and improving our Services. We may add or remove functionalities or features, and we may suspend or stop a Service altogether. You can stop using our Services at any time, although we’ll be sorry to see you go. SCM Globe may also stop providing Services to you, or add or create new limits to our Services at any time. We believe that you own your data and preserving your access to such data is important. If we discontinue a Service, where reasonably possible, we will give you reasonable advance notice and a chance to get information out of that Service.
        Our Warranties and Disclaimers: We provide our Services using a commercially reasonable level of skill and care and we hope that you will enjoy using them. But there are certain things that we don’t promise about our Services. OTHER THAN AS EXPRESSLY SET OUT IN THESE TERMS OR ADDITIONAL TERMS, NEITHER SCM GLOBE NOR ITS SUPPLIERS OR DISTRIBUTORS MAKE ANY SPECIFIC PROMISES ABOUT THE SERVICES. FOR EXAMPLE, WE DON’T MAKE ANY COMMITMENTS ABOUT THE CONTENT WITHIN THE SERVICES, THE SPECIFIC FUNCTION OF THE SERVICES, OR THEIR RELIABILITY, AVAILABILITY, OR ABILITY TO MEET YOUR NEEDS. WE PROVIDE THE SERVICES “AS IS”. SOME JURISDICTIONS PROVIDE FOR CERTAIN WARRANTIES, LIKE THE IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. TO THE EXTENT PERMITTED BY LAW, WE EXCLUDE ALL WARRANTIES.
        Liability for our Services: WHEN PERMITTED BY LAW, SCM GLOBE, AND SCM GLOBE’S SUPPLIERS AND DISTRIBUTORS, WILL NOT BE RESPONSIBLE FOR LOST PROFITS, REVENUES, OR DATA, FINANCIAL LOSSES OR INDIRECT, SPECIAL, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES. TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF SCM GLOBE, AND ITS SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIM UNDER THESE TERMS, INCLUDING FOR ANY IMPLIED WARRANTIES, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES (OR, IF WE CHOOSE, TO SUPPLYING YOU THE SERVICES AGAIN). IN ALL CASES, SCM GLOBE, AND ITS SUPPLIERS AND DISTRIBUTORS, WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE THAT IS NOT REASONABLY FORESEEABLE.
        Business uses of our Services: If you are using our Services on behalf of a business, that business accepts these terms. It will hold harmless and indemnify SCM Globe and its affiliates, officers, agents, and employees from any claim, suit or action arising from or related to the use of the Services or violation of these terms, including any liability or expense arising from claims, losses, damages, suits, judgments, litigation costs and attorneys’ fees. About these Terms We may modify these terms or any additional terms that apply to a Service to, for example, reflect changes to the law or changes to our Services. You should look at the terms regularly. We’ll post notice of modifications to these terms on this page. We’ll post notice of modified additional terms in the applicable Service. Changes will not apply retroactively and will become effective no sooner than fourteen days after they are posted. However, changes addressing new functions for a Service or changes made for legal reasons will be effective immediately. If you do not agree to the modified terms for a Service, you should discontinue your use of that Service. If there is a conflict between these terms and the additional terms, the additional terms will control for that conflict.
        These terms control the relationship between SCM Globe and you. They do not create any third party beneficiary rights. If you do not comply with these terms, and we don’t take action right away, this doesn’t mean that we are giving up any rights that we may have (such as taking action in the future). If it turns out that a particular term is not enforceable, this will not affect any other terms. The laws of New York, U.S.A., excluding New York’s conflict of laws rules, will apply to any disputes arising out of or relating to these terms or the Services. All claims arising out of or relating to these terms or the Services will be litigated exclusively in the federal or state courts of Cook County, Illinois, USA, and you and SCM Globe consent to personal jurisdiction in those courts.
        For information about how to contact SCM Globe, please visit our website at www.scmglobe.com.
        Privacy Policy: Your privacy matters to SCM Globe. If you have any questions, please contact us at www.scmglobe.com. We collect information you give us, like your name, email address, or telephone number. When you use our services or view content provided by SCM Globe, we may automatically collect and store certain information in server logs. We may also collect your Internet Protocol Address. We use the information we collect from our services to provide, maintain, protect and improve them, to develop new ones, and to protect SCM Globe and our users. When you contact SCM Globe, we may keep a record of your communication to help solve any issues you might be facing. We may use your email address to inform you about our services, such as letting you know about upcoming changes or improvements. We will ask for your consent before using information for a purpose other than those that are set out in this Privacy Policy. We do not share personal information with companies, organizations and individuals outside of SCM Globe. We will share personal information with companies, organizations or individuals outside of SCM Globe if we have a good-faith belief that access, use, preservation or disclosure of the information is reasonably necessary to:
        meet any applicable law, regulation, legal process or enforceable governmental request.
        enforce applicable Terms of Service, including investigation of potential violations.
        detect, prevent, or otherwise address fraud, security or technical issues.
        protect against harm to the rights, property or safety of SCM Globe, our users or the public as required or permitted by law.
        Our Privacy Policy applies to all of the services offered by SCM Globe Inc. Our Privacy Policy may change from time to time. We will not reduce your rights under this Privacy Policy without your explicit consent. We will post any privacy policy changes on this page. We will also keep prior versions of this Privacy Policy in an archive for your review.
      </div>
    </div>
  </div>
</div>


<!--Terms popup-->
