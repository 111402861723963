<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="viewTicketCls">
  <div class="container-custom">
    <div class="row">
      <div class="col-12">
        <div class="ticketCls" *ngIf="ticketDetailData">
          <div class="head">
            <h4 class="pull-left">User:- {{ticketDetailData.user_email}}</h4>
            <h4 class="pull-right">Status:- {{ticketDetailData.status}}</h4>
            <div class="clearfix"></div>
          </div>
          <div *ngIf="ticketDetailData.tickets_comments?.length > 0">
            <div class="comment_block" *ngFor="let comment of ticketDetailData.tickets_comments">
              <div class="comment_head">
                <p class="pull-left">{{comment.user_email}}</p>
                <p class="pull-right">{{comment.created_at}}</p>
                <div class="clearfix"></div>
              </div>
              <div class="comment">
                <p>{{comment.comments}} </p>
              </div>
            </div>
          </div>
          <!-- <div class="comment_block">
            <div class="comment_head">
              <p class="pull-left">anu@gmail.com</p>
              <p class="pull-right">July 15, 2021 10:10</p>
              <div class="clearfix"></div>
            </div>
            <div class="comment">
              <p>test 01 test 01 test 01 test 01 test 01 </p>
            </div>
          </div>
          <div class="comment_block">
            <div class="comment_head">
              <p class="pull-left">anu@gmail.com</p>
              <p class="pull-right">July 15, 2021 10:10</p>
              <div class="clearfix"></div>
            </div>
            <div class="comment">
              <p>test 01 test 01 test 01 test 01 test 01 </p>
            </div>
          </div>
          <div class="comment_block">
            <div class="comment_head">
              <p class="pull-left">anu@gmail.com</p>
              <p class="pull-right">July 15, 2021 10:10</p>
              <div class="clearfix"></div>
            </div>
            <div class="comment">
              <p>test 01 test 01 test 01 test 01 test 01 </p>
            </div>
          </div>-->
          <hr>
          <div class="replyBox">
            <div class="form-group">
              <label>Reply</label>
              <textarea class="form-control" rows="5" [(ngModel)]="replyText" placeholder="Enter here..."></textarea>
            </div>
            <div class="buttons">
              <a (click)="replyComment()" class="btn btn-outline-primary">Reply</a>
              <a [routerLink]="['/supporttickets']" class="btn btn-outline-secondary">Back</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
