<div class="container" style="height: calc(100vh - 80px);overflow: scroll;">
  <div class="row">
    <div class="col-12">
      <div class="forgotPassCls">
        <div *ngIf="!emailSentSuccess">
          <h4>
            Forgot your password?
          </h4>
          <p>Fill out the form below and instructions to reset your password will be emailed to you.</p>
          <form [formGroup]="forgotForm" (submit)="onSubmit()" autocomplete="off">
            <div class="form-group">
              <div class="input-group">
                <input [(ngModel)]="email" formControlName="email" class="form-control" placeholder="Email" type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" >
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                </div>
              </div> <!-- input-group.// -->
            </div> <!-- form-group// -->
            <div class="form-group">
              <button type="submit" class="btn btn-primary btn-block" [disabled]="!email"> Send Email To Reset My Password  </button>
            </div>
          </form>
        </div>
        <div *ngIf="emailSentSuccess" class="text-center">
          <div class="s_icon">
            <i class="fa fa-check-circle-o" aria-hidden="true"></i>
          </div>
          <h2>Forgot Password Successfully.</h2>
          <p>Instructions to reset your password have been emailed to you. Please check your email. Check your Spam folder if you do not see an email from <a href="mailto:noreply@scmglobe.com">noreply@scmglobe.com</a> in your Inbox</p>
          <a [routerLink]="['/login']" class="btn btn-primary">Back to login page.</a>
        </div>
      </div>
    </div>
    <!--<img src="assets/pageNotFound.jpeg" alt="page not found...">-->
  </div>
</div>
