<ngx-spinner type="ball-clip-rotate-multiple"></ngx-spinner>
<div class="container">
  <div class="userCls">
    <div class="row">
      <div class="col-6">
        <div class="usersListCls">
          <h4>
            All Users
            <a (click)="openAddUserModal('add')" class="btn btn-primary addButton">
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add New User
            </a>
          </h4>
          <div class="clearfix"></div>
          <div class="searchGlobal">
            <input class="form-input form-control" type="text" id="globalSearch" (input)="onChange($event)"
              placeholder="Global Search" />
          </div>
          <ngx-table #allUsersTable [configuration]="allUsersConfig" [data]="allUserList" [columns]="allUserColumns"
            (event)="onEvent($event)">
          </ngx-table>

          <ng-template #allUsersActionTpl let-row let-rowIndex="rowIndex">
            <div class="btn btn-sm btn-outline-danger waves-effect waves-light pull-right" (click)="deleteUser(row)">
              Delete
            </div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right"
              (click)="openAddUserModal('edit')">
              Edit
            </div>
          </ng-template>
        </div>
      </div>
      <div class="col-6">
        <div class="usersListCls">
          <h4>Actively Subscribed Users</h4>
          <div class="searchGlobal">
            <input class="form-input form-control" type="text" id="globalSearch2" (input)="onChangeActive($event)"
              placeholder="Global Search" />
          </div>
          <ngx-table #ActiveUsersTable [configuration]="activeUsersConfig" [data]="activeUserList"
            [columns]="activeUserColumns" (event)="onEvent($event)">
          </ngx-table>

          <ng-template #activeUsersActionTpl let-row let-rowIndex="rowIndex">
            <div class="btn btn-sm btn-outline-danger waves-effect waves-light pull-right" (click)="deleteUser(row)">
              Delete
            </div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right"
              (click)="openAddUserModal('edit')">
              Edit
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add/Update user modal start -->
<div bsModal id="userAddModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1"
  role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-sizes-name2" class="modal-title pull-left">
          <span *ngIf="modalTypeFn === 'add'">Add New </span>
          <span *ngIf="modalTypeFn === 'edit'">Edit </span>
          <span> User</span>
        </h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeUserAddModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Full Name</label>
          <input type="text" [(ngModel)]="userName" class="form-control" />
        </div>
        <div class="form-group">
          <label>Email</label>
          <input type="text" [(ngModel)]="userEmail" class="form-control" />
        </div>
        <div class="form-group">
          <label>Expiration Date</label>
          <input type="text" placeholder="Datepicker" class="form-control" bsDatepicker [(ngModel)]="userExpireDate"
            [bsConfig]="{ isAnimated: true, dateInputFormat: 'YYYY-MM-DD' }" />
        </div>
        <div class="form-group form-check">
          <input type="checkbox" [(ngModel)]="noExpiration" class="form-check-input" />
          <label class="form-check-label">No Expiration</label>
        </div>
        <div class="form-group">
          <label>Role</label>
          <select class="form-control" [(ngModel)]="userRole" (change)="changeRole(userRole)"
            style="text-transform: capitalize">
            <option *ngFor="let option of roleList" [ngValue]="option">
              {{ option }}
            </option>
          </select>
        </div>
        <!--<div class="form-group form-check">
          <input type="checkbox" [(ngModel)]="isProUser" class="form-check-input">
          <label class="form-check-label">Is Pro User?</label>
        </div>-->
        <div class="form-group">
          <label>Password</label>
          <input type="text" [(ngModel)]="userPassword" class="form-control" />
        </div>
        <button [disabled]="userName.length <= 3" (click)="addEditUser()" type="button" class="btn btn-success">
          Save
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add/Update modal end -->