import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { RegistrationPayload } from 'src/types/services/authentication-service.types';
import { Links } from '../links.module';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpClient
  ) { }

  loginService(payload: any): any {
    return this.http.post(Links.loginUrl, payload, {})
      .pipe(map((res: any) => res));
  }

  registrationService(payload: RegistrationPayload): any {
    return this.http.post(Links.registerUrl, payload, {})
      .pipe(map((res: any) => res));
  }

  forgotPassService(payload: any): any {
    return this.http.post(Links.forgotPass, payload, {})
      .pipe(map((res: any) => res));
  }

  resetPassService(payload: any): any {
    return this.http.put(Links.forgotPass, payload, {})
      .pipe(map((res: any) => res));
  }
}
