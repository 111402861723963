import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // title = 'scmglobe-enterprise-web';
  constructor(private http: HttpClient) {}

  openBugReport(event: Event): void {
    event.preventDefault();

    const versionFile$ = this.http.get('assets/.app_version', {responseType: 'text'});
    const apiVersions$ = this.http.get<{sim_engine: string, api: string}>(environment.baseUrl + '/api/v1/versions');

    forkJoin([versionFile$, apiVersions$]).subscribe(([appVersion, apiVersions]) => {
      const url = `https://scmglobe-jira.atlassian.net/secure/CreateIssueDetails!init.jspa?pid=10000&issuetype=10007&summary=Enter%20Issue%20Summary&description=Enter%20issue%20description%0A%0APlease%20do%20not%20modify%20the%20lines%20below.%0AVersions%3A%0Aapp%3A%20${appVersion}%0Asimengine%3A%20${apiVersions.sim_engine}%0Aapi%3A%20${apiVersions.api}`;
      window.open(url, '_blank');
    });
  }
}
