import { MapsAPILoader } from '@agm/core';
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { DashboardService } from '../../services/dashboard.service';
import { SimulationService } from '../../services/simulation.service';
// @ts-ignore
import lzwCompress from 'lzwcompress';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { SupplyChainService } from 'src/app/services/supply-chain.service';
import { removeQueryString } from 'src/lib/icons-helper';
import { SupplyChainDto } from 'src/types/dtos/supply-chain.types';
import { SupplyChain } from 'src/types/services/supply-chain-service.types';
import { CloneService } from '../../services/clone.service';

declare var $: any;

@Component({
  selector: 'app-simulation',
  templateUrl: './simulation.component.html',
  styleUrls: ['./simulation.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationComponent implements OnInit {
  latitude: any;
  longitude: any;
  zoom = 2;
  mapBound = false;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  markers: any = [];
  vehicleMarkers: any = [];
  private sub: any;
  selectedSupplyChainId: any;
  supplyChainList: any = [];
  intervalList: any = [];
  selectedInterval: any;
  stopBtnDisable = true;
  pauseBtnDisable = true;
  playBtnDisable = false;
  intervalData: any;
  displayIntervalData: any;
  callInterval: any;
  intervalId = 0;
  reportData: any;
  public configuration1: Config | any;
  public columns: Columns[] | any;
  public lineChartData: ChartDataSets[] = [
    { data: [12, 23, 12, 23, 12, 43, 34, 56], label: 'Series A', lineTension: 0, fill: 0 },
    { data: [45, 56, 67, 56, 67, 45, 34, 23, 34, 56], label: 'Series B', lineTension: 0, fill: 0 },
    { data: [23, 23, 24, 45, 56, 34, 45, 34], label: 'Series C', lineTension: 0, fill: 0 },
  ];
  public lineChartLabels: Label[] = [];
  public lineChartOptions: ChartOptions = {
    responsive: true,
  };
  public lineChartColors: Color[] = [
    {
      borderColor: '#194dff',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#00ff70',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#ffb800',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#00fcff',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#29e46b',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#e500f1',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#ff2100',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#55fc00',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#d900ff',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
    {
      borderColor: '#179c07',
      // backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType: ChartType = 'line';
  public lineChartPlugins = [];
  simulationFacility: any = [];
  polylines: any = [];
  public vehicleTableConfig: Config | any;
  public vehicleTableColumns: Columns[] | any;
  vehicleTableData: any;
  public productTableConfig: Config | any;
  public productTableColumns: Columns[] | any;
  geopathHashMap: any = {};
  clonedVehicleMarkers = [];
  selectedAccordion = 0;
  // @ViewChild('staticTabs', { static: false }) staticTabs: TabsetComponent | any;
  selectedTabIndex = 0;
  tabActive0 = true;
  tabActive1 = false;
  tabActive2 = false;
  colorCodes = [
    '#00FF00', // Lime Green
    '#FF00FF', // Fuchsia
    '#00FFFF', // Aqua
    '#FFA500', // Orange
    '#FFFF00', // Yellow
    '#FF0000', // Red
    '#0000FF', // Blue
    '#800080', // Purple
    '#FF69B4', // Hot Pink
    '#BFFF00'  // Lime
  ];
  speedMultipliers = [
    {
      label: 'Slow',
      value: 2
    },
    {
      label: 'Medium',
      value: 1.5
    },
    {
      label: 'Fast',
      value: 1
    },
    {
      label: 'Fastest',
      value: 0.5
    }
  ];
  speedMultiplier = this.speedMultipliers[0];
  stopClicked = false;
  graphHashMapData: any = {};
  firstIntervalData: any = {};
  markerRunInterval = 1;
  pauseClicked = false;
  formatedTime = '';
  weekNo = 0;
  simulationId = '';
  intervalTime = 700;
  numDeltas = 100;
  delay = 10; // milliseconds
  delayIndex = 0;
  deltaLat: any;
  deltaLng: any;
  noOfDays = 30;

// start report modal 
  startDay: any = 0;
  endDay: any = 30;
  reportType: any = 'mission';
  facilities: any = [];
  showDownloadReport: any = false;
// End report modal

  constructor(
    private activatedRoute: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private spinner: NgxSpinnerService,
    private dashService: DashboardService,
    private cdr: ChangeDetectorRef,
    private httpClient: HttpClient,
    private simulationService: SimulationService,
    private clonerService: CloneService,
    private readonly router: Router,
    private readonly supplyChainService: SupplyChainService
  ) {
    this.latitude = 22.2736308;
    this.longitude = 70.7512555;
  }

  ngOnInit(): void {
    this.vehicleTableConfig = { ...DefaultConfig };
    this.vehicleTableColumns = [
      { key: 'VehicleName', title: 'Vehicle' },
      { key: 'CostPerDistance', title: 'Running Cost' },
      { key: 'CurrentCarbonOutput', title: 'Total Carbon( kg)' },
      { key: 'DestinationFacilityName', title: 'Destination' },
      { key: 'RouteName', title: 'Route' },
    ];
    this.vehicleTableConfig.paginationEnabled = false;
    this.productTableConfig = { ...DefaultConfig };
    this.productTableColumns = [
      { key: 'FacilityName', title: 'Facility' },
      { key: 'ProductName', title: 'Product' },
      { key: 'OnHandAmount', title: 'On-Hand' },
      { key: 'ProductValue', title: 'Value' },
    ];
    this.productTableConfig.paginationEnabled = false;
    this.setCurrentLocation();
    // this.sub = this.activatedRoute.params.subscribe(params => {
    //   if (params && params.id) {
    //     const paramId = parseInt(params.id, 10);
    //     // console.log('paramId:::', paramId);
    //     this.getSupplyChains(paramId);
    //     this.getFacilityList(paramId);
    //   }
    // });
  
    this.sub = this.activatedRoute.params
      .pipe(
        map(params => parseInt(params.id, 10)),
        filter(paramsId => !!paramsId),
        switchMap(paramsId =>
          this.getSupplyChainByID(paramsId).pipe(
            tap(supplyChain => {
              const supplyChainID = supplyChain.id;

              this.getSupplyChains(supplyChainID);
              this.getFacilityList(supplyChainID);
            })
          )
        )
      )
      .subscribe({
        error: error => console.error('Error in stream:', error),
      });

    this.getTimeIntervalList();
    // START: get simulation data from json file
    /*this.httpClient.get('assets/simulation.json').subscribe(data => {
      // console.log(data);
      this.intervalData = data;
      for (const time of this.intervalData) {
        this.lineChartLabels.push(time.CurrentTimeStamp);
      }
    });*/
    // END: get simulation data from json file
    this.configuration1 = { ...DefaultConfig };
    this.configuration1.paginationEnabled = false;
    this.columns = [
      { key: 'ProductName', title: 'Product' },
      { key: 'Demand', title: 'Demand' },
      { key: 'Production', title: 'Production' },
      { key: 'OnHandAmount', title: 'Quantity On hand' },
      { key: 'CapacityPercent', title: '% of Total Capacity' },
    ];

    setTimeout(() => {
      $('#sidebarPopup > .ui-modal').css({ display: 'block', 'z-index': 10 });
    }, 100);
  }
  // Get Current Location Coordinates

  getRandomColor(): string {
    const baseColor = this.colorCodes[Math.floor(Math.random() * this.colorCodes.length)];
    return this.addColorVariation(baseColor);
  }

  private addColorVariation(color: string): string {
    const colorValue = parseInt(color.slice(1), 16);
    const variation = Math.floor(Math.random() * 0xFFFFFF);
    const veryRandomColorValue = (colorValue + variation) % 0xFFFFFF;
    const veryRandomColor = `#${veryRandomColorValue.toString(16).padStart(6, '0')}`;
    return veryRandomColor;
  }
  setCurrentLocation(): any {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        // get address by lat lng
        // this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  getSupplyChains(facilityId: any): void {
    this.dashService.getSupplyChainList().subscribe((res: any) => {
      if (res) {
        this.supplyChainList = res.supply_chain;
        if (this.supplyChainList && this.supplyChainList.length > 0) {
          for (const obj of this.supplyChainList) {
            if (obj.id === facilityId) {
              this.selectedSupplyChainId = obj.id;
              break;
            }
          }
        }
        this.cdr.detectChanges();
      }
    });
  }
  createGetSimulationData(): any {
    if (this.pauseClicked) {
      this.resumeSimulation();
    } else {
      this.stopClicked = false;
      this.createSimulationData();
      this.intervalData = [];
    }
  }
  createSimulationData(): any {
    if (this.selectedSupplyChainId) {
      const payload = {
        simulation: {
          simulation_id: '',
          supply_chain_id: this.selectedSupplyChainId,
          time_interval: this.selectedInterval.value,
          simulation_length: this.noOfDays,
        },
      };
      this.weekNo = 0;
      this.spinner.show('calculate');
      this.simulationService.createSimulationDataById(payload).subscribe((res: any) => {
        this.spinner.hide('calculate');
        if (res) {
          // console.log(res);
          if (res.simulation && res.simulation.simulation_id) {
            this.simulationId = res.simulation.simulation_id;
            this.getSimulationData(this.simulationId, this.weekNo);
          } else {
            this.spinner.hide('calculate');
            this.Toast.fire({
              icon: 'warning',
              title: 'Something went wrong please try again later.',
            });
          }
        }
      });
    }
  }
  getSimulationData(simId: any, wNo: any): any {
    if (simId) {
      const payload = {
        simulationId: simId,
        section: wNo,
      };
      if (wNo === 1) {
        this.spinner.show('calculate');
      }
      this.simulationService.getSimulationDataById(payload).subscribe((res: any) => {
        if (wNo === 1) {
          this.spinner.hide('calculate');
        }
        if (res) {
          // console.log(res);
          if (this.weekNo === 0) {
            this.intervalData = [];
            this.lineChartLabels = [];
          }
          if (res && res.simulation?.length > 0) {
            for (const time of res.simulation) {
              const convertToString = String(time.CurrentTimeStamp);
              const min = convertToString.slice(-2);
              const hr = convertToString.slice(-4, -2);
              const day = convertToString.slice(-5, -4);
              const week = convertToString.length === 7 ? convertToString.slice(0, 2) : convertToString.slice(0, 1);
              time.CurrentTimeStamp = 'W' + week + ' D' + day + ' ' + hr + ':' + min;
              time.WeekTimeStamp = 'Week ' + week + ' Day ' + day + ' ' + hr + ':' + min;
              this.intervalData.push(time);
              // this.lineChartLabels.push(time.CurrentTimeStamp);
            }
            this.playSimulation();
          } else {
            this.stopSimulation();
          }
        }
      });
    }
  }
  supplyChainChange(supplyChainId: any): void {
    if (supplyChainId) {
      this.getFacilityList(supplyChainId);
    }
  }
  getFacilityList(sId: any): void {
    const payload = {
      supply_chain_id: sId,
    };
    this.spinner.show();
    this.simulationService.getFacilityList(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.simulationFacility = res.facilities;
        this.simulationFacility.forEach((facility: { id: number, name: any; }) => {
          this.facilities.push({
            id: facility.id,
            name: facility.name,
            selected: true
          });
        });
        this.markers = [];
        this.polylines = [];
        if (this.simulationFacility?.length > 0) {
          let routeID = 1; // TODO:: Need to remove for dynamic id
          let colorIndex = 0;
          for (const facility of this.simulationFacility) {
            facility.latitude = parseFloat(facility.latitude);
            facility.longitude = parseFloat(facility.longitude);
            const iconObj = {
              url: removeQueryString(facility.icon),
              scaledSize: {
                width: 25,
                height: 25,
              },
            };
            this.markers.push({
              facilityId: facility.id,
              label: facility.name,
              lat: facility.latitude,
              lng: facility.longitude,
              draggable: false,
              animation: 'DROP',
              icon: iconObj,
            });
            if (facility.routes?.length > 0) {
              for (const route of facility.routes) {
                const vehicleIconObj = {
                  url: removeQueryString(route.vehicle_type_icon),
                  scaledSize: {
                    width: 30,
                    height: 30,
                  },
                  size: {
                    width: 30,
                    height: 30,
                  },
                  anchor: {
                    x: 10,
                    y: 10,
                  },
                  origin: {
                    x: 0,
                    y: 0,
                  },
                };
                this.vehicleMarkers.push({
                  routeId: route.id, // TODO:: Need to routeID remove and set route.id
                  label: route.name,
                  lat: facility.latitude,
                  lng: facility.longitude,
                  draggable: false,
                  animation: 'DROP',
                  icon: vehicleIconObj,
                });
                this.mapBound = this.markers?.length > 1;
                this.cdr.detectChanges();
                const parseGeopath = JSON.parse(route.geopath);
                // console.log('parseGeopath:::', parseGeopath);
                const geoPath = google.maps.geometry.encoding.decodePath(parseGeopath);
                const pathArry = geoPath.map(path => {
                  return path.toJSON();
                });
                // const pathArry = lzwCompress.unpack(parseGeopath);
                // console.log('pathArry:::', pathArry);
                const polylineObj = {
                  color: this.getRandomColor(),
                  visible: true,
                  path: pathArry,
                };
                this.polylines.push(polylineObj);
                this.geopathHashMap[route.id] = {
                  path: pathArry,
                  index: 0,
                };
                // this.geopathHashMap[routeID] = pathArry;         // TODO:: Need to routeID remove and set route.id
                ++routeID; // TODO:: Need to routeID for dynamic data
                ++colorIndex;
              }
            }
          }
        }
        // console.log('geopathHash:::', this.geopathHashMap);
        // console.log('VehicleMarkers:::', this.vehicleMarkers);
        this.clonedVehicleMarkers = this.clonerService.deepClone(this.vehicleMarkers);
        /*if (this.markers?.length > 1) {
          this.mapBound = true;
        } else {
          this.zoom = 12;
          this.mapBound = false;
        }*/
        this.mapBound = true;
        this.cdr.detectChanges();
      }
    });
  }
  getTimeIntervalList(): void {
    this.simulationService.getTimeIntervalDataService().subscribe((res: any) => {
      if (res) {
        this.intervalList = res.time_intervals;
        this.selectedInterval = this.intervalList[0];
        this.cdr.detectChanges();
      }
    });
  }
  updateSpeedMultiplier(speed: any): void {
    this.speedMultiplier = speed;
    this.speedChange(this.selectedInterval);
  }
  speedChange(interval: any): void {
    if (interval) {
      this.selectedInterval = interval;
      let intervalTime = 0;
      if (interval.value > 10 && interval.value <= 15) {
        intervalTime = 650;
      } else if (interval.value > 15 && interval.value <= 30) {
        intervalTime = 575;
      } else if (interval.value > 30 && interval.value <= 45) {
        intervalTime = 500;
      } else if (interval.value > 45 && interval.value <= 60) {
        intervalTime = 450;
      } else if (interval.value > 60 && interval.value <= 120) {
        intervalTime = 350;
      } else if (interval.value > 120 && interval.value <= 600) {
        intervalTime = 250;
      } else if (interval.value > 600 && interval.value <= 1440) {
        intervalTime = 150;
      } else if (interval.value > 1440) {
        intervalTime = 100;
      } else {
        intervalTime = 700;
      }
      this.intervalTime = intervalTime * this.speedMultiplier.value;
    }
  }
  playSimulation(): void {
    if (this.pauseClicked) {
      return;
    }
    this.pauseBtnDisable = false;
    this.stopBtnDisable = false;
    this.playBtnDisable = true;
    const dataLength = this.intervalData.length;
    // this.markerRunInterval = 1;
    // Graph data start.....
    if (this.weekNo === 0) {
      this.firstIntervalData = this.intervalData[0];
      // this.graphHashMapData = {};
      for (const facility of this.firstIntervalData.TotalFacility) {
        if (this.firstIntervalData.TotalFacilityScheduleProducts?.length > 0) {
          facility.products = [];
          facility.products = this.firstIntervalData.TotalFacilityScheduleProducts.filter(
            (fObj: any) => fObj.FacilityID === facility.ID
          );
          // Hash map preparation
          facility.onHandAmount = [];
          facility.in = [];
          facility.out = [];
          if (facility.products?.length > 0) {
            for (const prod of facility.products) {
              facility.onHandAmount.push({
                data: [],
                label: prod.ProductName,
                lineTension: 0,
                fill: 0,
              });
              facility.in.push({
                data: [],
                label: prod.ProductName,
                lineTension: 0,
                fill: 0,
              });
              facility.out.push({
                data: [],
                label: prod.ProductName,
                lineTension: 0,
                fill: 0,
              });
            }
          }
        }
      }
      // console.log('firstIntervalData:1111::', this.firstIntervalData);
    }
    // Graph data end......
    this.callInterval = setInterval(() => {
      if (this.intervalId >= this.intervalData.length) {
        if (this.weekNo === 10) {
          this.stopSimulation();
          this.resetMarkers();
          return;
        } else {
          ++this.weekNo;
          this.getSimulationData(this.simulationId, this.weekNo);
          clearInterval(this.callInterval);
          return;
        }
      }
      // time formation
      this.formatedTime = this.intervalData[this.intervalId].CurrentTimeStamp;
      // time formation end
      this.lineChartLabels.push(this.formatedTime);
      // this.displayIntervalData = this.intervalData[this.intervalId];
      this.firstIntervalData.CurrentTimeStamp = this.intervalData[this.intervalId].CurrentTimeStamp;
      this.firstIntervalData.TotalFacilityScheduleProducts =
        this.intervalData[this.intervalId].TotalFacilityScheduleProducts;
      this.firstIntervalData.Vehicles = this.intervalData[this.intervalId].Vehicles;
      const totalFacility = this.intervalData[this.intervalId].TotalFacility;
      for (let facility = 0; facility < totalFacility.length; facility++) {
        if (this.firstIntervalData.TotalFacilityScheduleProducts?.length > 0) {
          totalFacility[facility].products = [];
          totalFacility[facility].products = this.firstIntervalData.TotalFacilityScheduleProducts.filter(
            (fObj: any) => fObj.FacilityID === totalFacility[facility].ID
          );
        }
        // new logic start
        this.firstIntervalData.TotalFacility[facility].ID = totalFacility[facility].ID;
        this.firstIntervalData.TotalFacility[facility].Name = totalFacility[facility].Name;
        this.firstIntervalData.TotalFacility[facility].CarbonOutput = totalFacility[facility].CarbonOutput;
        this.firstIntervalData.TotalFacility[facility].EnergyCost = totalFacility[facility].EnergyCost;
        this.firstIntervalData.TotalFacility[facility].LaborCost = totalFacility[facility].LaborCost;
        this.firstIntervalData.TotalFacility[facility].OperationCost = totalFacility[facility].OperationCost;
        this.firstIntervalData.TotalFacility[facility].RentCost = totalFacility[facility].RentCost;
        this.firstIntervalData.TotalFacility[facility].StorageCapacity = totalFacility[facility].StorageCapacity;
        this.firstIntervalData.TotalFacility[facility].StoredAmount = totalFacility[facility].StoredAmount;
        this.firstIntervalData.TotalFacility[facility].products = totalFacility[facility].products;
        // new logic end
        if (totalFacility[facility].products && totalFacility[facility].products.length > 0) {
          for (let fProduct = 0; fProduct < totalFacility[facility].products.length; fProduct++) {
            this.firstIntervalData.TotalFacility[facility].onHandAmount[fProduct].data.push(
              totalFacility[facility].products[fProduct].OnHandAmount
            );
            this.firstIntervalData.TotalFacility[facility].in[fProduct].data.push(
              totalFacility[facility].products[fProduct].In
            );
            this.firstIntervalData.TotalFacility[facility].out[fProduct].data.push(
              totalFacility[facility].products[fProduct].Out
            );
          }
        }
      }
      // console.log('firstIntervalData:2222::', this.firstIntervalData);

      this.vehicleRun(this.firstIntervalData.Vehicles, this.intervalId);
      /*for (const vehicle of this.firstIntervalData.Vehicles) {
        if (this.geopathHashMap[vehicle.RouteID]) {
          const hashMapLength = this.geopathHashMap[vehicle.RouteID].path.length;
          if (hashMapLength > dataLength) {
            markerRunInterval = Math.round(hashMapLength / dataLength);
            // run marker
            const foundIndex = this.vehicleMarkers.findIndex((x: any) => x.routeId === vehicle.RouteID);
            if (foundIndex > -1) {
              const intId = this.intervalId * markerRunInterval;
              this.vehicleMarkers[foundIndex].lat = this.geopathHashMap[vehicle.RouteID].path[intId].lat;
              this.vehicleMarkers[foundIndex].lng = this.geopathHashMap[vehicle.RouteID].path[intId].lng;
            }
          } else {
            markerRunInterval = Math.round(dataLength / hashMapLength);
            if (this.intervalId !== 0 && ((this.intervalId % markerRunInterval) === 0)) {
              // run marker
              const foundIndex = this.vehicleMarkers.findIndex((x: any) => x.routeId === vehicle.RouteID);
              if (foundIndex > -1) {
                this.vehicleMarkers[foundIndex].lat = this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lat;
                this.vehicleMarkers[foundIndex].lng = this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lng;
                ++this.geopathHashMap[vehicle.RouteID].index;
              }
            }
          }
        }
      }*/
      ++this.intervalId;
      // this.vehicleMarkers
      // console.log('intervalId:::', this.intervalId);
      // console.log('displayIntervalData:::', this.displayIntervalData);
      this.cdr.detectChanges();
      this.mapBound = false;
    }, this.intervalTime);
  }
  vehicleRun(vehicleData: any, intervalIndex: any): any {
    const dataLength = this.intervalData.length;
    for (const vehicle of vehicleData) {
      if (this.geopathHashMap[vehicle.RouteID]) {
        const hashMapLength = this.geopathHashMap[vehicle.RouteID].path.length;
        if (hashMapLength > dataLength) {
          this.markerRunInterval = Math.round(hashMapLength / dataLength);
          // run marker
          const foundIndex = this.vehicleMarkers.findIndex((x: any) => x.routeId === vehicle.RouteID);
          if (foundIndex > -1) {
            const intId = intervalIndex * this.markerRunInterval;
            if (intId < hashMapLength) {
              this.vehicleMarkers[foundIndex].lat = this.geopathHashMap[vehicle.RouteID].path[intId].lat;
              this.vehicleMarkers[foundIndex].lng = this.geopathHashMap[vehicle.RouteID].path[intId].lng;
            } else {
              this.intervalId = dataLength;
            }
            // this.vehicleMarkers[foundIndex].setPosition(new google.maps.LatLng(this.geopathHashMap[vehicle.RouteID].path[intId].lat, this.geopathHashMap[vehicle.RouteID].path[intId].lng));
            /*// Animation code start here...
            this.delayIndex = 0;
            this.deltaLat = (this.geopathHashMap[vehicle.RouteID].path[intId].lat - this.vehicleMarkers[foundIndex].lat) / this.numDeltas;
            this.deltaLng = (this.geopathHashMap[vehicle.RouteID].path[intId].lng - this.vehicleMarkers[foundIndex].lng) / this.numDeltas;
            this.moveMarkerSmoothly(foundIndex);
            // Animation code end here...*/
          }
        } else {
          this.markerRunInterval = Math.round(dataLength / hashMapLength);
          if (intervalIndex !== 0 && intervalIndex % this.markerRunInterval === 0) {
            // run marker
            const foundIndex = this.vehicleMarkers.findIndex((x: any) => x.routeId === vehicle.RouteID);
            if (foundIndex > -1) {
              this.vehicleMarkers[foundIndex].lat =
                this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lat;
              this.vehicleMarkers[foundIndex].lng =
                this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lng;
              // this.vehicleMarkers[foundIndex].setPosition(new google.maps.LatLng(this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lat, this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lng));
              /*// Animation code start here...
              this.delayIndex = 0;
              this.deltaLat = (this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lat - this.vehicleMarkers[foundIndex].lat) / this.numDeltas;
              this.deltaLng = (this.geopathHashMap[vehicle.RouteID].path[this.geopathHashMap[vehicle.RouteID].index].lng - this.vehicleMarkers[foundIndex].lng) / this.numDeltas;
              this.moveMarkerSmoothly(foundIndex);
              // Animation code end here...*/
              ++this.geopathHashMap[vehicle.RouteID].index;
            }
          }
        }
      }
    }
  }
  /*moveMarkerSmoothly(vIndex: any): any {
    this.vehicleMarkers[vIndex].lat += this.deltaLat;
    this.vehicleMarkers[vIndex].lng += this.deltaLng;
    if (this.delayIndex !== this.numDeltas) {
      ++this.delayIndex;
      setTimeout(this.moveMarkerSmoothly(vIndex), this.delay);
    }
  }*/
  resumeSimulation(): void {
    this.pauseClicked = false;
    this.pauseBtnDisable = false;
    this.playBtnDisable = true;
    this.stopClicked = false;
    this.playSimulation();
  }
  pauseSimulation(): void {
    // pause
    clearInterval(this.callInterval);
    this.pauseClicked = true;
    this.pauseBtnDisable = true;
    this.playBtnDisable = false;
    this.stopClicked = false;
  }
  stopSimulation(): void {
    // stop
    this.showDownloadReport = true;
    clearInterval(this.callInterval);
    this.pauseClicked = false;
    this.pauseBtnDisable = true;
    this.stopBtnDisable = true;
    this.playBtnDisable = false;
    this.intervalId = 0;
    this.markerRunInterval = 1;
    this.stopClicked = true;
    this.weekNo = 0;
    for (const g in this.geopathHashMap) {
      if (this.geopathHashMap.hasOwnProperty(g)) {
        if (g) {
          this.geopathHashMap[g].index = 0;
        }
      }
    }

    this.reportData = {
      statistic_tabl: {
        cols: this.generateStatisticTableCols(this.intervalData),
        rows: this.generateStatisticTableRows(this.intervalData)
      },
      statistic_demands_tabl: {
        cols: this.generateStatisticDemandTableCols(),
        rows: this.generateStatisticDemandTableRows()
      },
      statistic_outputs_tabl: {
        cols: this.generateStatisticProductionTableCols(),
        rows: this.generateStatisticProductionTableRows()
      },
      statistic_in_tabl: {
        cols: this.generateStatisticTableCols(this.intervalData),
        rows: this.generateStatisticInTablRows(this.intervalData)
      },
      statistic_out_tabl: {
        cols: this.generateStatisticTableCols(this.intervalData),
        rows: this.generateStatisticOutTablRows(this.intervalData)
      },
      facility_cost_tabl: {
        cols: this.generateStatisticTableCols(this.intervalData),
        rows: this.generateFacilityCostTableRows(this.intervalData)
      },
      vehicle_cost_tabl: {
        cols: this.generateVehicleCostTableCols(),
        rows: this.generateVehicleCostTableRows(this.intervalData)
      }
    };
  }

  downloadReportData(): void {
    const payload = {
      simulation_id: this.simulationId,
      data: JSON.stringify(this.reportData)
    };
    console.log(payload.data);
    this.simulationService.downloadSimulationReport(payload).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = `simulation_report_${this.simulationId}.xls`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }

  generateStatisticTableCols(intervalData: any): any {
    const cols = [{
      id: '',
      label: 'Facility',
      pattern: '',
      type: 'string'
    }];
    intervalData.forEach((data: any) => {
      cols.push({
        id: '',
        label: data.WeekTimeStamp,
        pattern: '',
        type: 'string'
      });
    });
    return cols;
  }

  generateStatisticDemandTableCols(): any {
    const cols = [{
      id: '',
      label: 'Facility',
      pattern: '',
      type: 'string'
    },
    {
      id: '',
      label: 'Demand',
      pattern: '',
      type: 'string'
    }
    ];
    return cols;
  }

  generateStatisticProductionTableCols(): any {
    const cols = [{
      id: '',
      label: 'Facility',
      pattern: '',
      type: 'string'
    },
    {
      id: '',
      label: 'Production',
      pattern: '',
      type: 'string'
    }];
    return cols;
  }

  generateVehicleCostTableCols(): any {
    const cols = [{
      id: '',
      label: 'Vehicle',
      pattern: '',
      type: 'string'
    },
    {
      id: '',
      label: 'Running Cost',
      pattern: '',
      type: 'number'
    },
    {
      id: '',
      label: 'Total Corbon (Kg)',
      pattern: '',
      type: 'number'
    },
    {
      id: '',
      label: 'Facility',
      pattern: '',
      type: 'string'
    }];
    return cols;
  }

  generateStatisticTableRows(intervalData: any): any {
    const rows: any = [];
    intervalData[0].TotalFacilityScheduleProducts.forEach((facilityScheduleProduct: any) => {
      rows.push({
          c: this.getFaciltyProductOnHandValues(intervalData, facilityScheduleProduct),
        });
    });
    return rows;
  }

  generateStatisticDemandTableRows(): any {
    const rows: any = [];
    this.simulationFacility.forEach((facility: any) => {
      facility.facility_schedule_products.forEach((products: any) => {
        rows.push(
          {
            c: [{v: facility.name + ' - ' + products.product_name}, {v: products.demand}],
          });
      });
    });
    return rows;
  }

  generateStatisticProductionTableRows(): any {
    const rows: any = [];
    this.simulationFacility.forEach((facility: any) => {
      facility.facility_schedule_products.forEach((products: any) => {
        rows.push(
          {
            c: [{v: facility.name + ' - ' + products.product_name}, {v: products.production}],
          });
      });
    });
    return rows;
  }

  generateStatisticInTablRows(intervalData: any): any {
    const rows: any = [];
    intervalData[0].TotalFacilityScheduleProducts.forEach((facilityScheduleProduct: any) => {
      rows.push(
        {
          c: this.getFaciltyProductInValues(intervalData, facilityScheduleProduct),
        });
    });
    return rows;
  }

  generateStatisticOutTablRows(intervalData: any): any {
    const rows: any = [];
    intervalData[0].TotalFacilityScheduleProducts.forEach((facilityScheduleProduct: any) => {
      rows.push(
        {
          c: this.getFaciltyProductOutValues(intervalData, facilityScheduleProduct),
        });
    });
    return rows;
  }

  generateFacilityCostTableRows(intervalData: any): any {
    const rows: any = [];
    intervalData[0].TotalFacility.forEach((FacilityData: any) => {
      rows.push(
        {
          c: this.getFacilityCostValues(intervalData, FacilityData),
        });
    });
    return rows;
  }

  generateVehicleCostTableRows(intervalData: any): any {
    const rows: any = [];
    intervalData[intervalData.length - 1].Vehicles.forEach((vehicleData: any) => {
      rows.push(
        {
          c: this.getVehicleCostValues(intervalData, vehicleData),
        });
    });
    return rows;
  }

  getFaciltyProductOnHandValues(intervalData: any, facilityScheduleProduct: any): any {
    const productWithFaciltityOnHand = [{ v: facilityScheduleProduct.FacilityName + ' - ' + facilityScheduleProduct.ProductName }];
    intervalData.forEach((data: any) => {     
      const productWithFaciltiyObj = data.TotalFacilityScheduleProducts.find((productFacilty: any) => productFacilty.FacilityID === facilityScheduleProduct.FacilityID && productFacilty.ProductId === facilityScheduleProduct.ProductId);
      if (productWithFaciltiyObj) {
        productWithFaciltityOnHand.push({v: productWithFaciltiyObj.OnHandAmount});
      }
    });
    return productWithFaciltityOnHand;
  }

  getFaciltyProductInValues(intervalData: any, facilityScheduleProduct: any): any {
    const productWithFaciltityIn = [{ v: facilityScheduleProduct.FacilityName + ' - ' + facilityScheduleProduct.ProductName }];
    intervalData.forEach((data: any) => {     
      const productWithFaciltiyObj = data.TotalFacilityScheduleProducts.find((productFacilty: any) => productFacilty.FacilityID === facilityScheduleProduct.FacilityID && productFacilty.ProductId === facilityScheduleProduct.ProductId);
      if (productWithFaciltiyObj) {
        productWithFaciltityIn.push({v: productWithFaciltiyObj.In});
      }
    });
    return productWithFaciltityIn;
  }

  getFaciltyProductOutValues(intervalData: any, facilityScheduleProduct: any): any {
    const productWithFaciltityOut = [{ v: facilityScheduleProduct.FacilityName + ' - ' + facilityScheduleProduct.ProductName }];
    intervalData.forEach((data: any) => {     
      const productWithFaciltiyObj = data.TotalFacilityScheduleProducts.find((productFacilty: any) => productFacilty.FacilityID === facilityScheduleProduct.FacilityID && productFacilty.ProductId === facilityScheduleProduct.ProductId);
      if (productWithFaciltiyObj) {
        productWithFaciltityOut.push({v: productWithFaciltiyObj.Out});
      }
    });
    return productWithFaciltityOut;
  }

  getFacilityCostValues(intervalData: any, FacilityData: any): any {
    const facilityValues = [{ v: FacilityData.Name }];
    intervalData.forEach((data: any) => {     
      const facilityObj = data.TotalFacility.find((facility: any) => facility.ID === FacilityData.ID);
      if (facilityObj) {
        facilityValues.push({v: (facilityObj.OperationCost) + (facilityObj.StorageCapacity * facilityObj.RentCost)});
      }
    });
    return facilityValues;
  }

  getVehicleCostValues(intervalData: any, vehicleData: any): any {
    const vehicleValues = [{ v: vehicleData.VehicleName }];
    vehicleValues.push({v: vehicleData.CostPerDistance});
    vehicleValues.push({v: vehicleData.CurrentCarbonOutput});
    vehicleValues.push({v: this.getFacilityName(vehicleData.CurrentFacilityID)});
    return vehicleValues;
  }

  getFacilityName(facilityId: number): string {
    const facility = this.simulationFacility.find((sFacility: any) => sFacility.id === facilityId);
    return facility.name;
  }


  resetMarkers(): void {
    // nnhhnhn
    this.vehicleMarkers = this.clonedVehicleMarkers;
    this.cdr.detectChanges();
  }
  accordionOpen(e: boolean, i: number): any {
    this.selectedAccordion = i;
  }
  onSelectTab(tabId: number): any {
    // this.staticTabs.tabs[tabId].active = true;
    // this.selectedTabIndex = tabId;
    if (tabId === 0) {
      this.tabActive0 = true;
      this.tabActive1 = false;
      this.tabActive2 = false;
    } else if (tabId === 1) {
      this.tabActive0 = false;
      this.tabActive1 = true;
      this.tabActive2 = false;
    } else if (tabId === 2) {
      this.tabActive0 = false;
      this.tabActive1 = false;
      this.tabActive2 = true;
    }
  }

  getSupplyChainByID(id: number): Observable<SupplyChainDto> {
    return this.supplyChainService.getSupplyChainByID(id).pipe(
      catchError(error => {
        if (error.status !== 200) {
          this.Toast.fire({
            icon: 'warning',
            title: 'Supply chain not found',
          });

          this.router.navigate(['/dashboard']);
        }
        return throwError(() => new Error('Failed to fetch supply chain'));
      }),
      map((res: SupplyChain) => res.supply_chain)
    );
  }

// start download report
  openDownloadReportModal(): void {
    $('#showReportModal > .ui-modal').css({ display: 'block', 'z-index': 10 });
   
  }
  closeDownloadReportModal(): void{
    $('#showReportModal > .ui-modal').css({ display: 'none', 'z-index': 0 });
  }

  downloadReport(): void {
    const selectedFacilities = this.facilities.filter((facility: { selected: any; }) => facility.selected).map((facility: { id: any; }) => facility.id);
    const payload = {
      sc_id: this.selectedSupplyChainId,
      from_day: this.startDay,
      to_day: this.endDay,
      report_type: this.reportType,
      facility_ids: selectedFacilities,
      data: JSON.stringify(this.reportData)
    };

    this.simulationService.downloadPNLReport(payload).subscribe((response: Blob) => {
      const url = window.URL.createObjectURL(response);
      const a = document.createElement('a');
      a.href = url;
      a.download = `simulation_report_${this.simulationId}.xls`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  }
  // End download report
}
