import { MapsAPILoader, MouseEvent } from '@agm/core';
import { ChangeDetectorRef, Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { CloneService } from '../../services/clone.service';
import { DashboardService } from '../../services/dashboard.service';
import { SimulationService } from '../../services/simulation.service';
import { AiSuggestService } from '../../services/ai-suggest.service';
// @ts-ignore
// import lzwCompress from 'lzwcompress';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { SupplyChainService } from 'src/app/services/supply-chain.service';
import { removeQueryString } from 'src/lib/icons-helper';
import { FacilityScheduleData } from 'src/types/components/supply-chains.types';
import { SupplyChainDto } from 'src/types/dtos/supply-chain.types';
import { SupplyChain } from 'src/types/services/supply-chain-service.types';

declare var $: any;

@Component({
  selector: 'app-supplychain',
  templateUrl: './supplychain.component.html',
  styleUrls: ['./supplychain.component.css'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }],
})
export class SupplychainComponent implements OnInit {
  latitude: any;
  longitude: any;
  zoom = 2;
  maxZoom = 10;
  address = '';
  mapBound: any;
  private disableMapZoom = false;
  private geoCoder: any;
  selectedSupplyChain: any;
  supplyChainList: any[] = [];
  paramsId: any;

  @ViewChild('search')
  public searchElementRef: ElementRef | any;
  supplyFacility: any = [];
  facilityVehicle: any = [];
  faciVehiRoute: any = [];
  supplyName = '';
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  editSupplyMode = false;
  productList: any = [];
  productForm: FormGroup | any;
  productName = '';
  productPrice = 0;
  productCubeSize = 0;
  productWeight = 0;
  productUpdate = false;
  productId = '';
  facilityForm: FormGroup | any;
  facilityName = '';
  selectedfacilityType: any;
  storageCapacity = 0;
  operatingCost = 0;
  rentCost = 0;
  carbonOutput = 0;
  energyCost = 0;
  laborCost = 0;
  totalArea = 0;
  facilityUpdate = false;
  facilityId = '';
  noOfWeeksList: any = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  selectedWeek: any = 1;
  minTime: Date = new Date();
  maxTime: Date = new Date();
  dummyScheduleData: any = [];
  facilityTypes: any = [];
  addProductInFacilityForm: FormGroup | any;
  selectedProdInFacility: any;
  pDemand = 0;
  pProduction = 0;
  pOnHandAmount = 0;
  pCapacityUsed = 0;
  facilityScheduleData: any = [];
  previousFacilityScheduleData: any = [];
  // facilityScheduleData: Array<FacilityScheduleData> = [];
  selectedWeekList: any = [];
  facilityData: any;
  facilityCopyId: any;
  showMarker = false;
  markers: any = [];
  markerAdded = false;
  dropdownSettings = {};
  selectedNoOfWeeks: any;
  originalWeekDropData = [
    { item_id: 1 },
    { item_id: 2 },
    { item_id: 3 },
    { item_id: 4 },
    { item_id: 5 },
    { item_id: 6 },
    { item_id: 7 },
    { item_id: 8 },
    { item_id: 9 },
    { item_id: 10 },
    { item_id: 11 },
    { item_id: 12 },
  ];
  weekDropData = [];
  copyWeekNumber: any;
  vehicleForm: FormGroup | any;
  vehicleTypeList: any = [];
  vehicleUpdate = false;
  vehicleName = '';
  selectedVehicleType: any;
  vehicleSpeed: any = 0;
  vehicleCarbonOutput = 0;
  vehicleCost = 0;
  vehicleVolume = 0;
  vehicleWeight = 0;
  selectedFacility: any;
  vehicleId: any;
  facilityVehicleList: any = [];
  facilityVehicleData: any;
  startPoint: any;
  endPoint: any;
  public renderOptions = {
    suppressMarkers: true,
    draggable: true,
    polylineOptions: {
      strokeColor: '#0809c5',
      strokeOpacity: 1,
      strokeWeight: 5,
      zIndex: 1,
      geodesic: false,
      clickable: true,
      editable: false,
    },
  };
  waypoints: any = [];
  public markerOptions = {
    origin: {
      icon: '',
      label: '',
      opacity: 0,
      draggable: false,
    },
    destination: {
      icon: '',
      label: '',
      opacity: 0,
      draggable: false,
    },
    waypoints: {
      icon: '',
      label: '',
      opacity: 0,
      draggable: false,
    },
  };
  private sub: any;
  // routes variables
  routeUpdate = false;
  isCalculateRouteTime = false;
  routeForm: FormGroup | any;
  routeDistance = 0;
  routeName = '';
  routeTime: any = 0;
  selectedVehicle: any;
  selectedRouteWeek: any = 1;
  selectedRouteWeekList: any = [];
  routeScheduleData: any = [];
  dummyRouteScheduleData: any = [];
  selectedRouteFacility: any;
  addProductInStopForm: FormGroup | any;
  selectedProdInRouteStop: any;
  prodDeliveryQty = 0;
  prodPickupQty = 0;
  selectedStopObjIndex: any;
  routeId: any;
  routeData: any;
  copiedRouteId: any;
  facilityListForRouteStop: any = [];
  facilityBeforeDelData: any;
  selectedMode = '';
  selectedRoute: any;
  selectedNoOfWeeksInRoute: any;
  copyWeekNumberRoute: any;
  manualRoute: any = [];
  routeVisible = false;
  totalStorage = 0;
  oldOnHandAmount = 0;
  polylines: any = [];
  allGeoPaths: any = [];
  oldPickupAmount = 0;
  oldDropOffAmount = 0;
  selectedProductId = null;
  vehicleMarkers: any = [];
  polylinePathArry: any[] = [];
  colorCodes = [
    '#00008B',
    'var(--brand)',
    '#da9328',
    '#28dada',
    '#29e46b',
    '#e43576',
    '#a121f1',
    '#2187f1',
    '#54ad2f',
    '#a79516',
    '#a76016',
    '#a73716',
    '#a71616',
    '#a05050',
    '#7947a7',
    '#b9397a',
    '#9fe245',
    '#45e2be',
    '#07f3bd',
    '#179c07',
    '#9a9c07',
  ];
  polylineMidMarkers: any = [];
  locationSearchMarker: any = {};
  searchZoom = 17;
  markerOldLat: any;
  markerOldLng: any;
  polyMarkerId = 1;
  applyToWeek = false;
  ariaExpanded = 0;
  aiSuggestions = '';

  constructor(
    private activatedRoute: ActivatedRoute,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private dashService: DashboardService,
    private cdr: ChangeDetectorRef,
    private simulationService: SimulationService,
    private aiSuggestService: AiSuggestService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private clonerService: CloneService,
    private router: Router,
    private readonly supplyChainService: SupplyChainService
  ) {
    this.latitude = 22.2736308;
    this.longitude = 70.7512555;
    this.minTime.setHours(0);
    this.minTime.setMinutes(1);
    this.maxTime.setHours(24);
    this.maxTime.setMinutes(60);
    const sTime = new Date();
    sTime.setHours(0, 1, 0);
    const eTime = new Date();
    eTime.setHours(0, 0, 0);
    this.dummyScheduleData = [
      {
        day_of_week: 1,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        product_total_demand: 0,
        product_total_production: 0,
        product_total_quantity: 0,
        product_total_storage: 0,
        product_total_storage_per: 0,
        facility_schedule_products_attributes: [],
      },
      {
        day_of_week: 2,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        product_total_demand: 0,
        product_total_production: 0,
        product_total_quantity: 0,
        product_total_storage: 0,
        product_total_storage_per: 0,
        facility_schedule_products_attributes: [],
      },
      {
        day_of_week: 3,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        product_total_demand: 0,
        product_total_production: 0,
        product_total_quantity: 0,
        product_total_storage: 0,
        product_total_storage_per: 0,
        facility_schedule_products_attributes: [],
      },
      {
        day_of_week: 4,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        product_total_demand: 0,
        product_total_production: 0,
        product_total_quantity: 0,
        product_total_storage: 0,
        product_total_storage_per: 0,
        facility_schedule_products_attributes: [],
      },
      {
        day_of_week: 5,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        product_total_demand: 0,
        product_total_production: 0,
        product_total_quantity: 0,
        product_total_storage: 0,
        product_total_storage_per: 0,
        facility_schedule_products_attributes: [],
      },
      {
        day_of_week: 6,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        product_total_demand: 0,
        product_total_production: 0,
        product_total_quantity: 0,
        product_total_storage: 0,
        product_total_storage_per: 0,
        facility_schedule_products_attributes: [],
      },
      {
        day_of_week: 7,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        product_total_demand: 0,
        product_total_production: 0,
        product_total_quantity: 0,
        product_total_storage: 0,
        product_total_storage_per: 0,
        facility_schedule_products_attributes: [],
      },
    ];
    this.dummyRouteScheduleData = [
      {
        day_of_week: 1,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        _destroy: false,
        route_stops_attributes: [],
      },
      {
        day_of_week: 2,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        route_stops_attributes: [],
      },
      {
        day_of_week: 3,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        route_stops_attributes: [],
      },
      {
        day_of_week: 4,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        route_stops_attributes: [],
      },
      {
        day_of_week: 5,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        route_stops_attributes: [],
      },
      {
        day_of_week: 6,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        route_stops_attributes: [],
      },
      {
        day_of_week: 7,
        week_number: 1,
        start_time: sTime,
        end_time: eTime,
        closed: false,
        route_stops_attributes: [],
      },
    ];
    this.selRouteWeekListUpdate();

  }

  ngOnInit(): void {
    /*const stringify = JSON.stringify('[{\'lat\':28.52712818191903,\'lng\':77.14075893007815},{\'lat\':19.043330738764915,\'lng\':72.81877604770509},{\'lat\':22.74950984314945,\'lng\':75.89666258786617},{\'lat\':18.972009861083727,\'lng\':57.7951193690704},{\'lat\':28.52712818191903,\'lng\':77.14075893007815}]');
    console.log('before compressed::---', stringify);
    const compressed = lzwCompress.pack(stringify);
    console.log('after compressed::---', compressed);
    const decompressed = lzwCompress.unpack(compressed);
    console.log('after decompressed::---', decompressed);
    const parseString = JSON.parse(decompressed);
    console.log('after parse::---', parseString);*/
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      // this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          // this.zoom = 17;
          this.changeMapZoom(18);
          // this.zoom = this.searchZoom + 0.5;
          // this.mapBound = false;
        });
      });
    });

    this.sub = this.activatedRoute.params
      .pipe(
        map(params => parseInt(params.id, 10)),
        filter(paramsId => !!paramsId),
        tap(paramsId => (this.paramsId = paramsId)),
        switchMap(paramsId =>
          this.getSupplyChainByID(paramsId).pipe(
            tap(supplyChain => {
              this.getProductList(`${supplyChain.id}`);
              this.getFacilityList(`${supplyChain.id}`);
              this.getSupplyChains('');
            })
          )
        )
      )
      .subscribe({
        error: error => console.error('Error in stream:', error),
      });

    // this.setCurrentLocation();
    /* Entity tree script goes ere... START  */
    /*const toggler = document.getElementsByClassName('caret');
    let i: number;

    for (i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener('click', (): any => {
        if (toggler[i] && toggler[i].parentElement) {
          toggler[i].parentElement.querySelector('.nested').classList.toggle('active');
        }
        toggler[i].classList.toggle('caret-down');
      });
    }*/
    /* Entity tree script goes ere... END   */
    this.productForm = this.fb.group({
      productName: [null, Validators.required],
      productPrice: [null, Validators.required],
      productCubeSize: [null, Validators.required],
      productWeight: [null, Validators.required],
      validate: '',
    });
    this.facilityForm = this.fb.group({
      facilityName: [null, Validators.required],
      facilityType: [null, Validators.required],
      storageCapacity: [null, Validators.required],
      operatingCost: [null, Validators.required],
      rentCost: [null, Validators.required],
      carbonOutput: [null, Validators.required],
      energyCost: [null, Validators.required],
      laborCost: [null, Validators.required],
      totalArea: [null, Validators.required],
      validate: '',
    });
    this.vehicleForm = this.fb.group({
      vName: [null, Validators.required],
      vType: [null, Validators.required],
      vVolume: [null, Validators.required],
      vWeight: [null, Validators.required],
      vSpeed: [null, Validators.required],
      vCost: [null, Validators.required],
      vCarbonOut: [null, Validators.required],
      validate: '',
    });
    this.routeForm = this.fb.group({
      routeName: [null, Validators.required],
      validate: '',
    });
    this.addProductInFacilityForm = this.fb.group({
      pName: [null, Validators.required],
      pDemand: [null, Validators.required],
      pProduction: [null, Validators.required],
      pOnHandAmount: [null, Validators.required],
      pCapacityUsed: [null, Validators.required],
      validate: '',
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_id',
      enableCheckAll: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false,
      clearSearchFilter: true,
      maxHeight: 120,
      noDataAvailablePlaceholderText: 'No data found',
    };
    this.getFacilityType();
    this.getVehicleType();
    this.addProductInStopForm = this.fb.group({
      prodName: [null, Validators.required],
      prodDeliveryQty: [null, Validators.required],
      prodPickupQty: [null, Validators.required],
      validate: '',
    });
    setTimeout(() => {
      jQuery('input[type="number"]').on('mouseup', function(): any {
        jQuery(this).select().focus(); // select all text when input field focus
      });
    }, 1000);
  }

  changeMapZoom(e: any): any {
    this.zoom = e;
  }

  getProductList(pId: string): void {
    const payload = {
      supply_chain_id: pId,
    };
    this.simulationService.getProductListById(payload).subscribe((res: any) => {
      if (res) {
        this.productList = res.products;
      }
    });
  }
  getFacilityList(sId: string): void {
    const payload = {
      supply_chain_id: sId,
    };
    this.spinner.show();
    this.simulationService.getFacilityListById(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.supplyFacility = res.facilities.map((facility: any) => {
          return {
            ...facility,
            icon: removeQueryString(facility.icon),
          };
        });
        this.markers = [];
        this.polylines = [];
        if (this.supplyFacility?.length > 0) {
          // let colorIndex = 0;
          for (const facility of this.supplyFacility) {
            facility.latitude = parseFloat(facility.latitude);
            facility.longitude = parseFloat(facility.longitude);
            const iconObj = {
              url: facility.icon,
              scaledSize: {
                width: 25,
                height: 25,
              },
            };
            this.markers.push({
              id: facility.id,
              label: facility.name,
              lat: facility.latitude,
              lng: facility.longitude,
              draggable: false,
              animation: 'DROP',
              icon: iconObj,
            });
            if (facility.routes?.length > 0) {
              for (const route of facility.routes) {
                const parseGeopath = JSON.parse(route.geopath);
                 // console.log('parseGeopath:::', parseGeopath);
                const geoPath = google.maps.geometry.encoding.decodePath(parseGeopath);
                const pathArry = geoPath.map(path => {
                  return path.toJSON();
                });
                const polylineObj = {
                  color: '#0809c5',
                  visible: true,
                  path: pathArry,
                };
                this.polylines.push(polylineObj);
                // ++colorIndex;
              }
            }
          }
        }
        if (this.markers?.length > 1) {
          this.mapBound =
            !this.disableMapZoom ??
            setTimeout(() => {
              this.disableMapZoom = false;
              return true;
            }, 100);
          // this.changeMapZoom(12);
        } else {
          // this.changeMapZoom(2);
          this.mapBound = false;
        }
        this.changeMapZoom(this.zoom);
      }
    });
  }
  getSupplyChains(type: any): void {
    this.dashService.getSupplyChainList().subscribe((res: any) => {
      if (res) {
        this.supplyChainList = res.supply_chain;
        if (this.supplyChainList && this.supplyChainList.length > 0) {
          if (type === 'new') {
            // this.selectedSupplyChain = this.supplyChainList[0];
            this.router.navigate(['/supplyChain', this.supplyChainList[0].id]);
          } else {
            for (const obj of this.supplyChainList) {
              if (obj.id === this.paramsId) {
                this.selectedSupplyChain = obj;
              }
            }
          }
        }
        this.cdr.detectChanges();
      }
    });
  }
  supplyChainChange(sc: any): void {
    if (sc && sc.id) {
      this.router.navigate(['/supplyChain', sc.id]);
      // this.getProductList(sc.id);
      // this.getFacilityList(sc.id);
    }
  }
  // Get Current Location Coordinates
  setCurrentLocation(): any {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        // get address by lat lng
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }
  markerDragStart(m: any, $event: any): void {
    console.log($event);
    /*if (m.id) {
      const iconObj = {
        url: m.icon,
        scaledSize: {
          width: 30,
          height: 30
        }
      };
      this.oldMarkerObj = {
        id: m.id,
        label: m.name,
        lat: $event.coords.lat,
        lng: $event.coords.lng,
        draggable: false,
        animation: 'DROP',
        icon: iconObj,
      };
      console.log('this.oldMarkerObj', this.oldMarkerObj);
    }*/
    this.markerOldLat = $event.coords.lat;
    this.markerOldLng = $event.coords.lng;
  }
  markerDragEnd(m: any, $event: any): void {
    console.log($event);
    m.lat = $event.coords.lat;
    m.lng = $event.coords.lng;
    this.mapBound = false;
    // this.latitude = $event.coords.lat;
    // this.longitude = $event.coords.lng;
    /*this.getAddress(this.latitude, this.longitude);*/
  }
  mapClicked($event: MouseEvent): any {
    if (this.facilityName && this.selectedfacilityType && this.selectedfacilityType.id && this.markerAdded === false) {
      this.showMarker = true;
      const iconObj = {
        url: this.selectedfacilityType.icon,
        scaledSize: {
          width: 30,
          height: 30,
        },
      };
      this.markers.push({
        label: this.facilityName || '',
        lat: $event.coords.lat,
        lng: $event.coords.lng,
        draggable: true,
        animation: 'DROP',
        icon: iconObj,
        savetype: 'new',
      });
      this.markerAdded = true;
      this.mapBound = false;
    }
  }
  mapDblClick(): any {
    alert('Map double clicked.');
  }
  markerOver(m: any, infoWindow: any, gm: any): any {
    // m.animation = 'BOUNCE';
    /*if (gm.lastOpen != null) {
      gm.lastOpen.close();
    }*/

    gm.lastOpen = infoWindow;

    infoWindow.open();
  }

  markerOut(m: any, infoWindow: any): any {
    // m.animation = '';
    infoWindow.close();
  }
  clickedMarker(markerObj: any, index: any): any {
    if (markerObj && markerObj.id) {
      const findFacility = this.supplyFacility.find((facility: any) => facility.id === markerObj.id);
      if (findFacility) {
        this.addUpdateFacilityMode('update', findFacility);
      }
    }
  }
  getAddress(latitude: number, longitude: number): void {
    this.geoCoder.geocode({ location: { lat: latitude, lng: longitude } }, (results: any[], status: string) => {
      console.log(results);
      console.log(status);
      if (status === 'OK') {
        const res = results[0];
        if (res) {
          this.zoom = 12;
          this.address = res.formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }
  toggleTree(e: any, scId: any, facilityObj: any, vehObj: any, type: string): void {
    e.preventDefault();
    console.log(e);
    e.target.parentElement.parentElement.querySelector('.nested').classList.toggle('active');
    e.target.classList.toggle('caret-down');
    if (type === 'facility') {
      if (e.target.classList.contains('caret-down')) {
        this.getFacilityVehicles(scId, facilityObj);
      } else {
        // this.getFacilityVehicles(scId, targetObject);
      }
    } else if (type === 'vehicle') {
      if (e.target.classList.contains('caret-down')) {
        this.getVehicleRouteList(scId, facilityObj, vehObj);
      } else {
        // this.getFacilityVehicles(scId, targetObject);
      }
    }
  }
  addUpdateSupplyChain(): void {
    let payload;
    this.spinner.show();
    if (this.editSupplyMode) {
      payload = {
        id: this.selectedSupplyChain.id,
        name: this.supplyName,
      };
      this.dashService.updateSupplyChainService(payload).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.getSupplyChains('');
          // @ts-ignore
          document.getElementById('supplyChainCloseBtn').click();
          this.editSupplyMode = false;
          this.Toast.fire({
            icon: 'success',
            title: 'Updated successfully',
          });
        }
      });
    } else {
      payload = {
        name: this.supplyName,
      };
      this.dashService.saveSupplyChainService(payload).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.getSupplyChains('new');
          // @ts-ignore
          document.getElementById('supplyChainCloseBtn').click();
          this.Toast.fire({
            icon: 'success',
            title: 'Added successfully',
          });
        }
      });
    }
  }
  addNewSupplyChain(cond: any): void {
    $('#addNewSupplyChainModal').modal('toggle');
    if (cond === 'show') {
      this.editSupplyMode = true;
      this.supplyName = this.selectedSupplyChain.name;
    } else {
      this.editSupplyMode = false;
      this.supplyName = '';
    }
  }
  addSupplyToHistory(): any {
    const payload = {
      supply_chain_id: this.selectedSupplyChain.id,
      name: this.selectedSupplyChain.name,
    };
    this.spinner.show();
    this.dashService.addSupplyHistoryService(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        // this.getSupplyChains();
        this.Toast.fire({
          icon: 'success',
          title: 'History added successfully',
        });
      }
    });
  }
  addUpdateProductMode(mode: string, facilityObj: any): void {
    // const largestZindex = this.getLargestZindex();
    // START:: open modal and set z-index
    $('app-modal > .ui-modal').css('z-index', 9);
    // const callId = $('#showProduct > .ui-modal');
    $('#showProduct > .ui-modal').css({ display: 'block', 'z-index': 10 });
    // callId.css('z-index', largestZindex + 1);
    // END:: open modal and set z-index
    if (mode === 'update') {
      this.productUpdate = true;
      this.productName = facilityObj.name;
      this.productPrice = facilityObj.price;
      this.productCubeSize = facilityObj.volume;
      this.productWeight = facilityObj.weight;
      this.productId = facilityObj.id;
    } else if (mode === 'clone') {
      this.productUpdate = false;
      this.productName = 'Copy of ' + facilityObj.name;
      this.productPrice = facilityObj.price;
      this.productCubeSize = facilityObj.volume;
      this.productWeight = facilityObj.weight;
      // this.productId = facilityObj.id;
    } else {
      this.productForm.reset({
        productName: '',
        productPrice: 0,
        productCubeSize: 0,
        productWeight: 0,
      });
      this.productUpdate = false;
    }
  }
  addUpdateProduct(): void {
    const payload = {
      name: this.productName,
      price: this.productPrice,
      volume: this.productCubeSize,
      weight: this.productWeight,
    };
    const payloadIds = {
      supply_chain_id: this.selectedSupplyChain.id,
      update: this.productUpdate,
      id: this.productId || '',
    };
    this.spinner.show();
    this.simulationService.addUpdateProductById(payload, payloadIds).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.Toast.fire({
          icon: 'success',
          title: 'Saved successfully',
        });
        this.productForm.reset();
        this.getProductList(this.selectedSupplyChain.id);
        // @ts-ignore
        // document.getElementById('closeProductForm').click();
        this.closeModalLib('showProduct', '');
      }
    });
  }
  closeModalLib(id: string, fId: any): void {
    $('#' + id + ' > .ui-modal').css('display', 'none');
    if (id === 'showFacility') {
      if (this.markers?.length > 0) {
        const index = this.markers.findIndex((x: any) => x.savetype === 'clone' || x.savetype === 'new');
        if (index !== -1) {
          this.markers.splice(index, 1);
          this.mapBound = false;
        }
        /*for (const marker of this.markers) {
          marker.draggable = false;
        }*/
        if (fId) {
          const markerIndex = this.markers.findIndex((x: any) => x.id === fId);
          this.markers[markerIndex].draggable = false;
          this.markers[markerIndex].lat = this.markerOldLat;
          this.markers[markerIndex].lng = this.markerOldLng;
        }
      }
      this.markerAdded = false;
      this.selectedfacilityType = '';
      this.facilityData = null;
    }
    if (id === 'showVehicle') {
      const vehicleIndex = this.vehicleMarkers.findIndex((x: any) => x.savetype === 'clone' || x.savetype === 'new');
      if (vehicleIndex !== -1) {
        this.vehicleMarkers.splice(vehicleIndex, 1);
        this.mapBound = false;
      }
    }
    if (id === 'showRoutes') {
      // this.waypoints = [];
      // this.polylines = [];
      this.mapBound = false;
      // this.zoom = 8;
      this.startPoint = null;
      this.endPoint = null;
      this.routeVisible = false;
      // this.polylinePathArry = [];
      this.polylineMidMarkers = [];
      this.polyMarkerId = 1;
    }
    if (id === 'copyWeekInFacility') {
      this.selectedNoOfWeeks = [];
    }
  }
  deleteProduct(prod: any): void {
    if (prod) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'You won&#39;t be able to revert this!',
        icon: 'warning',
        customClass: {
          container: 'swalConfirmCls',
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-outline-primary',
        },
        showCancelButton: true,
        confirmButtonColor: '#f96a74',
        cancelButtonColor: '#4f9d8d',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          const payload = {
            id: prod.id,
            supplyId: this.selectedSupplyChain.id,
          };
          this.spinner.show();
          this.simulationService.deleteProductService(payload).subscribe((res: any) => {
            this.spinner.hide();
            if (res) {
              this.getProductList(this.selectedSupplyChain.id);
              this.Toast.fire({
                icon: 'success',
                title: 'Product Deleted successfully',
              });
            }
          });
        }
      });
    }
  }
  selWeekListUpdate(): void {
    if (!this.selectedWeekList.includes(this.selectedWeek)) {
      for (const dummyObj of this.dummyScheduleData) {
        // for of loop
        const clonedObj = this.clonerService.deepClone(dummyObj);
        // @ts-ignore
        clonedObj.week_number = this.selectedWeek;
        // ** NOTE:: if already added products in old weeks then copy all products in newly added week
        if (
          this.facilityScheduleData?.length > 0 &&
          this.facilityScheduleData[0].facility_schedule_products_attributes?.length > 0
        ) {
          for (const productObj of this.facilityScheduleData[0].facility_schedule_products_attributes) {
            const clonedProdObj = this.clonerService.deepClone(productObj);
            // @ts-ignore
            if (clonedProdObj.id) {
              // @ts-ignore
              delete clonedProdObj.id;
            }
            // @ts-ignore
            clonedObj.facility_schedule_products_attributes.push(clonedProdObj);
          }
        }
        // ***************
        this.facilityScheduleData.push(clonedObj);
      }
      this.selectedWeekList.push(this.selectedWeek);
      this.selectedWeekList.sort((a: any, b: any) => parseFloat(a) - parseFloat(b));
      // console.log('selectedWeekList', this.selectedWeekList);
      // console.log('facilityScheduleData after new week added:- ', this.facilityScheduleData);
      this.calculateProductTotalValues();
    }
    // const key = 'week_number';
    // this.uniqScheduleWeek = [...new Map(this.facilityScheduleData.map((item: any) => [item[key], item])).values()]; // get unique object from array of object
  }
  cloneWeekFn(w: any): void {
    if (w) {
      // START:: open modal and set z-index
      const callId = $('#copyWeekInFacility > .ui-modal');
      callId.css('display', 'block');
      const zIndex = callId.css('z-index');
      callId.css('z-index', zIndex + 9);
      // END:: open modal and set z-index
      this.copyWeekNumber = w;
      this.weekDropData = JSON.parse(JSON.stringify(this.originalWeekDropData));
      this.weekDropData = this.weekDropData.filter((week: any) => week.item_id !== w);
    }
  }
  addCopiedWeeks(): void {
    // console.log(this.selectedNoOfWeeks);
    // now below add weeks in facility...
    const copyWeekArray = this.facilityScheduleData.filter(
      (dataObj: any) => dataObj.week_number === this.copyWeekNumber
    );
    // console.log('copyWeekArry: ', copyWeekArray);
    const weekArray = [];
    for (const week of this.selectedNoOfWeeks) {
      weekArray.push(week.item_id);
      if (this.selectedWeekList.includes(week.item_id)) {
        // if week already available
        const length = this.facilityScheduleData.length;
        for (let x = 0; x < length; x++) {
          const removeIndex = this.facilityScheduleData.map((item: any) => item.week_number).indexOf(week.item_id); // get index of object with weeknumber
          if (removeIndex !== -1) {
            this.facilityScheduleData.splice(removeIndex, 1); // remove object
          }
        }
        // console.log('this.facilityScheduleData - after splice ', this.facilityScheduleData);
        for (const weekDay of copyWeekArray) {
          const cloneObj = this.clonerService.deepClone(weekDay);
          // @ts-ignore
          if (cloneObj.id) {
            // @ts-ignore
            delete cloneObj.id;
          }
          // @ts-ignore
          if (cloneObj.facility_schedule_products_attributes?.length > 0) {
            // @ts-ignore
            for (const product of cloneObj.facility_schedule_products_attributes) {
              if (product.id) {
                delete product.id;
              }
            }
          }
          // @ts-ignore
          cloneObj.week_number = week.item_id;
          this.facilityScheduleData.push(cloneObj);
        }
        // console.log('this.facilityScheduleData - after splice - after add ', this.facilityScheduleData);
      } else {
        // if week is new entry
        for (const weekDay of copyWeekArray) {
          const cloneObj = this.clonerService.deepClone(weekDay);
          // @ts-ignore
          if (cloneObj.id) {
            // @ts-ignore
            delete cloneObj.id; // remove id for new entry
          }
          // @ts-ignore
          if (cloneObj.facility_schedule_products_attributes?.length > 0) {
            // @ts-ignore
            for (const product of cloneObj.facility_schedule_products_attributes) {
              if (product.id) {
                delete product.id;
              }
            }
          }
          // @ts-ignore
          cloneObj.week_number = week.item_id;
          this.facilityScheduleData.push(cloneObj);
        }
        // console.log('this.facilityScheduleData - when new entry ', this.facilityScheduleData);
        this.selectedWeekList.push(week.item_id);
      }
      // console.log('final data this.facilityScheduleData  ', this.facilityScheduleData);
    }
    this.selectedWeekList.sort((a: any, b: any) => parseFloat(a) - parseFloat(b));
    // console.log('weekarray:- ', weekArray);
    this.calculateProductTotalValues();
    this.closeModalLib('copyWeekInFacility', '');
  }
  removeWeekFn(weekNo: any): void {
    if (weekNo) {
      if (this.selectedWeekList?.length > 1) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won&#39;t be able to revert this!',
          icon: 'warning',
          customClass: {
            container: 'swalConfirmCls',
            confirmButton: 'btn btn-outline-danger',
            cancelButton: 'btn btn-outline-primary',
          },
          showCancelButton: true,
          confirmButtonColor: '#f96a74',
          cancelButtonColor: '#4f9d8d',
          confirmButtonText: 'Yes, delete it!',
        }).then(result => {
          if (result.isConfirmed) {
            // start:: remove week from selectedWeekList
            const index = this.selectedWeekList.indexOf(weekNo);
            if (index > -1) {
              this.selectedWeekList.splice(index, 1);
            }
            console.log('selectedWeekList after remove week', this.selectedWeekList);
            // end:: remove week from selectedWeekList
            console.log('facilityScheduleData before remove week', this.facilityScheduleData);
            for (let i = 0; i < this.facilityScheduleData.length; i++) {
              if (this.facilityScheduleData[i].week_number === weekNo) {
                delete this.facilityScheduleData[i];
              }
            }
            this.facilityScheduleData = this.facilityScheduleData.filter((data: any) => data !== undefined);
            // start:: remove week data from facilityScheduleData
            // this.facilityScheduleData = this.facilityScheduleData.filter((item: any) => item.week_number !== weekNo);
            for (const schedule of this.facilityScheduleData) {
              if (schedule.week_number === weekNo) {
                schedule._destroy = true;
              }
            }
            console.log('facilityScheduleData after remove week', this.facilityScheduleData);
            // end:: remove week data from facilityScheduleData
          }
        });
      }
    }
  }
  addUpdateFacilityMode(mode: string, obj: any): void {
    // $('.modal-demo .ui-modal').css('left', '10px');

    // prevent api call again and again if facility is same
    if (mode === 'update' && this.facilityData?.id === obj.id) {
      return;
    }
    if (mode === 'add') {
      this.getFacilityType();
    }
    this.facilityForm.reset({
      facilityName: '',
      storageCapacity: 0,
      operatingCost: 0,
      rentCost: 0,
      carbonOutput: 0,
      energyCost: 0,
      laborCost: 0,
      totalArea: 0,
    });
    this.selectedWeekList = [1];
    this.facilityScheduleData = this.clonerService.deepClone(this.dummyScheduleData);
    // const largestZindex = this.getLargestZindex();
    $('app-modal > .ui-modal').css('z-index', 9);
    // const callId = $('#showFacility > .ui-modal');
    $('#showFacility > .ui-modal').css({ display: 'block', 'z-index': 10 });
    // callId.css('z-index', 10);
    // END:: open modal and set z-index
    if (mode === 'update') {
      this.getFacilityData(obj.id, '');
      this.facilityUpdate = true;
      this.facilityId = obj.id;
      this.facilityCopyId = '';
      this.updateFaciltiyRoutesOnMap(obj);
    } else if (mode === 'clone') {
      this.facilityUpdate = false;
      this.facilityCopyId = obj.id;
      this.facilityId = '';
      this.getFacilityData(obj.id, 'clone');
    } else {
      this.facilityCopyId = '';
      this.facilityId = '';
      this.facilityUpdate = false;
    }
  }

  updateFaciltiyRoutesOnMap(facility: any): void {
    this.polylines = [];
    facility.latitude = parseFloat(facility.latitude);
    facility.longitude = parseFloat(facility.longitude);
    const iconObj = {
      url: facility.icon,
      scaledSize: {
        width: 25,
        height: 25,
      },
    };
    
    if (facility.routes?.length > 0) {
      for (const route of facility.routes) {
        const parseGeopath = JSON.parse(route.geopath);
        // console.log('parseGeopath:::', parseGeopath);
        const geoPath = google.maps.geometry.encoding.decodePath(parseGeopath);
        const pathArry = geoPath.map(path => {
          return path.toJSON();
        });
        const polylineObj = {
          color: '#0809c5',
          visible: true,
          path: pathArry,
        };
        this.polylines.push(polylineObj);
        // ++colorIndex;
      }
    }
  }

  updateVehicleRoutesOnMap(facility: any, vehicle: any): void {
    this.polylines = [];
    facility.latitude = parseFloat(facility.latitude);
    facility.longitude = parseFloat(facility.longitude);
    const iconObj = {
      url: facility.icon,
      scaledSize: {
        width: 25,
        height: 25,
      },
    };
   
    if (vehicle.vehicleRoutes?.length > 0) {
      for (const route of vehicle.vehicleRoutes) {
        const parseGeopath = JSON.parse(route.geopath);
        const geoPath = google.maps.geometry.encoding.decodePath(parseGeopath);
        const pathArry = geoPath.map(path => {
          return path.toJSON();
        });
        const polylineObj = {
          color: '#0809c5',
          visible: true,
          path: pathArry,
        };
        this.polylines.push(polylineObj);
      }
    }
  }

  timeFormatToDate(time: any): any {
    const momentTime = moment(time, 'HH:mm');
    // alert(momentTime.format('YYYY-MM-DD HH:mm'));
    return momentTime.toDate();
  }
  getFacilityData(fId: any, type: string): any {
    const payload = {
      supply_chain_id: this.selectedSupplyChain.id,
      facility_id: fId,
    };
    this.spinner.show();
    this.simulationService.getFacilityDataById(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.facilityData = res.facility;
        if (this.facilityData.facility_schedules_attributes?.length > 0) {
          for (const schedules of this.facilityData.facility_schedules_attributes) {
            schedules.start_time = this.timeFormatToDate(schedules.start_time);
            schedules.end_time = this.timeFormatToDate(schedules.end_time);
          }
        }
        const key = 'week_number';
        const uniqueData = [
          ...new Map(this.facilityData.facility_schedules_attributes.map((item: any) => [item[key], item])).values(),
        ];
        // console.log('unique objects', uniqueData);
        this.selectedWeekList = [];
        for (const weekNo of uniqueData) {
          // @ts-ignore
          this.selectedWeekList.push(weekNo.week_number);
        }
        // *** set selected facility type
        setTimeout((): any => {
          const typeIndex = this.facilityTypes.findIndex((x: any) => x.id === this.facilityData.facility_type_id);
          this.selectedfacilityType = this.facilityTypes[typeIndex]; // set facility type
        }, 200);
        if (type === 'clone') {
          this.facilityName = 'Copy of ' + this.facilityData.name;
          for (const schedule of this.facilityData.facility_schedules_attributes) {
            delete schedule.id;
            for (const product of schedule.facility_schedule_products_attributes) {
              delete product.id; // remove id for new entry
            }
          }
          /*const iconObj = {
            url: this.selectedfacilityType.icon,
            scaledSize: {
              width: 30,
              height: 30
            }
          };
          this.markers.push({
            label: this.facilityName || '',
            lat: parseFloat(this.facilityData.latitude),
            lng: parseFloat(this.facilityData.longitude),
            draggable: true,
            animation: 'DROP',
            savetype: 'clone',
            icon: iconObj
          });*/
          this.markerAdded = false; // no need to click on map if this is true.
        } else {
          this.facilityName = this.facilityData.name;
          // START: make facility marker draggable true
          const markerIndex = this.markers.findIndex((x: any) => x.id === this.facilityData.id);
          this.markers[markerIndex].draggable = true;
          // END: make facility marker draggable true
          this.markerAdded = true; // no need to click on map if this is true.
          this.mapBound = new google.maps.LatLngBounds();
          this.mapBound.extend(new google.maps.LatLng(this.markers[markerIndex].lat, this.markers[markerIndex].lng));
          setTimeout((): any => {
            this.changeMapZoom(13);
          }, 200);
          // map.fitBounds(bonds);
        }
        this.latitude = this.facilityData.latitude;
        this.longitude = this.facilityData.longitude;
        // this.altitude = this.facilityData.altitude          // TODO: Need to update it
        this.totalArea = this.facilityData.total_area;
        this.storageCapacity = this.facilityData.storage_capacity;
        this.carbonOutput = this.facilityData.carbon_output;
        this.operatingCost = this.facilityData.operation_cost;
        this.rentCost = this.facilityData.rent_cost;
        this.energyCost = this.facilityData.energy_cost;
        this.laborCost = this.facilityData.labor_cost;
        // time_zone: 'Asia/Calcutta|Asia/Kolkata' = this.facilityData.timezone;      // TODO: Need to update it
        this.facilityScheduleData = this.facilityData.facility_schedules_attributes;
      }
    });
  }
  getFacilityType(): void {
    this.simulationService.getFacilityTypesService().subscribe((res: any) => {
      if (res) {
        this.facilityTypes = res.facility_types;
        this.selectedfacilityType = this.facilityTypes[0];
        this.changeFacilityType(this.selectedfacilityType);
      }
    });
  }
  changeFacilityType(obj: any): any {
    if (obj) {
      this.storageCapacity = obj.storage_capacity;
      this.operatingCost = obj.operation_cost;
      this.rentCost = obj.rent_cost;
      this.carbonOutput = obj.carbon_output;
      this.energyCost = obj.energy_cost;
      this.laborCost = obj.labor_cost;
      // product modals value below
      this.pDemand = obj.demand;
      this.pProduction = obj.production;
      this.pOnHandAmount = obj.on_hand_amount;
    }
  }
  addProductFn(): void {
    // console.log('Clicked...');
    if (this.addProductInFacilityForm.invalid) {
      this.Toast.fire({
        icon: 'warning',
        title: 'Please fill all mandatory fields.',
      });
      return;
    }
    // const parseDemand = parseFloat(String(this.pDemand));
    // const demandDevide = parseDemand / 60;
    // const demandFixed = parseFloat(demandDevide.toFixed(2));
    // const parsepProduction = parseFloat(String(this.pProduction));
    // const prodDevide = parsepProduction / 60;
    // const prodFixed = parseFloat(prodDevide.toFixed(2));
    if (this.facilityScheduleData && this.facilityScheduleData[0].facility_schedule_products_attributes?.length > 0) {
      if (
        this.facilityScheduleData[0].facility_schedule_products_attributes.some(
          (product: any) => product.product_id === this.selectedProdInFacility.id
        )
      ) {
        for (const schedule of this.facilityScheduleData) {
          for (const prod of schedule.facility_schedule_products_attributes) {
            if (prod.product_id === this.selectedProdInFacility.id && prod._destroy === true) {
              // prod.display_demand = this.pDemand;
              // prod.display_production = this.pProduction;
              (prod.demand = this.pDemand), // convert it to per minute demand and production
                (prod.production = this.pProduction),
                (prod.on_hand_amount = this.pOnHandAmount);
              prod.storage_used = this.pOnHandAmount * this.selectedProdInFacility.volume;
              prod.product_volume = this.selectedProdInFacility.volume;
              prod._destroy = false;
            } else if (prod.product_id === this.selectedProdInFacility.id && prod._destroy === false) {
              this.Toast.fire({
                icon: 'warning',
                title: 'Selected product already added.',
              });
              return;
            }
          }
        }
      } else {
        const obj = {
          product_id: this.selectedProdInFacility.id,
          product_name: this.selectedProdInFacility.name,
          // display_demand: this.pDemand,
          // display_production: this.pProduction,
          demand: this.pDemand, // convert it to per minute demand and production
          production: this.pProduction,
          on_hand_amount: this.pOnHandAmount,
          storage_used: this.pOnHandAmount * this.selectedProdInFacility.volume,
          product_volume: this.selectedProdInFacility.volume,
          _destroy: false,
        };
        for (const prod of this.facilityScheduleData) {
          const clonedObj = this.clonerService.deepClone(obj);
          prod.facility_schedule_products_attributes.push(clonedObj);
        }
      }
    } else {
      const obj = {
        product_id: this.selectedProdInFacility.id,
        product_name: this.selectedProdInFacility.name,
        // display_demand: this.pDemand,
        // display_production: this.pProduction,
        demand: this.pDemand, // convert it to per minute demand and production
        production: this.pProduction,
        on_hand_amount: this.pOnHandAmount,
        storage_used: this.pOnHandAmount * this.selectedProdInFacility.volume,
        product_volume: this.selectedProdInFacility.volume,
        _destroy: false,
      };
      for (const prod of this.facilityScheduleData) {
        const clonedObj = this.clonerService.deepClone(obj);
        prod.facility_schedule_products_attributes.push(clonedObj);
      }
    }
    this.calculateProductTotalValues();
    this.closeModalLib('prodInFacility', '');
    this.Toast.fire({
      icon: 'success',
      title: 'Product added in all days of all weeks.',
    });
  }
  openProdAddModal(): void {
    if (this.selectedfacilityType && this.selectedfacilityType.id) {
      if (this.storageCapacity > 0) {
        // START:: open modal and set z-index
        this.addProductInFacilityForm.reset({
          pName: {},
          pDemand: 0,
          pProduction: 0,
          pOnHandAmount: 0,
          pCapacityUsed: 0,
        });
        const callId = $('#prodInFacility > .ui-modal');
        callId.css('display', 'block');
        const zIndex = callId.css('z-index');
        callId.css('z-index', zIndex + 9);
        // END:: open modal and set z-index
        setTimeout((): any => {
          if (this.productList?.length > 0) {
            this.selectedProdInFacility = this.productList[0];
          }
        }, 200);
      } else {
        this.Toast.fire({
          icon: 'warning',
          title: 'Please add Max Storage Capacity.',
        });
      }
    } else {
      this.Toast.fire({
        icon: 'warning',
        title: 'Please first select facility type.',
      });
    }
  }
  calculateProductTotalValues(): any {
    for (const schedule of this.facilityScheduleData) {
      if (schedule.facility_schedule_products_attributes?.length > 0) {
        schedule.product_total_demand = 0;
        schedule.product_total_production = 0;
        schedule.product_total_quantity = 0;
        schedule.product_total_storage = 0;
        schedule.product_total_storage_per = 0;
        for (const prod of schedule.facility_schedule_products_attributes) {
          if (prod._destroy === false) {
            schedule.product_total_demand += parseFloat(prod.demand);
            schedule.product_total_production += parseFloat(prod.production);
            schedule.product_total_quantity += parseFloat(prod.on_hand_amount);
            schedule.product_total_storage += parseFloat(prod.storage_used);
          }
        }
        schedule.product_total_storage_per = Math.round((schedule.product_total_storage / this.storageCapacity) * 100);
      }
    }
  }
  storageCapacityValid(index: any): any {
    let pTotalStorage = 0;
    for (const product of this.facilityScheduleData[index].facility_schedule_products_attributes) {
      pTotalStorage += parseFloat(product.storage_used);
    }
    if (pTotalStorage > this.storageCapacity) {
      return false;
    } else {
      return true;
    }
  }
  calculateSelectedDayProductTotalValue(productObj: any, idx: any): any {
    if (this.storageCapacityValid(idx)) {
      this.facilityScheduleData[idx].product_total_demand = 0;
      this.facilityScheduleData[idx].product_total_production = 0;
      this.facilityScheduleData[idx].product_total_quantity = 0;
      this.facilityScheduleData[idx].product_total_storage = 0;
      this.facilityScheduleData[idx].product_total_storage_per = 0;
      for (const product of this.facilityScheduleData[idx].facility_schedule_products_attributes) {
        if (product._destroy === false) {
          this.facilityScheduleData[idx].product_total_demand += parseFloat(product.demand);
          this.facilityScheduleData[idx].product_total_production += parseFloat(product.production);
          this.facilityScheduleData[idx].product_total_quantity += parseFloat(product.on_hand_amount);
          this.facilityScheduleData[idx].product_total_storage += parseFloat(product.storage_used);
        }
      }
      this.facilityScheduleData[idx].product_total_storage_per = Math.round(
        (this.facilityScheduleData[idx].product_total_storage / this.storageCapacity) * 100
      );
    } else {
      productObj.on_hand_amount = this.oldOnHandAmount;
      productObj.storage_used = parseFloat(productObj.on_hand_amount) * productObj.product_volume;
      this.Toast.fire({
        icon: 'warning',
        title: 'Facility storage capacity is not sufficient for selected day.',
      });
    }
  }
  calculateCapacityInProduct(): any {
    if (this.selectedProdInFacility && this.pOnHandAmount) {
      const totalStorage = this.facilityScheduleData[0].product_total_storage;
      this.pCapacityUsed = Math.round(
        ((totalStorage + this.pOnHandAmount * this.selectedProdInFacility.volume) / this.storageCapacity) * 100
      );
      if (this.pCapacityUsed > 100) {
        this.Toast.fire({
          icon: 'warning',
          title: 'Facility storage capacity is not sufficient.',
        });
        this.pCapacityUsed = 0;
        this.pOnHandAmount = 0;
      }
    }
  }
  calculateStorage(product: any, dayIndex: any): any {
    // const oldOnHandAmount = product.on_hand_amount;
    product.storage_used = parseFloat(product.on_hand_amount) * product.product_volume;
    this.calculateSelectedDayProductTotalValue(product, dayIndex);
    // this.totalStorage = this.facilityScheduleData[0].facility_schedule_products_attributes[index];
    // this.pCapacityUsed = Math.round((this.totalStorage / this.storageCapacity) * 100);
  }
  getProductOldValues(prodObj: any): any {
    this.oldOnHandAmount = prodObj.on_hand_amount;
  }
  removeProdcutInFacility(prodObj: any): void {
    console.log(prodObj);
    if (prodObj.product_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This product will be deleted from all the scheduled days.',
        icon: 'warning',
        position: 'top-right',
        customClass: {
          container: 'swalConfirmCls',
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-outline-primary',
        },
        showCancelButton: true,
        confirmButtonColor: '#f96a74',
        cancelButtonColor: '#4f9d8d',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          for (const prod of this.facilityScheduleData) {
            const index = prod.facility_schedule_products_attributes.findIndex(
              (product: any) => product.product_id === prodObj.product_id
            );
            if (index > -1) {
              if (prod.facility_schedule_products_attributes[index].id) {
                prod.facility_schedule_products_attributes[index]._destroy = true; // make destroy true of selected product
              } else {
                prod.facility_schedule_products_attributes.splice(index, 1); // delete product from list
              }
            }
          }
          this.calculateProductTotalValues();
          console.log('facilityScheduleData', this.facilityScheduleData);
        }
      });
    }
  }
  closeDay(dt: any, e: any): void {
    if (e.target.checked === true) {
      if (dt.start_time) {
        const st = new Date(dt.start_time);
        st.setHours(0, 0);
        dt.start_time = st;
      }
      if (dt.end_time) {
        const et = new Date(dt.end_time);
        et.setHours(0, 0);
        dt.end_time = et;
      }
    }
  }
  getTime(dateTime: any): any {
    dateTime = new Date(dateTime);
    let hr = dateTime.getHours();
    if (hr < 10) {
      hr = '0' + hr;
    }
    let min = dateTime.getMinutes();
    if (min < 10) {
      min = '0' + min;
    }
    return hr + ':' + min;
  }

  addUpdateFacilityFn(): void {
    this.disableMapZoom = true;
    // console.log('clicked');
    if (this.markers?.length <= 0 || !this.markerAdded) {
      this.Toast.fire({
        icon: 'warning',
        title: 'Please click on map to placed facility.',
      });
      return;
    }
    if (this.facilityScheduleData?.length > 0) {
      if (this.facilityScheduleData[0].facility_schedule_products_attributes?.length <= 0) {
        this.Toast.fire({
          icon: 'warning',
          title: 'Please add at least 1 product to the facility.',
        });
        return;
      }
      if (this.facilityScheduleData[0].start_time && this.facilityScheduleData[0].end_time) {
        console.log('time available');
      } else {
        this.Toast.fire({
          icon: 'warning',
          title: 'Facility Schedule time is not selected.',
        });
        return;
      }
      for (const f of this.facilityScheduleData) {
        f.start_time = this.getTime(f.start_time);
        f.end_time = this.getTime(f.end_time);
        // demand and production amount convert into per minute
        /*if (f.facility_schedule_products_attributes?.length > 0) {
          for (const prod of f.facility_schedule_products_attributes) {
            prod.demand = parseFloat((prod.display_demand / 60).toFixed(2));
            prod.production = parseFloat((prod.display_production / 60).toFixed(2));
          }
        }*/
      }
    }
    // const updatingMarkerIndex = this.markers.findIndex((x: any) => x.id === this.facilityData.id);
    let lat: any;
    let lng: any;
    if (this.facilityUpdate) {
      const updatingMarkerIndex = this.markers.findIndex((x: any) => x.id === this.facilityData.id);
      lat = this.markers[updatingMarkerIndex].lat; // when update facility
      lng = this.markers[updatingMarkerIndex].lng;
    } else {
      lat = this.markers[this.markers.length - 1].lat;
      lng = this.markers[this.markers.length - 1].lng;
    }
    setTimeout((): any => {
      const payload = {
        facility_type_id: this.selectedfacilityType.id,
        name: this.facilityName,
        latitude: lat,
        longitude: lng,
        altitude: 0, // TODO : this is dummy data need to update from map
        total_area: this.totalArea,
        storage_capacity: this.storageCapacity,
        carbon_output: this.carbonOutput,
        operation_cost: this.operatingCost,
        rent_cost: this.rentCost,
        energy_cost: this.energyCost,
        labor_cost: this.laborCost,
        facility_schedules_attributes: this.facilityScheduleData,
        copy_from_id: this.facilityCopyId || '',
      };
      console.log(payload);
      const payloadIds = {
        supply_chain_id: this.selectedSupplyChain.id,
        update: this.facilityUpdate,
        id: this.facilityId || '',
      };
      this.spinner.show();
      this.simulationService.addUpdateFacilityService(payload, payloadIds).subscribe((res: any) => {
        this.spinner.hide();
        this.markerAdded = false;
        if (res) {
          if (this.facilityUpdate) {
            this.Toast.fire({
              icon: 'success',
              title: 'Updated Successfully.',
            });
          } else {
            this.Toast.fire({
              icon: 'success',
              title: 'Created Successfully.',
            });
          }
          this.facilityForm.reset();
          this.getFacilityList(this.selectedSupplyChain.id);
          // $('#closeFacilityForm').click();
          this.closeModalLib('showFacility', '');
        }
      });
    }, 1000);
  }
  deleteFacility(facility: any): void {
    if (facility) {
      this.spinner.show();
      const payload = {
        supply_chain_id: this.selectedSupplyChain.id,
        facility_id: facility.id,
      };
      this.simulationService.facilityBeforeDeleteData(payload).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.selectedFacility = facility;
          this.facilityBeforeDelData = res;
          this.selectedMode = 'facility';
          $('#deleteFacilityModal').modal('toggle');
        }
      });
    }
  }
  removeFacilityConfirm(facilityId: string): void {
    const payload = {
      id: this.selectedFacility.id,
      supplyId: this.selectedSupplyChain.id,
    };
    this.spinner.show();
    this.simulationService.deleteFacilityService(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.getFacilityList(this.selectedSupplyChain.id);
        this.Toast.fire({
          icon: 'success',
          title: 'Facility Removed successfully',
        });

        if (facilityId === this.selectedFacility.id) {
          this.closeModalLib('showFacility', '');
        }

        $('#deleteFacilityModal').modal('toggle');
      }
    });
  }
  isStartTimeChange(event: any, obj: any, type: string): any {
    // console.log('start---', event, obj);
    if (event === null) {
      if (type === 'route') {
        if (obj.minTime) {
          obj.start_time = obj.minTime;
          this.Toast.fire({
            icon: 'warning',
            title: 'Cannot be entered start time less than from ' + this.getTime(obj.minTime),
          });
        } else {
          this.Toast.fire({
            icon: 'warning',
            title: 'Please enter valid start time.',
          });
        }
      } else {
        this.Toast.fire({
          icon: 'warning',
          title: 'Please enter valid start time.',
        });
        const sTime = new Date();
        sTime.setHours(0, 1);
        obj.start_time = sTime;
      }
     
      return false;
    } else {
      if (type === 'facility') {
        const sTime = event;
        const eTime = new Date(obj.end_time);
        if (sTime.getTime() > eTime.getTime()) {
          // start date greater it should be lesser
          const newTime = new Date();
          const startTime = new Date(obj.start_time);
          let startTimeHour = startTime.getHours();
          let startTimeMinute = startTime.getMinutes();
          const endTimeHour = eTime.getHours();
          const endTimeMinute = eTime.getMinutes();
          if (startTimeHour > endTimeHour) {
            --startTimeHour;
          } else if (startTimeMinute > endTimeMinute) {
            if (endTimeMinute === 0) {
              // startTimeMinute = 59;
              --startTimeHour;
            } else {
              --startTimeMinute;
            }
          } else if (startTimeMinute === endTimeMinute) {
            --startTimeMinute;
          }
          newTime.setHours(startTimeHour, startTimeMinute);
          obj.start_time = newTime;
          /*this.Toast.fire({
            icon: 'warning',
            title: 'Please enter valid time range.'
          });*/
        }
      } else if (type === 'route') {
        if (this.routeScheduleData?.length > 0) {
          // for (const schedules of this.routeScheduleData.filter(
          //   (x: { week_number: any }) => x.week_number === obj.week_number
          // )) {
          //   schedules.start_time = event;
          //   // schedules.end_time = this.timeFormatToDate(schedules.end_time);
          // }
          if (this.routeScheduleData[0].route_stops_attributes.length > 0) {
            this.routeScheduleData.forEach((routes: any, i: number) => {
              if ((routes.closed || routes.automaticClosed) && (routes.day_of_week + (7 * (routes.week_number - 1))) > this.ariaExpanded) {
                routes.automaticClosed = false;
                routes.isTakeInCalculation = false;
                routes.closed = false;
                routes.end_time = this.timeFormatToDate('00:00');
              } else if ((routes.day_of_week + (7 * (routes.week_number - 1))) > this.ariaExpanded) {
                routes.end_time = this.timeFormatToDate('00:00');
              }
            });
          }
          
          for (const f of this.routeScheduleData) {
            if (!f.isTakeInCalculation && (f.day_of_week + (7 * (f.week_number - 1))) > this.ariaExpanded) {
              f.isTakeInCalculation = false;
              if (f.route_stops_attributes.length > 0) {
                let totalStopTime = 0;
                let totalRouteDuration = this.routeTime;
                f.route_stops_attributes.forEach((stops: any) => {
                  if (!stops._destroy && !stops.is_return) {
                    totalStopTime += Number(stops.stop_time);
                  }
                });
                totalRouteDuration = this.setMinutesToDuration(totalRouteDuration, totalStopTime);
                const time = this.getTime(f.start_time);
                const endTimeObj = this.calculateEndTimeFromRouteDuration(f.day_of_week, time, totalRouteDuration, f.week_number);
                f.start_time = this.timeFormatToDate(f.start_time);
                f.end_time = endTimeObj.endTime;
                f.end_day_of_week = endTimeObj.endDayOfWeek;
                f.end_week_number = endTimeObj.endWeek;
              } else {
                  this.routeScheduleData.forEach((routes: any) => {
                    const sTime = new Date();
                    sTime.setHours(0, 1);
                    routes.start_time = sTime;
                    routes.closed = false;
                    routes.automaticClosed = false;
                    routes.isTakeInCalculation = false;
                    routes.end_time = this.timeFormatToDate('00:00');
                  });
              }
      
              // delete f.end_time;
              const calKey = 'isTakeInCalculation';
              const autoClosedKey = 'automaticClosed';
              if (this.routeScheduleData[0].route_stops_attributes.length > 0) {
                const findRouteIndex = this.routeScheduleData.findIndex((routeDay: any) => routeDay?.day_of_week === f.end_day_of_week && routeDay.week_number === f.end_week_number);
                if (findRouteIndex !== -1) {
                  if (f.day_of_week === f.end_day_of_week && f.week_number === f.end_week_number) {
                    this.routeScheduleData[findRouteIndex].minTime = findRouteIndex === 0 ? '' : this.timeFormatToDate(f.start_time);
                    this.routeScheduleData[findRouteIndex].end_time = this.timeFormatToDate(f.end_time);
                  } else {
                    this.routeScheduleData[findRouteIndex].start_time = this.timeFormatToDate(f.end_time);   
                    this.routeScheduleData[findRouteIndex].minTime = findRouteIndex === 0 ? '' : this.timeFormatToDate(f.end_time);
                    f.end_time = this.timeFormatToDate('00:00');
                  }

                  const dayOfWeek = f.day_of_week + (7 * (f.week_number - 1));
                  const endDayOfWeek = f.end_day_of_week + (7 * (f.end_week_number - 1));
                  if (endDayOfWeek > (dayOfWeek + 1)) {
                    for (let i = dayOfWeek; i < endDayOfWeek - 1; i++) {
                      this.routeScheduleData[i][calKey] = true;
                      this.routeScheduleData[i][autoClosedKey] = true;
                      this.routeScheduleData[i].closed = true;
                      this.closeDay(this.routeScheduleData[i], {target: {checked: true}});
                    }
                  }
                } else {
                  const dayStart = (f.day_of_week + (7 * (f.week_number - 1)));
                  const dayEnd = (7 * f.end_week_number);
                  const dayLength = dayEnd - dayStart;
                  f.end_time = this.timeFormatToDate('00:00');
                  if (dayLength > 0) {
                    for (let i = dayStart; i < this.routeScheduleData.length; i++) {
                      this.routeScheduleData[i][calKey] = true;
                      this.routeScheduleData[i][autoClosedKey] = true;
                      this.routeScheduleData[i].closed = true;
                      this.closeDay(this.routeScheduleData[i], {target: {checked: true}});
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  isEndTimeChange(event: any, obj: any): any {
    // console.log('End---', event, obj);
    if (event === null) {
      this.Toast.fire({
        icon: 'warning',
        title: 'Please enter valid end time.',
      });
      const eTime = new Date();
      eTime.setHours(23, 59);
      obj.end_time = eTime;
      return false;
    } else {
      const eTime = event;
      const sTime = new Date(obj.start_time);
      if (sTime.getTime() > eTime.getTime()) {
        // start date greater
        const newTime = new Date();
        const endTime = new Date(obj.end_time);
        let endTimeHour = endTime.getHours();
        let endTimeMinute = endTime.getMinutes();
        const startTimeHour = sTime.getHours();
        const startTimeMinute = sTime.getMinutes();
        if (startTimeHour > endTimeHour) {
          ++endTimeHour;
        } else if (startTimeMinute > endTimeMinute) {
          if (endTimeMinute === 0) {
            // startTimeMinute = 59;
            ++endTimeHour;
          } else {
            ++endTimeMinute;
          }
        } else if (startTimeMinute === endTimeMinute) {
          ++endTimeMinute;
        }
        newTime.setHours(endTimeHour, endTimeMinute);
        obj.end_time = newTime;
        /*this.Toast.fire({
          icon: 'warning',
          title: 'Please enter valid time range.'
        });*/
      }
    }
  }
  // NOTE::  Vehicle module code goes here...
  getVehicleType(): void {
    this.simulationService.getVehicleTypesService().subscribe((res: any) => {
      if (res) {
        this.vehicleTypeList = res.vehicle_types;
        /*if (this.vehicleTypeList?.length > 0) {
          this.selectedVehicleType = this.vehicleTypeList[0];
        }*/
      }
    });
  }
  getFacilityVehicles(sId: any, facility: any): void {
    const payload = {
      supply_chain_id: sId,
      facility_id: facility.id,
    };
    this.simulationService.getFacilityVehicleListById(payload).subscribe((res: any) => {
      if (res) {
        this.facilityVehicleList = res.vehicles.map((vehicle: any) => {
          return {
            ...vehicle,
            icon: removeQueryString(vehicle.icon),
          };
        });
        this.selectedFacility = facility;
        // this.markers = this.markers.filter((item: any) => item.iconType !== 'vehicle'); // remove all vehicles marker of selected facility
        this.vehicleMarkers = [];
        if (this.facilityVehicleList?.length > 0) {
          this.selectedFacility.vehicleList = this.facilityVehicleList;
          for (let vehicle = 0; vehicle < this.selectedFacility.vehicleList.length; vehicle++) {
            this.getVehicleRouteList(this.selectedSupplyChain.id, this.selectedFacility, this.selectedFacility.vehicleList[vehicle]);
            const iconObj = {
              url: this.selectedFacility.vehicleList[vehicle].icon,
              scaledSize: {
                width: 30,
                height: 30,
              },
              size: {
                width: 30,
                height: 30,
              },
              anchor: {
                x: 10,
                y: 10,
              },
              origin: {
                x: 0,
                y: 0,
              },
            };
            // const latlng calculation = ((lat - ((this.selectedFacility.vehicleList.length - i) * .00005)), lng + .00003);   // code by old scmglobe project
            const latitude = parseFloat(this.selectedFacility.latitude);
            const newLat = latitude - (this.selectedFacility.vehicleList.length - vehicle) * 0.00005;
            const longitude = parseFloat(this.selectedFacility.longitude);
            const newLng = longitude + 0.00003;
            this.vehicleMarkers.push({
              label: this.selectedFacility.vehicleList[vehicle].name || '',
              lat: newLat,
              lng: newLng,
              draggable: false,
              animation: 'DROP',
              icon: iconObj,
              iconType: 'vehicle',
            });
          }
          this.mapBound = false;
        } else {
          this.selectedFacility.vehicleList = [];
          this.mapBound = false;
        }
      }
    });
  }
  getFacilityVehicleData(fId: any, vId: any, type: string): any {
    const payload = {
      supply_chain_id: this.selectedSupplyChain.id,
      facility_id: fId,
      vehicle_id: vId,
    };
    this.simulationService.getFacilityVehicleDataById(payload).subscribe((res: any) => {
      if (res) {
        this.facilityVehicleData = res.vehicle;
        // *** set selected facility type
        const typeIndex = this.vehicleTypeList.findIndex((x: any) => x.id === this.facilityVehicleData.vehicle_type_id);
        this.selectedVehicleType = this.vehicleTypeList[typeIndex];
        if (type === 'clone') {
          this.vehicleName = 'Copy of ' + this.facilityVehicleData.name;
          const iconObj = {
            url: this.selectedVehicleType.icon,
            scaledSize: {
              width: 30,
              height: 30,
            },
          };
          const latitude = parseFloat(this.selectedFacility.latitude);
          const longitude = parseFloat(this.selectedFacility.longitude);
          this.vehicleMarkers.push({
            label: this.vehicleName || '',
            lat: latitude,
            lng: longitude,
            draggable: false,
            animation: 'DROP',
            savetype: 'clone',
            icon: iconObj,
          });
        } else {
          this.vehicleName = this.facilityVehicleData.name;
        }
        this.vehicleSpeed = this.facilityVehicleData.speed;
        this.vehicleCarbonOutput = this.facilityVehicleData.carbon_output;
        this.vehicleCost = this.facilityVehicleData.cost_per_distance;
        this.vehicleVolume = this.facilityVehicleData.max_volume;
        this.vehicleWeight = this.facilityVehicleData.max_weight;
      }
    });
  }
  addUpdateVehicleMode(mode: string, facilityObj: any, vehicleObj: any): void {
    this.vehicleForm.reset({
      vName: '',
      vSpeed: 0,
      vCarbonOut: 0,
      vCost: 0,
      vVolume: 0,
      vWeight: 0,
    });
    this.selectedFacility = facilityObj;
    // START:: open modal and set z-index
    // const largestZindex = this.getLargestZindex();
    $('app-modal > .ui-modal').css('z-index', 9);
    // const callId = $('#showVehicle > .ui-modal');
    $('#showVehicle > .ui-modal').css({ display: 'block', 'z-index': 10 });
    // callId.css('z-index', 10);
    // END:: open modal and set z-index
    if (mode === 'update') {
      this.getFacilityVehicleData(facilityObj.id, vehicleObj.id, '');
      this.vehicleUpdate = true;
      this.vehicleId = vehicleObj.id;
      this.getVehicleRouteList(this.selectedSupplyChain.id, facilityObj, vehicleObj);
      this.closeModalLib('showRoutes', '');
    } else if (mode === 'clone') {
      this.vehicleUpdate = false;
      this.getFacilityVehicleData(facilityObj.id, vehicleObj.id, 'clone');
    } else {
      this.vehicleUpdate = false;
      setTimeout((): any => {
        if (this.vehicleTypeList?.length > 0) {
          this.selectedVehicleType = this.vehicleTypeList[0];
          this.changeVehicleType(this.selectedVehicleType);
        }
      }, 100);
    }
  }
  changeVehicleType(selVeh: any): void {
    this.vehicleForm.reset({
      vType: this.selectedVehicleType,
      vName: this.vehicleName,
      vSpeed: selVeh.speed,
      vCarbonOut: selVeh.carbon_output,
      vCost: selVeh.cost_per_distance,
      vVolume: selVeh.max_volume,
      vWeight: selVeh.max_weight
    });
    this.cdr.detectChanges();
  }
  addUpdateVehicle(): void {
    const payload = {
      name: this.vehicleName,
      vehicle_type_id: this.selectedVehicleType.id,
      speed: this.vehicleSpeed,
      carbon_output: this.vehicleCarbonOutput,
      cost_per_distance: this.vehicleCost,
      max_volume: this.vehicleVolume,
      max_weight: this.vehicleWeight,
      route_draw_type: this.selectedVehicleType.route_draw_type !== undefined ? this.selectedVehicleType.route_draw_type : true,
      supply_chain_id: this.selectedSupplyChain.id,
    };
    const payloadIds = {
      supply_chain_id: this.selectedSupplyChain.id,
      facility_id: this.selectedFacility.id,
      update: this.vehicleUpdate,
      id: this.vehicleId || '',
    };
    this.spinner.show();
    this.simulationService.addUpdateVehicleService(payload, payloadIds).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.Toast.fire({
          icon: 'success',
          title: 'Saved successfully',
        });
        this.productForm.reset();
        this.getFacilityVehicles(this.selectedSupplyChain.id, this.selectedFacility);
        this.closeModalLib('showVehicle', '');
      }
    });
  }
  deleteVehicle(facilityObj: any, vehicleObj: any): void {
    if (facilityObj && vehicleObj) {
      this.spinner.show();
      const payload = {
        supply_chain_id: this.selectedSupplyChain.id,
        facility_id: facilityObj.id,
        vehicle_id: vehicleObj.id,
      };
      this.simulationService.vehicleBeforeDeleteData(payload).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.selectedFacility = facilityObj;
          this.selectedVehicle = vehicleObj;
          this.facilityBeforeDelData = res;
          this.selectedMode = 'vehicle';
          $('#deleteFacilityModal').modal('toggle');
        }
      });
    }
  }
  removeVehicleConfirm(): void {
    const payload = {
      vehicle_id: this.selectedVehicle.id,
      facility_id: this.selectedFacility.id,
      supplyId: this.selectedSupplyChain.id,
    };
    this.spinner.show();
    this.simulationService.deleteFacilityVehicleService(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.getFacilityVehicles(this.selectedSupplyChain.id, this.selectedFacility);
        this.Toast.fire({
          icon: 'success',
          title: 'Vehicle Deleted successfully',
        });
        $('#deleteFacilityModal').modal('toggle');
        this.closeModalLib('showVehicle', '');
        this.polylines = [];
      }
    });
  }
  // NOTE:: Route code goes here...
  getVehicleRouteList(sId: any, facility: any, vehicleObj: any): void {
    const payload = {
      supply_chain_id: sId,
      facility_id: facility.id,
      vehicle_id: vehicleObj.id,
    };
    this.simulationService.getVehicleRoutesList(payload).subscribe((res: any) => {
      if (res) {
        this.faciVehiRoute = res.routes;
        this.selectedFacility = facility;
        vehicleObj.vehicleRoutes = this.faciVehiRoute;
        this.updateVehicleRoutesOnMap(facility, vehicleObj);
      }
    });
  }
  getVehicleRouteData(fId: any, vId: any, rId: any, type: string): any {
    this.spinner.show();
    const payload = {
      supply_chain_id: this.selectedSupplyChain.id,
      facility_id: fId,
      vehicle_id: vId,
      route_id: rId,
    };
    this.simulationService.getRouteDataById(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.routeData = res;
        if (this.routeData.route_schedules_attributes?.length > 0) {
          for (const schedules of this.routeData.route_schedules_attributes) {
            schedules.start_time = this.formatRouteDateTime(schedules.start_time);
            // schedules.end_time = this.timeFormatToDate(schedules.end_time);
          }
        }
        const key = 'week_number';
        const uniqueData = [
          ...new Map(this.routeData.route_schedules_attributes.map((item: any) => [item[key], item])).values(),
        ];
        // console.log('unique objects', uniqueData);
        this.selectedRouteWeekList = [];
        for (const weekNo of uniqueData) {
          // @ts-ignore
          this.selectedRouteWeekList.push(weekNo.week_number);
        }
        if (type === 'clone') {
          this.routeName = 'Copy of ' + this.routeData.name;
          for (const schedule of this.routeData.route_schedules_attributes) {
            delete schedule.id;
            for (const stop of schedule.route_stops_attributes) {
              delete stop.id;
              for (const product of stop.route_stop_products_attributes) {
                delete product.id; // remove id for new entry
              }
            }
          }
          // @ts-ignore
          // this.routeData.route_schedules_attributes = this.routeData.route_schedules_attributes.map(({id, ...rest}) => ({...rest}));    // remove few parameter by using 'rest' parameter
        } else {
          this.routeName = this.routeData.name;
        }
        for (const schedule of this.routeData.route_schedules_attributes) {
          for (const stop of schedule.route_stops_attributes) {
            stop._destroy = false;
            const endFacility = this.supplyFacility.find((facility: any) => facility.id === stop.end_facility_id);
            if (endFacility) {
              stop.end_facility_data = {
                name: endFacility.name,
                latitude: endFacility.latitude,
                longitude: endFacility.longitude,
              };
            }
            const startFacility = this.supplyFacility.find((facility: any) => facility.id === stop.start_facility_id);
            if (startFacility) {
              stop.start_facility_data = {
                name: startFacility.name,
                latitude: startFacility.latitude,
                longitude: startFacility.longitude,
              };
            }
            for (const sProduct of stop.route_stop_products_attributes) {
              const findProduct = this.productList.find((product: any) => product.id === sProduct.product_id);
              if (findProduct) {
                sProduct.product_name = findProduct.name;
                sProduct.is_return = false;
                sProduct._destroy = false;
              }
            }
          }
        }
        this.routeScheduleData = this.routeData.route_schedules_attributes;
        this.routeScheduleData = this.routeScheduleData.map((routes: any, i: number) => {
          if (routes.day_of_week === routes.end_day_of_week && routes.week_number === routes.end_week_number) {
            routes.end_time = this.formatRouteDateTime(routes.end_time);
          } else {
            routes.end_time = this.timeFormatToDate('00:00');
          }
          routes.minTime = i === 0 ? '' : moment(routes.start_time).toDate();
          return routes;
        });
        console.log('this.routeScheduleData', this.routeScheduleData);
        const routeVehicle = this.facilityVehicleList.find((vehicle: any) => vehicle.id === this.routeData.vehicle_id);
        if (
          routeVehicle && !routeVehicle.route_draw_type
        ) {
          this.routeVisible = false;
          this.polylines = [];
          const stopCounts = this.routeData.route_schedules_attributes[0].route_stops_attributes.length;
          if (this.routeData.geopath) {
            const parseGeopath = JSON.parse(this.routeData.geopath);
            const geoPath = google.maps.geometry.encoding.decodePath(parseGeopath);
            const pathArry: any = geoPath.map(path => {
              return path.toJSON();
            });
            console.log('pathArry get data by id--', pathArry);
            this.polylineMidMarkers = [];
            this.polyMarkerId = 1;
            if (pathArry?.length > 0) {
              let devideCount = 1;
              pathArry[0].id = 0;
              // pathArry[pathArry.length - 1].id = pathArry.length;
              for (let path = 1; path < pathArry.length - 1; path++) {
                pathArry[path].id = this.polyMarkerId;
                const equallyDividePath = pathArry.length / stopCounts;
                if (path === equallyDividePath * devideCount - 1 || path === equallyDividePath * devideCount) {
                  // console.log('path')
                  if (path === equallyDividePath * devideCount) {
                    devideCount++;
                  }
                } else {
                  // pathArry[path].id = this.polyMarkerId;
                  const iconObj = {
                    url: 'assets/images/white_circle.png',
                  };
                  this.polylineMidMarkers.push({
                    label: '',
                    lat: pathArry[path].lat,
                    lng: pathArry[path].lng,
                    draggable: true,
                    animation: 'DROP',
                    savetype: '',
                    icon: iconObj,
                    id: pathArry[path].id,
                  });
                  // ++this.polyMarkerId;
                }
                ++this.polyMarkerId;
              }
            }
            // console.log('pathArry', pathArry);
            this.allGeoPaths = pathArry;
            const polylineObj = {
              color: '#0809c5',
              visible: true,
              path: pathArry,
            };
            this.polylines.push(polylineObj);
          }
          console.log('polyline get data by id--', this.polylines);
          this.routeTime = this.routeData.total_duration;
          this.routeDistance = this.routeData.total_distance;
          // will discuss later
          // const time = this.routeData.total_distance / this.routeData.vehicle_speed;
          //  this.routeTime = parseFloat(time.toFixed(2));
          // const modules = time % 1;
          // const h = Math.floor(time);
          // const m = Math.floor(modules * 60);
          // this.routeTime = h + ':' + m;
        } else {
          this.routeVisible = true;
          const parseWaypoints = JSON.parse(this.routeData.waypoints);
          const decodedWayPoints = google.maps.geometry.encoding.decodePath(parseWaypoints);
          const wayPoints = decodedWayPoints.map(way => {
            return way.toJSON();
          });
          this.waypoints = wayPoints.map(way => {
            return { location: way };
          });
          // this.waypoints = lzwCompress.unpack(parseWaypoints);
          // this.waypoints = JSON.parse(this.routeData.waypoints);
          this.startPoint = {
            lat: parseFloat(this.selectedFacility.latitude),
            lng: parseFloat(this.selectedFacility.longitude),
          };
          this.endPoint = {
            lat: parseFloat(this.selectedFacility.latitude),
            lng: parseFloat(this.selectedFacility.longitude),
          };
          // this.drawRoute(this.selectedFacility, this.selectedVehicle, this.routeScheduleData);
        }
      }
    });
  }
  formatRouteDateTime(dateTime: any): any {
    // Parse the given datetime string
    const timePart = dateTime.split('T')[1];
    // Split the time part by ':' to get the hours and minutes
    const [hour, minute] = timePart.split(':');
    // Get today's date
    const today = moment();
    // Set today's date with the extracted time
    const formatedDateTime = today.hours(hour).minutes(minute).seconds(0).milliseconds(0);
    return formatedDateTime.format();
  }
  addUpdateRouteMode(mode: string, facilityObj: any, vehicleObj: any, routeObj: any): void {
    this.routeForm.reset();
    this.selectedFacility = facilityObj;
    this.selectedVehicle = vehicleObj;
    this.facilityListForRouteStop = this.clonerService.deepClone(this.supplyFacility);
    this.facilityListForRouteStop = this.facilityListForRouteStop.filter(
      (facility: any) => facility.id !== facilityObj.id
    );
    this.selectedRouteWeekList = [1];
    this.selectedRouteWeek = 1;
    this.selectedRouteFacility = this.facilityListForRouteStop[0];
    this.routeScheduleData = this.clonerService.deepClone(this.dummyRouteScheduleData);
    this.polylines = [];
    this.polylineMidMarkers = [];
    this.polyMarkerId = 1;
    // START:: open modal and set z-index
    $('app-modal > .ui-modal').css('z-index', 9);
    // const callId = $('#showRoutes > .ui-modal');
    $('#showRoutes > .ui-modal').css({ display: 'block', 'z-index': 10 });
    // const zIndex = callId.css('z-index');
    // $('#showRoutes > .ui-modal').css('z-index',  10);
    // END:: open modal and set z-index
    this.isCalculateRouteTime = true;
    if (mode === 'update') {
      this.getVehicleRouteData(facilityObj.id, vehicleObj.id, routeObj.id, '');
      this.routeUpdate = true;
      this.isCalculateRouteTime = false;
      this.routeId = routeObj.id;
      this.copiedRouteId = '';
    } else if (mode === 'clone') {
      this.routeUpdate = false;
      this.copiedRouteId = routeObj.id;
      this.getVehicleRouteData(facilityObj.id, vehicleObj.id, routeObj.id, 'clone');
    } else {
      this.routeDistance = 0;
      this.routeTime = 0;
      this.copiedRouteId = '';
      this.routeUpdate = false;
    }
    this.ariaExpanded = 0;
  }
  selRouteWeekListUpdate(): void {
    if (!this.selectedRouteWeekList.includes(this.selectedRouteWeek)) {
      for (const dummyObj of this.dummyRouteScheduleData) {
        // for of loop
        const clonedObj = this.clonerService.deepClone(dummyObj);
        // @ts-ignore
        clonedObj.week_number = this.selectedRouteWeek;
        // ** NOTE:: if already added stop in old weeks then copy all stops in newly added week
        if (this.routeScheduleData?.length > 0 && this.routeScheduleData[0].route_stops_attributes?.length > 0) {
          for (const stopObj of this.routeScheduleData[0].route_stops_attributes) {
            const clonedStopObj = this.clonerService.deepClone(stopObj);
            // @ts-ignore
            clonedObj.route_stops_attributes.push(clonedStopObj);
          }
        }
        // ***************
        this.routeScheduleData.push(clonedObj);
      }
      this.selectedRouteWeekList.push(this.selectedRouteWeek);
      this.selectedRouteWeekList.sort((a: any, b: any) => parseFloat(a) - parseFloat(b));
      // console.log('selectedWeekList', this.selectedWeekList);
      // console.log('facilityScheduleData after new week added:- ', this.facilityScheduleData);
      this.calculateTotalPickDropQuantity();
    }
    // const key = 'week_number';
    // this.uniqScheduleWeek = [...new Map(this.facilityScheduleData.map((item: any) => [item[key], item])).values()]; // get unique object from array of object
    this.ariaExpanded = 0;
    const selectedDayForRoute = this.routeScheduleData[0];
    this.isStartTimeChange(selectedDayForRoute.start_time, selectedDayForRoute, 'route');
  }
  addNewStop(): any {
    const obj = {
      start_facility_id: this.selectedFacility.id,
      end_facility_id: this.selectedRouteFacility.id,
      distance: 0,
      stop_time: 10,
      sequence: 1,
      geo_path: '',
      manual_path: '',
      route_stop_products_attributes: [],
      start_facility_data: {
        latitude: this.selectedFacility.latitude,
        longitude: this.selectedFacility.longitude,
      },
      end_facility_data: {
        name: this.selectedRouteFacility.name,
        latitude: this.selectedRouteFacility.latitude,
        longitude: this.selectedRouteFacility.longitude,
      },
      _destroy: false,
      is_return: false,
    };
    const lastStopObj = {
      start_facility_id: this.selectedRouteFacility.id,
      end_facility_id: this.selectedFacility.id,
      distance: 0,
      stop_time: 10,
      sequence: 1,
      geo_path: '',
      manual_path: '',
      route_stop_products_attributes: [],
      start_facility_data: {
        latitude: this.selectedRouteFacility.latitude,
        longitude: this.selectedRouteFacility.longitude,
      },
      end_facility_data: {
        name: this.selectedFacility.name,
        latitude: this.selectedFacility.latitude,
        longitude: this.selectedFacility.longitude,
      },
      _destroy: false,
      is_return: true,
    };
    for (const stop of this.routeScheduleData) {
      const activeStop = stop.route_stops_attributes.filter((stopObj: any) => stopObj._destroy === false);
      const count = activeStop.length;
      if (count < 1) {
        const clonedObj2 = this.clonerService.deepClone(lastStopObj);
        stop.route_stops_attributes.push(clonedObj2);
      } else {
        obj.start_facility_id = stop.route_stops_attributes[stop.route_stops_attributes.length - 1].start_facility_id;
        obj.start_facility_data.latitude =
          stop.route_stops_attributes[stop.route_stops_attributes.length - 1].start_facility_data.latitude;
        obj.start_facility_data.longitude =
          stop.route_stops_attributes[stop.route_stops_attributes.length - 1].start_facility_data.longitude;
        //  Change Last stop data
        stop.route_stops_attributes[stop.route_stops_attributes.length - 1].start_facility_id =
          this.selectedRouteFacility.id;
        stop.route_stops_attributes[stop.route_stops_attributes.length - 1].start_facility_data.latitude =
          this.selectedRouteFacility.latitude;
        stop.route_stops_attributes[stop.route_stops_attributes.length - 1].start_facility_data.longitude =
          this.selectedRouteFacility.longitude;
      }
      const clonedObj = this.clonerService.deepClone(obj);
      const length = stop.route_stops_attributes.length - 1;
      stop.route_stops_attributes.splice(length, 0, clonedObj);
    }
  }
  addStopsToRoute(): void {
    console.log('this.selectedRouteFacility', this.selectedRouteFacility);
    if (this.selectedRouteFacility.id === this.selectedFacility.id) {
      this.Toast.fire({
        icon: 'warning',
        title: 'Please select stop other than vehicle starting point.',
      });
      return;
    }
    if (this.routeScheduleData && this.routeScheduleData[0].route_stops_attributes?.length > 0) {
      if (
        this.routeScheduleData[0].route_stops_attributes.some(
          (stop: any) => stop.end_facility_id === this.selectedRouteFacility.id
        )
      ) {
        for (const schedule of this.routeScheduleData) {
          for (const stop of schedule.route_stops_attributes) {
            if (stop.end_facility_id === this.selectedRouteFacility.id && stop._destroy === true) {
              stop._destroy = false;
              schedule.route_stops_attributes[schedule.route_stops_attributes.length - 1]._destroy = false;
              // this.addNewStop();
            } else if (stop.end_facility_id === this.selectedRouteFacility.id && stop._destroy === false) {
              this.Toast.fire({
                icon: 'warning',
                title: 'Selected stop already added.',
              });
              return;
            }
          }
        }
      } else {
        this.addNewStop();
        /*const obj = {
          start_facility_id: this.selectedFacility.id,
          end_facility_id: this.selectedRouteFacility.id,
          distance: 0,
          stop_time: 10,
          sequence: 1,
          geo_path: '',
          manual_path: '',
          route_stop_products_attributes: [],
          start_facility_data: {
            latitude: this.selectedFacility.latitude,
            longitude: this.selectedFacility.longitude
          },
          end_facility_data: {
            name: this.selectedRouteFacility.name,
            latitude: this.selectedRouteFacility.latitude,
            longitude: this.selectedRouteFacility.longitude
          },
          _destroy: false,
          is_return: false
        };
        const lastStopObj = {
          start_facility_id: this.selectedFacility.id,
          end_facility_id: this.selectedFacility.id,
          distance: 0,
          stop_time: 10,
          sequence: 1,
          geo_path: '',
          manual_path: '',
          route_stop_products_attributes: [],
          start_facility_data: {
            latitude: this.selectedFacility.latitude,
            longitude: this.selectedFacility.longitude
          },
          end_facility_data: {
            name: this.selectedFacility.name,
            latitude: this.selectedFacility.latitude,
            longitude: this.selectedFacility.longitude
          },
          _destroy: false,
          is_return: true
        };
        for (const stop of this.routeScheduleData) {
          if (stop.route_stops_attributes.length < 1) {
            const clonedObj2 = this.clonerService.deepClone(lastStopObj);
            stop.route_stops_attributes.push(clonedObj2);
          }
          const clonedObj = this.clonerService.deepClone(obj);
          const length = stop.route_stops_attributes.length - 1;
          stop.route_stops_attributes.splice(length, 0, clonedObj);
          // this.routeScheduleData[stop].route_stops_attributes.push(clonedObj);
        }*/
      }
    } else {
      this.addNewStop();
      /*const obj = {
        start_facility_id: this.selectedFacility.id,
        end_facility_id: this.selectedRouteFacility.id,
        distance: 0,
        stop_time: 10,        // stop time is in minutes
        sequence: 1,        // TODO::: this is a number of series
        geo_path: '',
        manual_path: '',     // TODO::: no need
        route_stop_products_attributes: [],
        start_facility_data: {
          latitude: this.selectedFacility.latitude,
          longitude: this.selectedFacility.longitude
        },
        end_facility_data: {
          name: this.selectedRouteFacility.name,
          latitude: this.selectedRouteFacility.latitude,
          longitude: this.selectedRouteFacility.longitude
        },
        _destroy: false,
        is_return: false
      };
      const lastStopObj = {
        start_facility_id: this.selectedFacility.id,
        end_facility_id: this.selectedFacility.id,
        distance: 0,
        stop_time: 10,
        sequence: 1,
        geo_path: '',
        manual_path: '',
        route_stop_products_attributes: [],
        start_facility_data: {
          latitude: this.selectedFacility.latitude,
          longitude: this.selectedFacility.longitude
        },
        end_facility_data: {
          name: this.selectedFacility.name,
          latitude: this.selectedFacility.latitude,
          longitude: this.selectedFacility.longitude
        },
        _destroy: false,
        is_return: true
      };
      for (const stop of this.routeScheduleData) {
        if (stop.route_stops_attributes.length < 1) {
          const clonedObj2 = this.clonerService.deepClone(lastStopObj);
          stop.route_stops_attributes.push(clonedObj2);
        }
        const clonedObj = this.clonerService.deepClone(obj);
        const length = stop.route_stops_attributes.length - 1;
        stop.route_stops_attributes.splice(length, 0, clonedObj);
        // this.routeScheduleData[stop].route_stops_attributes.push(clonedObj);
      }*/
    }
    this.drawRoute(this.selectedFacility, this.selectedVehicle, this.routeScheduleData); // draw route after add new stop
    /*this.startPoint = { lat: parseFloat(this.selectedFacility.latitude), lng: parseFloat(this.selectedFacility.longitude) };
    this.endPoint = { lat: parseFloat(this.selectedFacility.latitude), lng: parseFloat(this.selectedFacility.longitude) };
    const wayPointObj = {
      location: {
        lat: parseFloat(this.selectedRouteFacility.latitude),
        lng: parseFloat(this.selectedRouteFacility.longitude)
      }
    };
    this.waypoints.push(wayPointObj);*/
    /*if (this.routeScheduleData[0].route_stops_attributes.some( (stop: any) => stop.end_facility_id === this.selectedRouteFacility.id)) {
      this.Toast.fire({
        icon: 'warning',
        title: 'Selected stop already added.'
      });
      return;
    }
    const obj = {
      end_facility_name: this.selectedRouteFacility.name,
      start_facility_id: this.selectedFacility.id,
      end_facility_id: this.selectedRouteFacility.id,
      distance: 200.0,
      stop_time: '11:00',
      sequence: 500,
      geo_path: 'test1',
      manual_path: 'test2',
      route_stop_products_attributes: []
    };
    for (const prod of this.routeScheduleData) {
      const clonedObj = this.clonerService.deepClone(obj);
      prod.route_stops_attributes.push(clonedObj);
    }*/
    this.Toast.fire({
      icon: 'success',
      title: 'Stop added in all days of all weeks.',
    });
    this.calculateTotalPickDropQuantity();
    this.isCalculateRouteTime = true;
    console.log('New route added:::', this.routeScheduleData);
  }
  removeStopInRoute(stopObj: any): void {
    // console.log(stopObj);
    if (stopObj.end_facility_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This stop will be deleted from all the scheduled days.',
        icon: 'warning',
        position: 'top-right',
        customClass: {
          container: 'swalConfirmCls',
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-outline-primary',
        },
        showCancelButton: true,
        confirmButtonColor: '#f96a74',
        cancelButtonColor: '#4f9d8d',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          for (const stop of this.routeScheduleData) {
            const index = stop.route_stops_attributes.findIndex(
              (stopParam: any) => stopParam.end_facility_id === stopObj.end_facility_id
            );
            if (index > -1) {
              // if (stop.route_stops_attributes[index].id) {
              //   stop.route_stops_attributes[index]._destroy = true; // make destroy true of selected stop
              // } else {
              stop.route_stops_attributes.splice(index, 1); // delete stop from list
              // }
            }
            // START:: when only last/return stop left so remove it or make destroy false
            const count = stop.route_stops_attributes.filter((obj: any) => obj._destroy === false).length;
            if (count === 1) {
              // stop.route_stops_attributes = [];
              const indexNew = stop.route_stops_attributes.findIndex((param: any) => param._destroy === false);
              if (index > -1) {
                // if (stop.route_stops_attributes[indexNew].id) {
                //   stop.route_stops_attributes[indexNew]._destroy = true; // make destroy true of selected stop
                // } else {
                stop.route_stops_attributes.splice(indexNew, 1); // delete stop from list
                // }
              }
            }
            // END:: when only last/return stop left so remove it or make destroy false
          }
          console.log('routeScheduledata after remove stop:::::', this.routeScheduleData);
          this.calculateTotalPickDropQuantity();
          this.isCalculateRouteTime = true;
          this.drawRoute(this.selectedFacility, this.selectedVehicle, this.routeScheduleData);
          
          this.ariaExpanded = 0;
          const selectedDayForRoute = this.routeScheduleData[0];
          this.isStartTimeChange(selectedDayForRoute.start_time, selectedDayForRoute, 'route');
        }
      });
    }
  }
  calcDistance(p1: any, p2: any): any {
    const d = (google.maps.geometry.spherical.computeDistanceBetween(p1, p2) / 1000).toFixed(2);
    console.log(d);
    return d;
  }
  drawRoute(facilityObj: any, vehicleObj: any, routeScheduleDataObj: any): void {
    if (!vehicleObj.route_draw_type) {
      console.log('polyline draw::::');
      this.routeVisible = false;
      this.allGeoPaths = [];
      this.polylines = [];
      this.polylinePathArry = [];
      this.polylineMidMarkers = [];
      this.polyMarkerId = 1;
      let distance = 0;
      let individualDistance = 0;
      this.routeDistance = 0;
      if (routeScheduleDataObj && routeScheduleDataObj[0].route_stops_attributes?.length > 0) {
        const routeStops = routeScheduleDataObj[0].route_stops_attributes;
        let stopCount = 0;
        for (const x of routeStops) {
          if (x._destroy === false) {
            stopCount++;
          }
        }
        for (let stop = 0; stop < routeStops.length; stop++) {
          if (routeStops[stop]._destroy === false) {
            let startPoint: any = {};
            if (stop === 0) {
              startPoint = {
                lat: parseFloat(facilityObj.latitude),
                lng: parseFloat(facilityObj.longitude),
              };
            } else {
              startPoint = {
                lat: parseFloat(routeStops[stop - 1].end_facility_data.latitude),
                lng: parseFloat(routeStops[stop - 1].end_facility_data.longitude),
              };
            }
            const endPoint = {
              lat: parseFloat(routeStops[stop].end_facility_data.latitude),
              lng: parseFloat(routeStops[stop].end_facility_data.longitude),
            };
            if (stopCount > 2) {
              this.getPathOfTwoPoints(startPoint, endPoint);
            } else {
              if (stop === 0) {
                this.getPathOfTwoPoints(startPoint, endPoint);
              } else {
                const startPointObj = {
                  lat: startPoint.lat,
                  lng: startPoint.lng,
                  id: this.polyMarkerId,
                };
                this.polylinePathArry.push(startPointObj);
                const revArr: any[] = this.clonerService.deepClone(this.polylineMidMarkers);
                revArr.reverse();
                for (const r of revArr) {
                  ++this.polyMarkerId;
                  r.id = this.polyMarkerId;
                  this.polylineMidMarkers.push(r);
                  const midpointObj = {
                    lat: r.lat,
                    lng: r.lng,
                    id: this.polyMarkerId,
                  };
                  this.polylinePathArry.push(midpointObj);
                }
                ++this.polyMarkerId;
                const endPointObj = {
                  lat: endPoint.lat,
                  lng: endPoint.lng,
                  id: this.polyMarkerId,
                };
                this.polylinePathArry.push(endPointObj);
                ++this.polyMarkerId;
              }
            }
            console.log('this.polylineMidMarkers', this.polylineMidMarkers);
          }
        }
        console.log('this.polylinePathArry', this.polylinePathArry);
        /*pathArry.push(startEndPoint);     // starting points
        for (const stop of routeScheduleDataObj[0].route_stops_attributes) {
          if (stop._destroy === false) {
            const pathObj = {
              lat: parseFloat(stop.end_facility_data.latitude),
              lng: parseFloat(stop.end_facility_data.longitude)
            };
            pathArry.push(pathObj);
          }
        }*/

        // pathArry.push(startEndPoint);    // ending points
        let bpIndex = 1; // breakepoint index
        const breakpoint = this.polylinePathArry.length / this.routeScheduleData[0].route_stops_attributes.length;

        // calculate distance of polyline
        for (let points = 0; points < this.polylinePathArry.length; points++) {
          const p1 = new google.maps.LatLng(this.polylinePathArry[points].lat, this.polylinePathArry[points].lng);
          if (this.polylinePathArry[points + 1]) {
            const p2 = new google.maps.LatLng(
              this.polylinePathArry[points + 1].lat,
              this.polylinePathArry[points + 1].lng
            );
            distance += parseFloat(this.calcDistance(p1, p2));
            individualDistance += parseFloat(this.calcDistance(p1, p2));
            if (points === (breakpoint - 1) * bpIndex) {
              for (const stopAttr of this.routeScheduleData) {
                stopAttr.route_stops_attributes[bpIndex - 1].distance = individualDistance; // calculate individual route stop distance for polyline
              }
              bpIndex++;
              individualDistance = 0;
            }
          }
        }
        console.log('polyine distance::::', distance);
        this.routeDistance = distance;
        const time = distance / vehicleObj.speed;
        // this.routeTime = parseFloat(time.toFixed(2));
        const modules = time % 1;
        const h = Math.floor(time);
        const m = Math.floor(modules * 60);
        this.routeTime = h + ':' + m;
        if (this.isCalculateRouteTime) {
          this.ariaExpanded = 0;
          const selectedDayForRoute = this.routeScheduleData[0];
          this.isStartTimeChange(selectedDayForRoute.start_time, selectedDayForRoute, 'route');
        }
      }
      this.allGeoPaths = this.polylinePathArry;
      const polylineObj = {
        color: '#0809c5',
        visible: true,
        path: this.polylinePathArry,
      };
      this.polylines.push(polylineObj);
    } else {
      this.waypoints = [];
      this.routeVisible = true;
      if (routeScheduleDataObj && routeScheduleDataObj[0].route_stops_attributes?.length > 0) {
        const routeStop = routeScheduleDataObj[0].route_stops_attributes;
        for (let stop = 0; stop < routeStop.length - 1; stop++) {
          if (routeStop[stop]._destroy === false) {
            const wayPointObj = {
              location: {
                lat: parseFloat(routeStop[stop].end_facility_data.latitude),
                lng: parseFloat(routeStop[stop].end_facility_data.longitude),
              },
            };
            this.waypoints.push(wayPointObj);
          }
        }
      }
      this.startPoint = { lat: parseFloat(facilityObj.latitude), lng: parseFloat(facilityObj.longitude) };
      this.endPoint = { lat: parseFloat(facilityObj.latitude), lng: parseFloat(facilityObj.longitude) };
    }
  }
  getPathOfTwoPoints(sPoint: any, ePoint: any): any {
    const startLat = sPoint.lat;
    const endLat = ePoint.lat;
    const latDifference = endLat - startLat;
    const startLng = sPoint.lng;
    const endLng = ePoint.lng;
    let lngDifference = endLng - startLng;
    const lngDifference180 = 180 - Math.abs(startLng) + (180 - Math.abs(endLng));
    const midPoints = [];
    if (Math.abs(lngDifference) > lngDifference180) {
      if (startLng < 0 && endLng > 0) {
        lngDifference = lngDifference180 * -1;
      } else {
        lngDifference = lngDifference180;
      }
    }
    const startPointObj = {
      lat: startLat,
      lng: startLng,
      id: this.polyMarkerId,
    };
    this.polylinePathArry.push(startPointObj);
    for (let p = 0.1; p < 1.0; p += 0.10) {
      ++this.polyMarkerId;
      const midpointObj = {
        lat: startLat + latDifference * p,
        lng: startLng + lngDifference * p,
        id: this.polyMarkerId,
      };
      const iconObj = {
        url: 'assets/images/white_circle.png',
      };
      this.polylineMidMarkers.push({
        label: '',
        lat: midpointObj.lat,
        lng: midpointObj.lng,
        draggable: true,
        animation: 'DROP',
        savetype: '',
        icon: iconObj,
        id: this.polyMarkerId,
      });
      this.polylinePathArry.push(midpointObj);
    }
    ++this.polyMarkerId;
    const endPointObj = {
      lat: endLat,
      lng: endLng,
      id: this.polyMarkerId,
    };
    this.polylinePathArry.push(endPointObj);
    ++this.polyMarkerId;
  }
  polyMarkerDragStart(m: any, $event: any): void {
    console.log($event);
    m.lat = $event.coords.lat;
    m.lng = $event.coords.lng;
  }
  polyMarkerDragEnd(m: any, $event: any): void {
    console.log($event);
    m.lat = $event.coords.lat;
    m.lng = $event.coords.lng;
    const index = this.polylines[0].path.findIndex((x: any) => x.id === m.id);
    if (index !== -1) {
      this.polylines[0].path[index].lat = $event.coords.lat;
      this.polylines[0].path[index].lng = $event.coords.lng;
    }
    let distance = 0;
    let individualDistance = 0;
    let bpIndex = 1; // breakepoint index
    const breakpoint = this.polylines[0].path.length / this.routeScheduleData[0].route_stops_attributes.length;
    // calculate distance of polyline
    for (let points = 0; points < this.polylines[0].path.length; points++) {
      const p1 = new google.maps.LatLng(this.polylines[0].path[points].lat, this.polylines[0].path[points].lng);
      if (this.polylines[0].path[points + 1]) {
        const p2 = new google.maps.LatLng(
          this.polylines[0].path[points + 1].lat,
          this.polylines[0].path[points + 1].lng
        );
        distance += parseFloat(this.calcDistance(p1, p2));
        individualDistance += parseFloat(this.calcDistance(p1, p2));
        if (points === (breakpoint - 1) * bpIndex) {
          for (const stopAttr of this.routeScheduleData) {
            stopAttr.route_stops_attributes[bpIndex - 1].distance = individualDistance; // calculate individual route stop distance for polyline while drag end
          }
          bpIndex++;
          individualDistance = 0;
        }
      }
    }
    console.log('polyine distance::::', distance);
    this.routeDistance = distance;
    const time = distance / this.selectedVehicle.speed;
    // this.routeTime = parseFloat(time.toFixed(2));
    const modules = time % 1;
    const h = Math.floor(time);
    const min = Math.floor(modules * 60);
    this.routeTime = h + ':' + min;
    console.log('polyline after route drag--', this.polylines);
    /*this.polylines = [];
    const newMidMarkerArr = [];
    const startPointObj = {
      lat: this.polylinePathArry[0].lat,
      lng: this.polylinePathArry[0].lng
    };
    newMidMarkerArr.push(startPointObj);
    if (this.polylineMidMarkers?.length > 0) {
      for ( const midMarker of this.polylineMidMarkers) {
        const midPointObj = {
          lat: midMarker.lat,
          lng: midMarker.lng
        };
        newMidMarkerArr.push(midPointObj);
      }
      const endPointObj = {
        lat: this.polylinePathArry[this.polylinePathArry.length / 2].lat,
        lng: this.polylinePathArry[this.polylinePathArry.length / 2].lng
      };
      newMidMarkerArr.push(startPointObj);
      this.polylinePathArry = newMidMarkerArr;
      const polylineObj = {
        color: 'blue',
        visible: true,
        path: this.polylinePathArry
      };
      this.polylines.push(polylineObj);
    }*/
  }
  openProdAddInRouteModal(stopObj: any, index: any): void {
    this.addProductInStopForm.reset({
      prodDeliveryQty: 0,
      prodPickupQty: 0,
    });
    $('app-modal > .ui-modal').css('z-index', 9);
    $('#prodInRouteStop > .ui-modal').css({ display: 'block', 'z-index': 10 });
    this.selectedStopObjIndex = index;
    if (this.facilityListForRouteStop?.length > 0) {
      this.selectedRouteFacility = this.facilityListForRouteStop.find((x: any) => x.id === stopObj.end_facility_id);
    }
    setTimeout((): any => {
      // this.facilityListForRouteStop ======> facility list of route popup selected dropdown.
      // this.selectedRouteFacility ====>>>> facility selected in select dropdown in route popup.
      // this.selectedProdInRouteStop ====>>>> default product selected of selected facility in add product popup popup.
      if (this.selectedFacility.facility_schedule_products?.length > 0) {
        this.selectedProdInRouteStop = this.selectedFacility.facility_schedule_products[0];
      }
    }, 100);
  }
  changeProduct(): void {
    /*this.stopProdDemand = prodObj.display_demand;
    this.stopProdProduction = prodObj.display_production;
    this.stopProdOnHandAmount = prodObj.on_hand_amount;*/
  }
  checkProductWeightForVehicle(): boolean {
    let isValid = true;
    for (const prod of this.routeScheduleData) {
      let dropQtyForVol = 0;
      let pickQtyForVol = 0;
      let dropQtyForWgt = 0;
      let pickQtyForWgt = 0;
      if (prod.route_stops_attributes[this.selectedStopObjIndex].route_stop_products_attributes) {
        for (const routeStopProd of prod.route_stops_attributes[this.selectedStopObjIndex]
          .route_stop_products_attributes) {
          if (routeStopProd) {
            const findProduct = this.selectedFacility.facility_schedule_products.find((product: any) => product.product_id === routeStopProd.product_id);
            if (findProduct) {
              dropQtyForVol += routeStopProd.drop_off_quantity * findProduct.product_volume;
              pickQtyForVol += routeStopProd.pickup_quantity * findProduct.product_volume;
              dropQtyForWgt += routeStopProd.drop_off_quantity * findProduct.product_weight;
              pickQtyForWgt += routeStopProd.pickup_quantity * findProduct.product_weight;
            }
          }
        }
        dropQtyForVol += this.prodDeliveryQty * this.selectedProdInRouteStop.product_volume;
        pickQtyForVol += this.prodPickupQty * this.selectedProdInRouteStop.product_volume;
        dropQtyForWgt += this.prodDeliveryQty * this.selectedProdInRouteStop.product_weight;
        pickQtyForWgt += this.prodPickupQty * this.selectedProdInRouteStop.product_weight;
        if (dropQtyForWgt > this.selectedVehicle.max_weight || pickQtyForWgt > this.selectedVehicle.max_weight) {
          this.Toast.fire({
            icon: 'warning',
            title:
              'Total Start Weight for this Vehicle is more than the Max Weight: ' +
              (pickQtyForWgt > this.selectedVehicle.max_weight ? pickQtyForWgt : dropQtyForWgt)  +
              '/' +
              this.selectedVehicle.max_weight +
              'kg',
          });
          isValid = false;
          return isValid;
        }
        if (dropQtyForVol > this.selectedVehicle.max_volume) {
          this.Toast.fire({
            icon: 'warning',
            title:
              'Total Start Volume for this vehicle is more than Carry Volume: ' +
              (pickQtyForVol > this.selectedVehicle.max_volume ? pickQtyForVol : dropQtyForVol) +
              '/' +
              this.selectedVehicle.max_volume +
              'm ².',
          });
          isValid = false;
          return isValid;
        }
      }
    }
    return isValid;
  }
  addProductInStopRoute(): void {
    // console.log('product add clicked...');
    const stopindexedObj = this.routeScheduleData[0].route_stops_attributes[this.selectedStopObjIndex];
    if (stopindexedObj.route_stop_products_attributes?.length > 0) {
      if (
        stopindexedObj.route_stop_products_attributes.some(
          (product: any) => product.product_id === this.selectedProdInRouteStop.product_id
        )
      ) {
        for (const schedule of this.routeScheduleData) {
          for (const stop of schedule.route_stops_attributes) {
            for (const product of stop.route_stop_products_attributes) {
              if (product.product_id === this.selectedProdInRouteStop.product_id && product._destroy === true) {
                product._destroy = false;
                product.drop_off_quantity = this.prodDeliveryQty;
                product.pickup_quantity = this.prodPickupQty;
              } else if (product.product_id === this.selectedProdInRouteStop.product_id && product._destroy === false) {
                this.Toast.fire({
                  icon: 'warning',
                  title: 'Selected product already added.',
                });
                return;
              }
            }
          }
        }
      } else {
        const prodObj = {
          route_stop_id: this.routeScheduleData[0].route_stops_attributes[this.selectedStopObjIndex].end_facility_id,
          product_id: this.selectedProdInRouteStop.product_id,
          product_name: this.selectedProdInRouteStop.product_name,
          drop_off_quantity: this.prodDeliveryQty,
          pickup_quantity: this.prodPickupQty,
          _destroy: false,
          product_volume: this.selectedProdInRouteStop.product_volume,
        };
        if (!this.checkProductWeightForVehicle()) {
            return;
        }
        for (const prod of this.routeScheduleData) {
          let dropQty = 0;
          let pickQty = 0;
          if (prod.route_stops_attributes[this.selectedStopObjIndex].route_stop_products_attributes) {
            for (const routeStopProd of prod.route_stops_attributes[this.selectedStopObjIndex]
              .route_stop_products_attributes) {
              if (routeStopProd) {
                dropQty += routeStopProd.drop_off_quantity * routeStopProd.product_volume;
                pickQty += routeStopProd.pickup_quantity * routeStopProd.product_volume;
              }
            }
            dropQty += this.prodDeliveryQty * this.selectedProdInRouteStop.product_volume;
            pickQty += this.prodPickupQty * this.selectedProdInRouteStop.product_volume;
            if (dropQty > this.selectedVehicle.max_volume) {
              this.Toast.fire({
                icon: 'warning',
                title:
                  'Total drop volume is more than Vehicle carry volume of ' +
                  dropQty +
                  '/' +
                  this.selectedVehicle.max_volume +
                  'm ².',
              });
              return;
            }
            if (pickQty > this.selectedVehicle.max_volume) {
              this.Toast.fire({
                icon: 'warning',
                title:
                  'Total pick up volume is more than Vehicle carry volume of ' +
                  pickQty +
                  '/' +
                  this.selectedVehicle.max_volume +
                  'm ².',
              });
              return;
            }
          }
          const clonedObj = this.clonerService.deepClone(prodObj);
          prod.route_stops_attributes[this.selectedStopObjIndex].route_stop_products_attributes.push(clonedObj);
        }
      }
    } else if (stopindexedObj.route_stop_products_attributes?.length <= 0 || this.selectedStopObjIndex === 0 ) {
      const prodObj = {
        route_stop_id: this.routeScheduleData[0].route_stops_attributes[this.selectedStopObjIndex].end_facility_id,
        product_id: this.selectedProdInRouteStop.product_id,
        product_name: this.selectedProdInRouteStop.product_name,
        drop_off_quantity: this.prodDeliveryQty,
        pickup_quantity: this.prodPickupQty,
        _destroy: false,
        product_volume: this.selectedProdInRouteStop.product_volume,
      };
      if (!this.checkProductWeightForVehicle()) {
        return;
      }
      for (const prod of this.routeScheduleData) {
        let dropQty = 0;
        let pickQty = 0;
        if (prod.route_stops_attributes[this.selectedStopObjIndex].route_stop_products_attributes) {
          for (const routeStopProd of prod.route_stops_attributes[this.selectedStopObjIndex]
            .route_stop_products_attributes) {
            dropQty += routeStopProd.drop_off_quantity * routeStopProd.product_volume;
            pickQty += routeStopProd.pickup_quantity * routeStopProd.product_volume;
          }
          dropQty += this.prodDeliveryQty * this.selectedProdInRouteStop.product_volume;
          pickQty += this.prodPickupQty * this.selectedProdInRouteStop.product_volume;
          if (dropQty > this.selectedVehicle.max_volume) {
            this.Toast.fire({
              icon: 'warning',
              title:
                'Total drop volume is more than Vehicle carry volume of ' +
                dropQty +
                '/' +
                this.selectedVehicle.max_volume +
                'm ².',
            });
            return;
          }
          if (pickQty > this.selectedVehicle.max_volume) {
            this.Toast.fire({
              icon: 'warning',
              title:
                'Total pick up volume is more than Vehicle carry volume of ' +
                pickQty +
                '/' +
                this.selectedVehicle.max_volume +
                'm ².',
            });
            return;
          }
        }
        const clonedObj = this.clonerService.deepClone(prodObj);
        prod.route_stops_attributes[this.selectedStopObjIndex].route_stop_products_attributes.push(clonedObj);
      }
    }
    this.calculateTotalPickDropQuantity();
    this.closeModalLib('prodInRouteStop', '');
    this.Toast.fire({
      icon: 'success',
      title: 'Product added in all days of all weeks.',
    });
    console.log('routeScheduleData after add product', this.routeScheduleData);
  }
  getOldValues(prod: any ): any {
    this.oldPickupAmount = prod.pickup_quantity;
    this.oldDropOffAmount = prod.drop_off_quantity;
    this.selectedProductId = prod.product_id;
   }

   updateWaitTimeOfWeek(dayOfWeek: any, weekNumber: any, event: any ): any{
    const latestValue = event.target.value;
    if (this.routeScheduleData?.length > 0 && dayOfWeek === 1) {
      for (const schedule of this.routeScheduleData) {
        if (schedule.route_stops_attributes?.length > 0) {
           if (schedule.week_number === weekNumber) {
            const stop = schedule.route_stops_attributes[0];
            if (stop && stop.route_stop_products_attributes?.length > 0 && !stop.is_return && !stop._destroy) {
              stop.stop_time = latestValue;
            }
          }
        }
      }
    }

    this.ariaExpanded = 0;
    const selectedDayForRoute = this.routeScheduleData[0];
    this.isStartTimeChange(selectedDayForRoute.start_time, selectedDayForRoute, 'route');
  }

  updatePickDropQuantityOfWeek(prod: any, dayOfWeek: any, weekNumber: any, event: any): any{
    const latestValue = event?.target?.value;
    const dropOffQuantity =  event?.currentTarget?.classList?.contains('drop-off-quantity') ?  latestValue :  prod.drop_off_quantity;
    const pickupQuantity  = event?.currentTarget?.classList?.contains('pickup-quantity') ? latestValue :  prod.pickup_quantity;
   
    if (this.routeScheduleData?.length > 0 && dayOfWeek === 1) {
      for (const schedule of this.routeScheduleData) {
        if (schedule.route_stops_attributes?.length > 0) {
           if (schedule.week_number === weekNumber){
            const stop = schedule.route_stops_attributes[0];
            if (stop && stop.route_stop_products_attributes?.length > 0 && !stop.is_return && !stop._destroy) {
              
              for (const product of stop.route_stop_products_attributes) {
                // @ts-ignore
               if (product.product_id === prod.product_id){
                product.drop_off_quantity = dropOffQuantity;
                product.pickup_quantity = pickupQuantity;
               }
            }
          }
        }
        }
      }
    }

  }
  calculateTotalPickDropQuantity(prod?: any, dayOfWeek?: any, weekNumber?: any, event?: any): any {
    if (this.routeScheduleData?.length > 0) {
      for (const schedule of this.routeScheduleData) {
        if (schedule.route_stops_attributes?.length > 0) {
          const lastStop = this.clonerService.deepClone(
            schedule.route_stops_attributes[schedule.route_stops_attributes.length - 1]
          );
          // @ts-ignore
          if (lastStop.route_stop_products_attributes?.length > 0) {
            // @ts-ignore
            for (const lStop of lastStop.route_stop_products_attributes) {
              if (lStop){
                lStop.drop_off_quantity = 0;
                lStop.pickup_quantity = 0;
              }
            }
          }
          for (const stop of schedule.route_stops_attributes) {
            if (stop.route_stop_products_attributes?.length > 0 && !stop.is_return && !stop._destroy) {
              let dropQuantityVolume = 0;
              let pickupQuantityVolume = 0;
              let dropQuantityWeight = 0;
              let pickupQuantityWeight = 0;
              let dropOffQuantity = 0;
              let pickupQuantity = 0;
              for (const product of stop.route_stop_products_attributes) {
                const findProduct = this.selectedFacility.facility_schedule_products.find((fProduct: any) => fProduct.product_id === product.product_id);
                if (findProduct) {
                  dropOffQuantity += parseFloat(product.drop_off_quantity);
                  pickupQuantity += parseFloat(product.pickup_quantity);

                  dropQuantityVolume = dropOffQuantity * parseFloat(findProduct.product_volume);
                  pickupQuantityVolume = pickupQuantity * parseFloat(findProduct.product_volume);

                  dropQuantityWeight = dropOffQuantity * parseFloat(findProduct.product_weight);
                  pickupQuantityWeight = pickupQuantity * parseFloat(findProduct.product_weight);
                  // const totalQuantity = productAvailable.drop_off_quantity + productAvailable.pickup_quantity;
                  if (dropQuantityVolume > this.selectedVehicle.max_volume) {
                    this.Toast.fire({
                      icon: 'warning',
                      title:
                        'Drop Volume is more than Vehicle carry volume of ' +
                        dropQuantityVolume +
                        '/' +
                        this.selectedVehicle.max_volume +
                        'm ². This vehicle will be overloaded before it leaves.',
                    });
                    const index = stop.route_stop_products_attributes.findIndex((Std: any) => Std.product_id === this.selectedProductId);
                    stop.route_stop_products_attributes[index].drop_off_quantity = this.oldDropOffAmount;
                    return;
                  }
                  if (pickupQuantityVolume > this.selectedVehicle.max_volume) {
                    this.Toast.fire({
                      icon: 'warning',
                      title:
                        'Pickup Volume is more than Vehicle carry volume of ' +
                        pickupQuantityVolume +
                        '/' +
                        this.selectedVehicle.max_volume +
                        'm ². This vehicle will be overloaded before it leaves.',
                    });
                    const index = stop.route_stop_products_attributes.findIndex((Std: any) => Std.product_id === this.selectedProductId);
                    stop.route_stop_products_attributes[index].pickup_quantity = this.oldPickupAmount;
                    return;
                  }
                  if (dropQuantityWeight > this.selectedVehicle.max_weight) {
                    this.Toast.fire({
                      icon: 'warning',
                      title:
                        'Drop Weight is more than Vehicle max weight of ' +
                        dropQuantityWeight +
                        '/' +
                        this.selectedVehicle.max_weight +
                        'kg. This vehicle will be overloaded before it leaves.',
                    });
                    const index = stop.route_stop_products_attributes.findIndex((Std: any) => Std.product_id === this.selectedProductId);
                    stop.route_stop_products_attributes[index].drop_off_quantity = this.oldDropOffAmount;
                    return;
                  }
                  if (pickupQuantityWeight > this.selectedVehicle.max_weight) {
                    this.Toast.fire({
                      icon: 'warning',
                      title:
                        'Pickup  Weight is more than Vehicle max weight of ' +
                        pickupQuantityWeight +
                        '/' +
                        this.selectedVehicle.max_weight +
                        'kg. This vehicle will be overloaded before it leaves.',
                    });
                    const index = stop.route_stop_products_attributes.findIndex((Std: any) => Std.product_id === this.selectedProductId);
                    stop.route_stop_products_attributes[index].pickup_quantity = this.oldPickupAmount;
                    return;
                  }
                }
              }
              // console.log(this.oldDropOffAmount, this.oldPickupAmount);
              // for (const product of stop.route_stop_products_attributes) {
              //   // @ts-ignore
              //   const productAvailable = this.selectedFacility.facility_schedule_products.find(
              //     (facilityProduct: any) => facilityProduct?.product_id === product?.product_id
              //   );
              //   if (productAvailable) {
              //     productAvailable.drop_off_quantity += parseFloat(product.drop_off_quantity);
              //     productAvailable.pickup_quantity += parseFloat(product.pickup_quantity);
              //     dropQuantityVolume += parseFloat(product.drop_off_quantity) * parseFloat(product.product_volume);
              //     pickupQuantityVolume += parseFloat(product.pickup_quantity) * parseFloat(product.product_volume);
              //     // const totalQuantity = productAvailable.drop_off_quantity + productAvailable.pickup_quantity;
              //     if (dropQuantityVolume > this.selectedVehicle.max_volume) {
              //       this.Toast.fire({
              //         icon: 'warning',
              //         title:
              //           'Total drop volume is more than Vehicle carry volume of ' +
              //           dropQuantityVolume +
              //           '/' +
              //           this.selectedVehicle.max_volume +
              //           'm ². This vehicle will be overloaded before it leaves.',
              //       });
              //       product.drop_off_quantity = this.oldDropOffAmount;
              //       return;
              //     }
              //     if (pickupQuantityVolume > this.selectedVehicle.max_volume) {
              //       this.Toast.fire({
              //         icon: 'warning',
              //         title:
              //           'Total pick up volume is more than Vehicle carry volume of ' +
              //           pickupQuantityVolume +
              //           '/' +
              //           this.selectedVehicle.max_volume +
              //           'm ².',
              //       });
              //       product.pickup_quantity = this.oldPickupAmount;
              //       return;
              //     }
              //   } else {
              //     // @ts-ignore
              //     lastStop.route_stop_products_attributes.push(product);
              //   }
              // }
            }
          }
          schedule.route_stops_attributes.pop();
          schedule.route_stops_attributes.push(lastStop);
        }
      }
    }
    if (prod && dayOfWeek && weekNumber) {
      this.updatePickDropQuantityOfWeek(prod, dayOfWeek, weekNumber, event);
    }
  }
  removeProdcutInRoute(prodObj: any, stopIndex: any): void {
    console.log(prodObj);
    if (prodObj.product_id) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This product will be deleted from all the scheduled days.',
        icon: 'warning',
        position: 'top-right',
        customClass: {
          container: 'swalConfirmCls',
          confirmButton: 'btn btn-outline-danger',
          cancelButton: 'btn btn-outline-primary',
        },
        showCancelButton: true,
        confirmButtonColor: '#f96a74',
        cancelButtonColor: '#4f9d8d',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          for (const prod of this.routeScheduleData) {
            const index = prod.route_stops_attributes[stopIndex].route_stop_products_attributes.findIndex(
              (product: any) => product.product_id === prodObj.product_id
            );
            if (index > -1) {
              if (prod.route_stops_attributes[stopIndex].route_stop_products_attributes[index].id) {
                delete prod.route_stops_attributes[stopIndex].route_stop_products_attributes[index]; 
              } else {
                prod.route_stops_attributes[stopIndex].route_stop_products_attributes.splice(index, 1); // delete product from list
              }
            }
          }
          console.log('routeScheduleData', this.routeScheduleData);
          this.calculateTotalPickDropQuantity();
        }
      });
    }
  }
  addUpdateRoute(): void {
    const copyRouteScheduleData: any = this.clonerService.deepClone(this.routeScheduleData);
    if (copyRouteScheduleData?.length > 0) {
      if (copyRouteScheduleData[0].route_stops_attributes?.length > 0) {
        if (copyRouteScheduleData[0].route_stops_attributes[0].route_stop_products_attributes?.length <= 0) {
          this.Toast.fire({
            icon: 'warning',
            title: 'Please add atleast one product in stop.',
          });
          return;
        }
      } else {
        this.Toast.fire({
          icon: 'warning',
          title: 'Please add atleast one stop.',
        });
        return;
      }
      // const copyRouteScheduleData = this.clonerService.deepClone(copyRouteScheduleData);
      for (const f of copyRouteScheduleData) {
        f.start_time = this.getTime(f.start_time);
        f.end_time = this.getTime(f.end_time);
        // if (f.route_stops_attributes.length > 0) {
        //   let totalStopTime = 0;
        //   let totalRouteDuration = this.routeTime;
        //   f.route_stops_attributes.forEach((stops: any) => {
        //     if (!stops._destroy && !stops.is_return) {
        //       totalStopTime += Number(stops.stop_time);
        //     }
        //   });
        //   totalRouteDuration = this.setMinutesToDuration(totalRouteDuration, totalStopTime);
        //   const endTimeObj = this.calculateEndTimeFromRouteDuration(f.day_of_week, f.start_time, totalRouteDuration, f.week_number);
        //   f.end_time = endTimeObj.endTime;
        //   f.end_day_of_week = endTimeObj.endDayOfWeek;
        //   f.end_week_number = endTimeObj.endWeek;
        // }

        // delete f.end_time;
      }
    }

    const sendGeopath = JSON.stringify(this.encodePath(this.allGeoPaths));
    // const compressGeopath = lzwCompress.pack(this.allGeoPaths);
    // console.log('compressed geopath:::', compressGeopath);
    const mapManualRouteLocation = this.manualRoute.map((route: any) => route.location);
    const sendWaypoints = JSON.stringify(this.encodePath(mapManualRouteLocation));
    // const compressWaypoints = lzwCompress.pack(this.manualRoute); // TODO:: check this line before code pushed

    console.log('before routeTime', this.routeTime);
    if (this.routeTime === 0) {
      this.routeTime = '00:00';
    }
    console.log('after routeTime', this.routeTime);
    const payload = {
      name: this.routeName,
      total_distance: this.routeDistance,
      total_duration: this.routeTime,
      waypoints: sendWaypoints,
      geopath: sendGeopath,
      route_schedules_attributes: copyRouteScheduleData,
      copy_from_id: this.copiedRouteId || '',
      supply_chain_id: this.selectedSupplyChain.id,
    };
    const payloadIds = {
      supply_chain_id: this.selectedSupplyChain.id,
      facility_id: this.selectedFacility.id,
      vehicle_id: this.selectedVehicle.id,
      update: this.routeUpdate,
      id: this.routeId || '',
    };
    this.spinner.show();
    this.simulationService.addUpdateRouteService(payload, payloadIds).subscribe((res: any) => {
      this.spinner.hide();
      this.markerAdded = false;
      if (res) {
        if (this.routeUpdate) {
          this.Toast.fire({
            icon: 'success',
            title: 'Updated Successfully.',
          });
        } else {
          this.Toast.fire({
            icon: 'success',
            title: 'Created Successfully.',
          });
        }
        this.routeForm.reset();
        this.getVehicleRouteList(this.selectedSupplyChain.id, this.selectedFacility, this.selectedVehicle);
        this.closeModalLib('showRoutes', '');
        this.polylineMidMarkers = [];
        // make temp route by polyline
        this.polylines = [];
        const parseGeopath = JSON.parse(res.geopath);
        const geoPath = google.maps.geometry.encoding.decodePath(parseGeopath);
        const pathArry = geoPath.map(path => {
          return path.toJSON();
        });
        const polylineObj = {
          color: '#0809c5',
          visible: true,
          path: pathArry,
        };
        this.polylines.push(polylineObj);
      }
    });
  }
  deleteRoute(facilityObj: any, vehicleObj: any, routeObj: any): void {
    if (facilityObj && vehicleObj && routeObj) {
      this.selectedFacility = facilityObj;
      this.selectedVehicle = vehicleObj;
      this.selectedRoute = routeObj;
      this.facilityBeforeDelData = {
        vehicles: [],
      };
      this.facilityBeforeDelData.vehicles.push(vehicleObj.name);
      this.selectedMode = 'route';
      $('#deleteFacilityModal').modal('toggle');
    }
  }
  removeRouteConfirm(): void {
    const payload = {
      vehicle_id: this.selectedVehicle.id,
      facility_id: this.selectedFacility.id,
      route_id: this.selectedRoute.id,
      supplyId: this.selectedSupplyChain.id,
    };
    this.spinner.show();
    this.simulationService.deleteVehicleRouteService(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.getVehicleRouteList(this.selectedSupplyChain.id, this.selectedFacility, this.selectedVehicle);
        this.Toast.fire({
          icon: 'success',
          title: 'Route Deleted successfully',
        });
        $('#deleteFacilityModal').modal('toggle');
        this.closeModalLib('showRoutes', '');
        this.polylines = [];
      }
    });
  }
  cloneWeekRouteFn(w: any): void {
    if (w) {
      // START:: open modal and set z-index
      const callId = $('#copyWeekInRoute > .ui-modal');
      callId.css('display', 'block');
      const zIndex = callId.css('z-index');
      callId.css('z-index', zIndex + 9);
      // END:: open modal and set z-index
      this.copyWeekNumberRoute = w;
      this.weekDropData = JSON.parse(JSON.stringify(this.originalWeekDropData));
      this.weekDropData = this.weekDropData.filter((week: any) => week.item_id !== w);
    }
  }
  addCopiedWeeksInRoutes(): void {
    // now below add weeks in routes...
    const copyWeekArray = this.routeScheduleData.filter(
      (dataObj: any) => dataObj.week_number === this.copyWeekNumberRoute
    );
    console.log('copyWeekArry: ', copyWeekArray);
    const weekArray = [];
    for (const week of this.selectedNoOfWeeksInRoute) {
      weekArray.push(week.item_id);
      if (this.selectedRouteWeekList.includes(week.item_id)) {
        // if week already available
        const length = this.routeScheduleData.length;
        for (let x = 0; x < length; x++) {
          const removeIndex = this.routeScheduleData.map((item: any) => item.week_number).indexOf(week.item_id); // get index of object with weeknumber
          if (removeIndex !== -1) {
            this.routeScheduleData.splice(removeIndex, 1); // remove object
          }
        }
        console.log('this.routeScheduleData - after splice ', this.routeScheduleData);
        for (const weekDay of copyWeekArray) {
          const cloneObj = this.clonerService.deepClone(weekDay);
          // @ts-ignore
          if (cloneObj.id) {
            // @ts-ignore
            delete cloneObj.id;
          }
          // @ts-ignore
          if (cloneObj.route_stops_attributes?.length > 0) {
            // @ts-ignore
            for (const product of cloneObj.route_stops_attributes) {
              if (product.id) {
                delete product.id;
              }
            }
          }
          // @ts-ignore
          cloneObj.week_number = week.item_id;
          this.routeScheduleData.push(cloneObj);
        }
        // console.log('this.facilityScheduleData - after splice - after add ', this.facilityScheduleData);
      } else {
        // if week is new entry
        for (const weekDay of copyWeekArray) {
          const cloneObj = this.clonerService.deepClone(weekDay);
          // @ts-ignore
          if (cloneObj.id) {
            // @ts-ignore
            delete cloneObj.id; // remove id for new entry
          }
          // @ts-ignore
          if (cloneObj.route_stops_attributes?.length > 0) {
            // @ts-ignore
            for (const product of cloneObj.route_stops_attributes) {
              if (product.id) {
                delete product.id;
              }
            }
          }
          // @ts-ignore
          cloneObj.week_number = week.item_id;
          this.routeScheduleData.push(cloneObj);
        }
        console.log('this.routeScheduleData - when new entry ', this.routeScheduleData);
        this.selectedRouteWeekList.push(week.item_id);
      }
      console.log('final data this.routeScheduleData  ', this.routeScheduleData);
    }
    this.selectedRouteWeekList.sort((a: any, b: any) => parseFloat(a) - parseFloat(b));
    console.log('weekarray:- ', weekArray);
    this.calculateTotalPickDropQuantity();
    this.closeModalLib('copyWeekInRoute', '');

    this.ariaExpanded = 0;
    const selectedDayForRoute = this.routeScheduleData[0];
    this.isStartTimeChange(selectedDayForRoute.start_time, selectedDayForRoute, 'route');
  }
  removeWeekRouteFn(weekNo: any): void {
    if (weekNo) {
      if (this.selectedRouteWeekList?.length > 1) {
        Swal.fire({
          title: 'Are you sure?',
          text: 'You won&#39;t be able to revert this!',
          icon: 'warning',
          position: 'top-right',
          customClass: {
            container: 'swalConfirmCls',
            confirmButton: 'btn btn-outline-danger',
            cancelButton: 'btn btn-outline-primary',
          },
          showCancelButton: true,
          confirmButtonColor: '#f96a74',
          cancelButtonColor: '#4f9d8d',
          confirmButtonText: 'Yes, delete it!',
        }).then(result => {
          if (result.isConfirmed) {
            // start:: remove week from selectedWeekList
            const index = this.selectedRouteWeekList.indexOf(weekNo);
            if (index > -1) {
              this.selectedRouteWeekList.splice(index, 1);
            }
            console.log('selectedWeekList after remove week', this.selectedRouteWeekList);
            // end:: remove week from selectedWeekList
            console.log('routeScheduleData before remove week', this.routeScheduleData);
            // start:: remove week data from routeScheduleData
            for (let i = 0; i < this.routeScheduleData.length; i++) {
              if (this.routeScheduleData[i].week_number === weekNo) {
                delete this.routeScheduleData[i];
              }
            }
            this.routeScheduleData = this.routeScheduleData.filter((data: any) => data !== undefined);
            console.log('routeScheduleData after remove week', this.routeScheduleData);
            // end:: remove week data from routeScheduleData

            this.ariaExpanded = 0;
            const selectedDayForRoute = this.routeScheduleData[0];
            this.isStartTimeChange(selectedDayForRoute.start_time, selectedDayForRoute, 'route');
          }
        });
      }
    }
  }
  calculatePolylineDetails(route: any): any {
    if (route.legs?.length > 0) {
      this.allGeoPaths = [];
      for (let leg = 0; leg < route.legs.length; leg++) {
        // calculate distance of single route
        if (route.legs[leg].distance.value) {
          if (this.routeScheduleData[0].route_stops_attributes[leg]) {
            const distance = route.legs[leg].distance.value / 1000;
            this.routeScheduleData[0].route_stops_attributes[leg].distance = parseFloat(distance.toFixed(2));
          }
        }
        const geoPathLatLng = [];
        // geo_path or polyline points calculation
        for (const step of route.legs[leg].steps) {
          for (const path of step.path) {
            const pathObj = {
              lat: path.lat(),
              lng: path.lng(),
            };
            geoPathLatLng.push(pathObj);
            this.allGeoPaths.push(pathObj);
          }
        }
        // console.log('geoPath before stringify:::', geoPathLatLng);
        /*if (this.routeScheduleData[0].route_stops_attributes[leg]) {
          this.routeScheduleData[0].route_stops_attributes[leg].geo_path = JSON.stringify(geoPathLatLng);
        }*/
        // console.log('geoPath after stringify:::', geoPathLatLng);
      }
    }
    // console.log('getting Geo Path schedule data----', this.routeScheduleData);
  }
  changePolyLine(e: any): void {
    console.log('Change Polyline:::::', e);
    if (e) {
      this.spinner.show();
      if (e.routes?.length > 0) {
        this.routeDistance = 0;
        this.routeTime = 0;
        for (let route = 0; route < e.routes[0].legs.length; route++) {
          this.routeDistance += e.routes[0].legs[route].distance.value / 1000; // calculate distance
          this.routeTime += e.routes[0].legs[route].duration.value / 3600; // calculate time
          for (const stopAttr of this.routeScheduleData) {
            if (stopAttr.route_stops_attributes[route]) {
              stopAttr.route_stops_attributes[route].distance = e.routes[0].legs[route].distance.value / 1000; // calculate individual route distance
            }
          }
        }
      }
      this.calculatePolylineDetails(e.routes[0]);
      this.manualRoute = [];
      for (const path of e.request.waypoints) {
        let latitude;
        let longitude;
        if (path.location && path.location.location) {
          latitude = path.location.location.lat();
          longitude = path.location.location.lng();
        } else {
          latitude = path.location.lat();
          longitude = path.location.lng();
        }
        const locationObj = {
          location: {
            lat: latitude,
            lng: longitude,
          },
        };
        this.manualRoute.push(locationObj);
        console.log('this.manualRoute', this.manualRoute);
      }
      this.spinner.hide();
    }
    this.routeDistance = parseFloat(this.routeDistance.toFixed(2));
    // this.routeTime = parseFloat(this.routeTime.toFixed(2));
    const modules = this.routeTime % 1;
    const h = Math.floor(this.routeTime);
    const min = Math.floor(modules * 60);
    this.routeTime = h + ':' + min;

    if (this.isCalculateRouteTime) {
      this.ariaExpanded = 0;
      const selectedDayForRoute = this.routeScheduleData[0];
      this.isStartTimeChange(selectedDayForRoute.start_time, selectedDayForRoute, 'route');
    }
    
  }
  getStatusDirection(status: any): void {
    console.log('Directions Request Status---', status);
    if (status === 'ZERO_RESULTS') {
      this.Toast.fire({
        icon: 'warning',
        title: 'Directions request returned no results.',
      });
    }
  }
  openAiSuggestModal(): void {
    $('#aiSuggestModal').modal('toggle');
  }
  aiSuggest(): void {
    this.spinner.show();
    if (this.selectedSupplyChain.id) {
      this.aiSuggestService.aiSuggest(this.selectedSupplyChain.id, this.selectedSupplyChain.name)
        .subscribe({
          next: (res: any) => {
            if (res.message === 'OK') {
              this.spinner.hide();
              // there were no suggestions, there is no new model, don't redirect.
              if (this.selectedSupplyChain.id !== res.new_model_id) {
                this.router.navigate([`/supplyChain/${res.new_model_id}`]);
              }
              this.aiSuggestions = res && res.additional_info
                ? res.additional_info.replace(/\*\*Recommended action:\*\*--/g, '<br/><br/><b>Recommended action:</b><br/>')
                : 'There was no addtional information from the AI service.';
              this.aiSuggestions += res.error_info
                ? `<strong>Error:</strong><br/><hr/>${res.error_info}`
                : '';
              this.openAiSuggestModal();
              this.Toast.fire({
                showCancelButton: true,
                cancelButtonText: 'Great!',
                timer: 60000,
                icon: 'success',
                title: 'The AI service has successfully suggested and loaded a new supply chain.',
              });
              this.cdr.detectChanges();
            } // TODO: add error handling from response 'error_info'; is in response json just needs to be added. 
          },
          error: (err: any) => {
            console.error('Error retrieving AI suggestion:', err);
          },
        });
    } else {
      this.spinner.hide();
      this.Toast.fire({
        icon: 'warning',
        title: 'No supply chain.',
      });
    }
  }
  goToSimulation(): void {
    setTimeout((): any => {
      if (this.supplyFacility?.length > 0) {
        if (this.selectedSupplyChain.id) {
          this.router.navigate([]).then(result => {
            window.open(`/simulation/${this.selectedSupplyChain.id}`, '_blank');
          });
        }
      } else {
        this.Toast.fire({
          icon: 'warning',
          title: 'Facility not created for the simulation.',
        });
      }
    }, 500);
  }

  getSupplyChainByID(id: number): Observable<SupplyChainDto> {
    return this.supplyChainService.getSupplyChainByID(id).pipe(
      catchError(error => {
        if (error.status !== 200) {
          this.Toast.fire({
            icon: 'warning',
            title: 'Supply chain not found',
          });

          this.router.navigate(['/dashboard']);
        }
        return throwError(() => new Error('Failed to fetch supply chain'));
      }),
      map((res: SupplyChain) => res.supply_chain)
    );
  }

  handleApplyToWeek(data: FacilityScheduleData, clickType: string): void {
    if (this.applyToWeek && clickType === 'week') {
      return;
    }
    const currentWeekNumber = data.week_number;

    if (this.applyToWeek) {
      this.facilityScheduleData = this.facilityScheduleData.map((schedule: FacilityScheduleData) => {
        const key = `${schedule.week_number}-${schedule.day_of_week}`;

        if (this.previousFacilityScheduleData[key]) {
          return JSON.parse(JSON.stringify(this.previousFacilityScheduleData[key]));
        }

        return schedule;
      });
    } else {
      this.facilityScheduleData = this.facilityScheduleData.map((schedule: FacilityScheduleData) => {
        if (schedule.week_number === currentWeekNumber) {
          const backupKey = `${schedule.week_number}-${schedule.day_of_week}`;

          this.previousFacilityScheduleData[backupKey] = JSON.parse(JSON.stringify(schedule));

          if (schedule.day_of_week !== 1) {
            return { ...schedule, ...data, day_of_week: schedule.day_of_week };
          }
        }
        return schedule;
      });
    }

    this.applyToWeek = !this.applyToWeek;
  }

  getApplyToWeekText(): string {
    return this.applyToWeek ? 'Undo' : 'Apply to Week';
  }

  encodePath(arrays: any): any {
    return google.maps.geometry.encoding.encodePath(arrays);
  }

  setMinutesToDuration(duration: string, minutesToAdd: number): string {
    const [hours, minutes] = duration.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes + minutesToAdd;
    let resultHours = Math.floor(totalMinutes / 60);
    let resultMinutes = totalMinutes % 60;
    if (resultMinutes < 0) {
      resultMinutes += 60;
      resultHours -= 1;
    }
    if (resultHours < 0) {
      resultHours += 24;
    }
    const formattedHours = resultHours.toString().padStart(2, '0');
    const formattedMinutes = resultMinutes.toString().padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}`;
  }

  calculateEndTimeFromRouteDuration(startDay: number, startTime: string, durationToAdd: string, weekNumber: number): any {
    const daysOfWeek = [1, 2, 3, 4, 5, 6, 7];
    const startDayIndex = daysOfWeek.indexOf(startDay);
    const [startHours, startMinutes] = startTime.split(':').map(Number);

    const [addHours, addMinutes] = durationToAdd.split(':').map(Number);

    const totalStartMinutes = startDayIndex * 24 * 60 + startHours * 60 + startMinutes;
    const totalAddMinutes = addHours * 60 + addMinutes;

    let totalEndMinutes = totalStartMinutes + totalAddMinutes;

    const endDayIndex = Math.floor(totalEndMinutes / (24 * 60)) % 7;
    const endDayWeek = Math.floor(Math.floor(totalEndMinutes / (24 * 60)) / 7);
    totalEndMinutes = totalEndMinutes % (24 * 60);
    const endHours = Math.floor(totalEndMinutes / 60);
    const endMinutes = totalEndMinutes % 60;

    const formattedEndHours = endHours.toString().padStart(2, '0');
    const formattedEndMinutes = endMinutes.toString().padStart(2, '0');
    const endDay = daysOfWeek[endDayIndex];

    return { endTime: `${formattedEndHours}:${formattedEndMinutes}`, endDayOfWeek: endDay, endWeek: endDayWeek + weekNumber };
  }

  getCommaSepratedValue(value: number): string {
    return value === 0 ? value.toFixed(2) : parseFloat(value.toFixed(2)).toLocaleString('en-US');
  }

  highlightWeek(weekNo: number): string {
    if (this.selectedWeekList.indexOf(this.selectedWeek) === -1) {
      this.selectedWeek = 1;
    }
    if (this.selectedWeek === weekNo) {
      return 'active';
    } else {
      return '';
    }
  }
}
