/**
 * Removes everything after the first occurrence of a question mark in a URL.
 * If no question mark is present, returns the original URL.
 *
 * @param url - The original URL.
 * @returns The URL without anything after the first question mark.
 */
export const removeQueryString = (url: string): string => {
  const questionMarkIndex = url.indexOf('?');
  return questionMarkIndex !== -1 ? url.substring(0, questionMarkIndex) : url;
};
