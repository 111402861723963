import { OpenAddUserModalType, ValidationResult } from 'src/types/components/users.types';
import { RoleName } from 'src/types/dtos/user.types';
import { UserPayload } from 'src/types/services/users-service.types';

/**
 * Gets the expiration date for an entity unless no expiration is indicated.
 *
 * @param noExpirationDate - Indicates whether the entity should not expire.
 * @param expirationDate - The proposed expiration date, or null if unspecified.
 * @returns A formatted expiration date as a string, or null if no expiration.
 */
export function getExpirationDate(noExpirationDate: boolean, expirationDate: null | Date): null | string {
  if (!noExpirationDate && !!expirationDate) {
    return formatDate(expirationDate);
  }

  return null;
}

/**
 * Formats a date object into a string in the ISO format YYYY-MM-DD.
 *
 * @param date - The date to format.
 * @returns The formatted date.
 */
function formatDate(date: Date): string {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
}

/**
 * Validates the data provided for creating or editing a user against specified criteria.
 *
 * @param payload - The user creation data payload.
 * @param AddOrEditUser - The action to add or edit a user.
 * @returns The result of the validation, indicating success or failure and an accompanying message.
 */
export function validateUserData(payload: UserPayload, addOrEditUser: OpenAddUserModalType): ValidationResult {
  const user = payload.user;

  if (!user.email.trim()) {
    return {
      success: false,
      message: 'Email is required and cannot be empty.',
    };
  }

  if (addOrEditUser === 'add' && (!user.password || user.password.length < 8)) {
    return {
      success: false,
      message: 'Password must be at least 8 characters long.',
    };
  }

  if (!Object.values(RoleName).includes(user.role)) {
    return {
      success: false,
      message: 'Role must be one of the following: Admin, Professor, User.',
    };
  }

  if (!user.no_expiration && !user.expiration_date) {
    return {
      success: false,
      message: 'Expiration date is required.',
    };
  }

  return {
    success: true,
    message: null,
  };
}
