<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="announce">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="announcementCls">
          <h4> Announcements
            <a (click)="openAddAnnouncementModal('add')" class="btn btn-primary addButton">
              <i class="fa fa-plus" aria-hidden="true"></i>
              Add New Announcement
            </a>
          </h4>
          <ngx-table #announcementTable [configuration]="announcementConfig"
                     [data]="announcementList"
                     [columns]="announcementColumns"
                     (event)="onEvent($event)">
          </ngx-table>

          <ng-template #announcementActionTpl let-row let-rowIndex="rowIndex">
            <div class="btn btn-sm btn-outline-danger waves-effect waves-light pull-right" (click)="deleteAnnouncement(rowIndex)">Delete</div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light pull-right" (click)="openAddAnnouncementModal('edit')">Edit</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Add/Update announcement modal start -->
<div bsModal id="announcementAddModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-md">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-sizes-name2" class="modal-title pull-left">
          <span *ngIf="modalTypeFn === 'add'">Add New </span>
          <span *ngIf="modalTypeFn === 'edit'">Edit </span>
          <span> Announcement</span>
        </h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="closeAnnouncementModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Content<span class="required">*</span></label>
          <textarea class="form-control" [(ngModel)]="content" rows="5" placeholder="Enter here..."></textarea>
        </div>
        <div class="form-group form-check">
          <input type="checkbox" [(ngModel)]="status" class="form-check-input">
          <label class="form-check-label">Active</label>
        </div>
        <button [disabled]="content === ''" (click)="addEditAnnouncement()" type="button" class="btn btn-success">
          <span  *ngIf="modalTypeFn === 'add'">Save</span>
          <span *ngIf="modalTypeFn === 'edit'">Update</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add/Update announcement modal end -->
