import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {AuthService} from '../../services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.css']
})
export class ResetpasswordComponent implements OnInit {
  resetForm: FormGroup | any;
  password: any;
  confPassword: any;
  error: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  submitted = false;
  resetPassToken = '';
  private sub: any;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private auth: AuthService,
    private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.resetForm = this.fb.group({
      password: [null, Validators.required],
      confirmPassword: [null, Validators.required],
      validate: ''
    });
    this.sub = this.activatedRoute.params.subscribe(params => {
      if (params && params.id) {
        console.log(params.id);
        this.resetPassToken = params.id;
      }
    });
  }
  // convenience getter for easy access to form fields
  get f(): any { return this.resetForm.controls; }
  onSubmit(): any {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    if (this.password !== this.confPassword) {
      this.Toast.fire({
        icon: 'error',
        title: 'Password and confirm password not matched.'
      });
      return false;
    }
    if (this.password === undefined) {
      return false;
    } else {
      const payload = {
        reset_password_token: this.resetPassToken,
        password: this.password,
        password_confirmation: this.password
      };
      this.spinner.show();
      this.authService.resetPassService(payload).subscribe((data: any) => {
        this.spinner.hide();
        if (data && !data.errors) {
          this.Toast.fire({
            icon: 'success',
            title: 'Password reset successfully.'
          });
          this.router.navigate(['/login']);
        } else {
          console.log('errors');
          this.Toast.fire({
            icon: 'error',
            title: data.errors
          });
        }
      });
    }
  }

}
