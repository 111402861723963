import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Links } from '../links.module';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  constructor(
    private http: HttpClient
  ) { }
  getProductListById(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/products')
      .pipe(map((res: any) =>  res));
  }
  addUpdateProductById(payload: any, payId: any): any {
    if (payId.update === false) {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/products';
      return this.http.post(url, payload)
        .pipe(map((res: any) => res));
    } else {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/products' + '/' + payId.id;
      return this.http.put(url, payload)
        .pipe(map((res: any) => res));
    }
  }
  deleteProductService(payload: any): any {
    const url = Links.supplyChainUrl + '/' + payload.supplyId + '/products' + '/' + payload.id;
    return this.http.delete(url)
      .pipe(map((res: any) => res));
  }
  getFacilityTypesService(): any {
    return this.http.get(Links.facilityTypes)
      .pipe(map((res: any) =>  res));
  }
  addUpdateFacilityService(payload: any, payId: any): any {
    if (payId.update === false) {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/facilities';
      return this.http.post(url, payload)
        .pipe(map((res: any) => res));
    } else {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/facilities' + '/' + payId.id;
      return this.http.put(url, payload)
        .pipe(map((res: any) => res));
    }
  }
  getFacilityListById(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities')
      .pipe(map((res: any) =>  res));
  }
  getFacilityDataById(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities/' + payload.facility_id)
      .pipe(map((res: any) =>  res));
  }
  deleteFacilityService(payload: any): any {
    const url = Links.supplyChainUrl + '/' + payload.supplyId + '/facilities' + '/' + payload.id;
    return this.http.delete(url)
      .pipe(map((res: any) => res));
  }
  getVehicleTypesService(): any {
    return this.http.get(Links.vehicleTypes)
      .pipe(map((res: any) =>  res));
  }
  addUpdateVehicleService(payload: any, payId: any): any {
    if (payId.update === false) {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/facilities/' + payId.facility_id + '/vehicles';
      return this.http.post(url, payload)
        .pipe(map((res: any) => res));
    } else {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/facilities/' + payId.facility_id + '/vehicles/' + payId.id;
      return this.http.put(url, payload)
        .pipe(map((res: any) => res));
    }
  }
  getFacilityVehicleListById(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities/' + payload.facility_id + '/vehicles')
      .pipe(map((res: any) =>  res));
  }
  getFacilityVehicleDataById(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities/' + payload.facility_id + '/vehicles/' + payload.vehicle_id)
      .pipe(map((res: any) =>  res));
  }
  deleteFacilityVehicleService(payload: any): any {
    const url = Links.supplyChainUrl + '/' + payload.supplyId + '/facilities' + '/' + payload.facility_id + '/vehicles/' + payload.vehicle_id;
    return this.http.delete(url)
      .pipe(map((res: any) => res));
  }
  addUpdateRouteService(payload: any, payId: any): any {
    if (payId.update === false) {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/facilities/' + payId.facility_id + '/vehicles/' + payId.vehicle_id + '/routes';
      return this.http.post(url, payload)
        .pipe(map((res: any) => res));
    } else {
      const url = Links.supplyChainUrl + '/' + payId.supply_chain_id + '/facilities/' + payId.facility_id + '/vehicles/' + payId.vehicle_id + '/routes/' + payId.id;
      return this.http.put(url, payload)
        .pipe(map((res: any) => res));
    }
  }
  getVehicleRoutesList(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities/' + payload.facility_id + '/vehicles/' + payload.vehicle_id + '/routes')
      .pipe(map((res: any) =>  res));
  }
  getRouteDataById(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities/' + payload.facility_id + '/vehicles/' + payload.vehicle_id + '/routes/' + payload.route_id)
      .pipe(map((res: any) =>  res));
  }
  deleteVehicleRouteService(payload: any): any {
    const url = Links.supplyChainUrl + '/' + payload.supplyId + '/facilities' + '/' + payload.facility_id + '/vehicles/' + payload.vehicle_id + '/routes/' + payload.route_id;
    return this.http.delete(url)
      .pipe(map((res: any) => res));
  }
  facilityBeforeDeleteData(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities/' + payload.facility_id + '/before_delete')
      .pipe(map((res: any) =>  res));
  }
  vehicleBeforeDeleteData(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities/' + payload.facility_id + '/vehicles/' + payload.vehicle_id + '/before_delete')
      .pipe(map((res: any) =>  res));
  }
  faciTypeAddUpdateService(formData: any, type: any, id: any): any {
    if (type === 'add') {
      return this.http.post<any>(Links.facilityTypes, formData)
        .pipe(map((res: any) => res));
    } else {
      return this.http.put<any>(Links.facilityTypes + '/' + id, formData)
        .pipe(map((res: any) => res));
    }
  }
  vehicleTypeAddUpdateService(formData: any, type: any, id: any): any {
    if (type === 'add') {
      return this.http.post<any>(Links.vehicleTypes, formData)
        .pipe(map((res: any) => res));
    } else {
      return this.http.put<any>(Links.vehicleTypes + '/' + id, formData)
        .pipe(map((res: any) => res));
    }
  }
  deleteTypeService(payload: any): any {
    let url = '';
    if (payload.typeof === 'facility') {
      url = Links.facilityTypes + '/' + payload.id;
    } else {
      url = Links.vehicleTypes + '/' + payload.id;
    }
    return this.http.delete(url)
      .pipe(map((res: any) => res));
  }
  addUpdateTimeIntervalService(payload: any, type: any, id: any): any {
    if (type === 'add') {
      return this.http.post<any>(Links.timeInverval, payload)
        .pipe(map((res: any) => res));
    } else {
      return this.http.put<any>(Links.timeInverval + '/' + id, payload)
        .pipe(map((res: any) => res));
    }
  }
  getTimeIntervalDataService(): any {
    return this.http.get(Links.timeInverval)
      .pipe(map((res: any) => res));
  }
  deleteIntervalService(id: any): any {
    const url = Links.timeInverval + '/' + id;
    return this.http.delete(url)
      .pipe(map((res: any) => res));
  }
  getSimulationDataById(payload: any): any {
    return this.http.get(Links.simulationUrl + '?simulation_id=' + payload.simulationId + '&section=' + payload.section)
      .pipe(map((res: any) =>  res));
  }
  createSimulationDataById(payload: any): any {
    return this.http.post<any>(Links.simulationUrl, payload)
      .pipe(map((res: any) => res));
  }
  getFacilityList(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/' + payload.supply_chain_id + '/facilities')
      .pipe(map((res: any) =>  res));
  }
  downloadSimulationReport(formData: any): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    let body = new HttpParams();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        body = body.set(key, formData[key]);
      }
    }
  
    return this.http.post(Links.simulationUrl + '/export', body.toString(), { headers, responseType: 'blob' })
      .pipe(map((res: Blob) => res));
  }

  downloadPNLReport(formData: any): any {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
  
    let body = new HttpParams();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        body = body.set(key, formData[key]);
      }
    }
  
    return this.http.post(Links.simulationUrl + '/generate_report', body.toString(), { headers, responseType: 'blob' })
      .pipe(map((res: Blob) => res));
  }
}
