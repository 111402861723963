import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import { Links } from '../links.module';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(
    private http: HttpClient
  ) { }

  getSupplyChainList(): any {
    return this.http.get(Links.supplyChainUrl)
      .pipe(map((res: any) => res));
  }
  saveSupplyChainService(payload: any): any {
    return this.http.post(Links.supplyChainUrl, payload, {})
      .pipe(map((res: any) =>  res));
  }
  updateSupplyChainService(payload: any): any {
    const obj = {
      name: payload.name,
      is_library: payload.is_library || false
    };
    return this.http.put(Links.supplyChainUrl + '/' + payload.id, obj, {})
      .pipe(map((res: any) =>  res));
  }
  deleteSupplyChainService(payload: any): any {
    const deleteLink = Links.supplyChainUrl + '/' + payload.id;
    return this.http.delete(deleteLink)
      .pipe(map((res: any) =>  res));
  }
  getSupplyChainHistory(): any {
    return this.http.get(Links.supplyChainHistoryUrl)
      .pipe(map((res: any) => res));
  }
  addSupplyHistoryService(payload: any): any {
    return this.http.post(Links.supplyChainHistoryUrl, payload, {})
      .pipe(map((res: any) =>  res));
  }
  deleteSupplyHistoryService(payload: any): any {
    const deleteLink = Links.supplyChainHistoryUrl + '/' + payload.id;
    return this.http.delete(deleteLink)
      .pipe(map((res: any) =>  res));
  }
  restoreSupplyHistoryService(payload: any): any {
    const restoreLink = Links.supplyChainHistoryUrl + '/' + payload.id + '/restore';
    return this.http.get(restoreLink)
      .pipe(map((res: any) =>  res));
  }
  exportSupplyChainService(payload: any): any {
    return this.http.get(Links.supplyChainUrl + '/export?id=' + payload.ids)
      .pipe(map((res: any) => res));
  }
  sendFormData(formData: any): any {
    return this.http.post<any>(Links.supplyChainUrl + '/upload', formData)
      .pipe(map((res: any) => res));
  }
  downloadSupplyHistoryService(payload: any): any {
    const dwnLink = Links.supplyChainHistoryUrl + '/download/?id=' + payload.id;
    return this.http.get(dwnLink)
      .pipe(map((res: any) =>  res));
  }
  uploadStateService(formData: any): any {
    return this.http.post<any>(Links.supplyChainHistoryUrl + '/upload', formData)
      .pipe(map((res: any) => res));
  }
  /*updateSupplyChainService(payload: any): any {
    return this.http.post(Links.supplyChainUrl + '/' + payload.id, payload, {})
      .pipe(map((res: any) =>  res));
  }*/
  getLibraryListService(): any {
    return this.http.get(Links.libraryUrl)
      .pipe(map((res: any) => res));
  }
  deleteLibraryService(payload: any): any {
    const deleteLink = Links.supplyChainUrl + '/' + payload.id + '/delete_library';
    return this.http.delete(deleteLink)
      .pipe(map((res: any) =>  res));
  }
  importLibraryService(payload: any): any {
    return this.http.post(Links.supplyChainUrl + '/' + payload.id + '/library_import', payload, {})
      .pipe(map((res: any) =>  res));
  }
  getTimeIntervalDataService(): any {
    return this.http.get(Links.timeInverval)
      .pipe(map((res: any) => res));
  }
  saveSupportTicketService(payload: any): any {
    return this.http.post(Links.ticketsUrl, payload, {})
      .pipe(map((res: any) =>  res));
  }
  getTicketListService(): any {
    return this.http.get(Links.ticketsUrl)
      .pipe(map((res: any) => res));
  }
  getTicketDetailService(payload: any): any {
    return this.http.get(Links.ticketsUrl + '/' + payload.id)
      .pipe(map((res: any) => res));
  }
  saveTicketReplyService(payload: any): any {
    return this.http.post(Links.ticketsUrl + '/' + payload.id + '/ticket_comment', payload, {})
      .pipe(map((res: any) =>  res));
  }
}
