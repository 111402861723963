import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AnnouncementComponent } from './components/announcement/announcement.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FacilityvehicletypeComponent } from './components/facilityvehicletype/facilityvehicletype.component';
import { ForgotpasswordComponent } from './components/forgotpassword/forgotpassword.component';
import { LibraryComponent } from './components/library/library.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { ResetpasswordComponent } from './components/resetpassword/resetpassword.component';
import { SimulationComponent } from './components/simulation/simulation.component';
import { SupplychainComponent } from './components/supplychain/supplychain.component';
import { SupportticketsComponent } from './components/supporttickets/supporttickets.component';
import { TimeintervalComponent } from './components/timeinterval/timeinterval.component';
import { UsersComponent } from './components/users/users.component';
import { ViewticketComponent } from './components/viewticket/viewticket.component';
import { AuthGuard } from './guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgotpassword', component: ForgotpasswordComponent },
  { path: 'resetpassword/:id', component: ResetpasswordComponent },
  { path: 'simulation', component: SimulationComponent, canActivate: [AuthGuard] },
  { path: 'supplyChain/:id', component: SupplychainComponent, canActivate: [AuthGuard] },
  { path: 'facilityvehicletype', component: FacilityvehicletypeComponent, canActivate: [AuthGuard] },
  { path: 'library', component: LibraryComponent, canActivate: [AuthGuard] },
  { path: 'simulation/:id', component: SimulationComponent, canActivate: [AuthGuard] },
  { path: 'timeinterval', component: TimeintervalComponent, canActivate: [AuthGuard] },
  { path: 'supporttickets', component: SupportticketsComponent, canActivate: [AuthGuard] },
  { path: 'viewticket/:id', component: ViewticketComponent, canActivate: [AuthGuard] },
  { path: 'users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'announcement', component: AnnouncementComponent, canActivate: [AuthGuard] },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
