import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {APIDefinition, Columns, Config, DefaultConfig} from 'ngx-easy-table';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {DashboardService} from '../../services/dashboard.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-supporttickets',
  templateUrl: './supporttickets.component.html',
  styleUrls: ['./supporttickets.component.css']
})
export class SupportticketsComponent implements OnInit {
  @ViewChild('tickettable', { static: true }) private tickettable!: APIDefinition;
  @ViewChild('actionTpl', { static: true }) actionTpl!: TemplateRef<any>;
  public ticketConfig: Config | any;
  public ticketColumns: Columns[] | any;
  ticketData: any = [];
  selectedRowData: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });

  constructor(
    private spinner: NgxSpinnerService,
    private dashService: DashboardService,
    private cdr: ChangeDetectorRef,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.ticketConfig = { ...DefaultConfig };
    this.ticketColumns = [
      { key: 'user_email', title: 'Email' },
      { key: 'status', title: 'Status' },
      { key: 'action', title: 'Action', cellTemplate: this.actionTpl },
    ];
    this.getTicketData();
  }
  getTicketData(): any {
    this.spinner.show();
    this.dashService.getTicketListService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.ticketData = res.tickets;
      }
    });
  }
  onEvent(event: { event: string; value: any }): void {
    this.selectedRowData = event.value.row;
  }
  viewTicket(): any {
    setTimeout((): any => {
      if (this.selectedRowData) {
          if (this.selectedRowData.id) {
            console.log('this.selectedRowData.id --- ', this.selectedRowData.id);
            this.router.navigate([]).then(result => { window.open(`/viewticket/${this.selectedRowData.id}`, '_self'); });
          }
        /*const payload = {
          id: this.selectedRowData.id
        };
        this.spinner.show();
        this.dashService.getTicketDetailService(payload).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.ticketDetailData = res.tickets;
          }
        });*/
      }
    }, 300);
  }
}
