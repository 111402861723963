export interface UserDto {
  id: number;
  email: string;
  full_name: string;
  active: boolean;
  reset_password_token_expires_at: null | string;
  authentication_token: string;
  no_expiration: boolean;
  expiration_date: null | string;
  password: null | string;
  password_confirmation: null | string;
  created_at: string;
  updated_at: string;
  role: RoleName;
}

export enum RoleName {
  Admin = 'admin',
  Professor = 'professor',
  User = 'user',
}
