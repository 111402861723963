<div class="application-header-container">
  <div class='wrap'>
    <div class="logo-wrapper">
      <img src="assets/images/logo-black-text.png" alt="scmGlobe logo" /> <span class="selected-page">{{selectedPage}}</span>
    </div>
    <div class="navigation-links-wrapper">
      <div *ngIf="showInternalItems" class="navigation-item">
        <a href="/dashboard" [ngClass]="{'active': activeLink === '/dashboard'}">Account Management</a>
      </div>
      <div *ngIf="showInternalItems" class="navigation-item">
        <a href="/library" [ngClass]="{'active': activeLink === '/library'}">Library</a>
      </div>
      <div *ngIf="showInternalItems" id="settings-dropdown" class="navigation-item-dropdown" (click)="toggleDropdown()">
        <span class="dropdown-parent">Settings <img src="assets/vectors/arrow-{{isOpen ? 'up' : 'down'}}.svg" /></span>
        <ul class="dropdown-menu" *ngIf="isOpen">
          <li *ngIf="showAdminItems" class="dropdown-item-wrapper">
            <a href="/users" [ngClass]="{'active': activeLink === '/users'}">
              <div class="icon-wrapper">
                <img src="assets/vectors/manage-users.svg" />
              </div>
              <span class='dropdown-item-internal'>Manage Users</span>
            </a>
          </li>
          <li class="dropdown-item-wrapper">
            <a href="/supporttickets" [ngClass]="{'active': activeLink === '/supporttickets'}">
              <div class="icon-wrapper">
                <img src="assets/vectors/ticket.svg" />
              </div>
              <span class='dropdown-item-internal'>Support Tickets</span>
            </a>
          </li>
          <li *ngIf="showAdminItems" class="dropdown-item-wrapper">
            <a href="/timeinterval" [ngClass]="{'active': activeLink === '/timeinterval'}">
              <div class="icon-wrapper">
                <img src="assets/vectors/clock.svg" />
              </div>
              <span class='dropdown-item-internal'>Time Intervals</span>
            </a>
          </li>
          <li *ngIf="showAdminItems" class="dropdown-item-wrapper">
            <a href="/facilityvehicletype" [ngClass]="{'active': activeLink === '/facilityvehicletype'}">
              <div class="icon-wrapper">
                <img src="assets/vectors/paintbrush.svg" />
              </div>
              <span class='dropdown-item-internal'>Icons Manager</span>
            </a>
          </li>
          <hr />
          <li *ngIf="showAdminItems" class="dropdown-item-wrapper">
            <a href="#">
              <div class="icon-wrapper">
                <img src="assets/vectors/cycle.svg" />
              </div>
              <span class='dropdown-item-internal'>Scrub Orphan</span>
            </a>
          </li>
          <li *ngIf="showAdminItems" class="dropdown-item-wrapper">
            <a href="#">
              <div class="icon-wrapper">
                <img src="assets/vectors/db-account.svg" />
              </div>
              <span class='dropdown-item-internal'>Clean 1 Year Old Expired Account</span>
            </a>
          </li>
          <li *ngIf="showAdminItems" class="dropdown-item-wrapper">
            <a href="/announcement" [ngClass]="{'active': activeLink === '/announcement'}">
              <div class="icon-wrapper">
                <img src="assets/vectors/announcement.svg" />
              </div>
              <span class='dropdown-item-internal'>Announcements</span>
            </a>
          </li>
          <li class="dropdown-item-wrapper">
            <a href="https://www.scmglobe.com/online-guide/" target="_blank">
              <div class="icon-wrapper">
                <img src="assets/vectors/help.svg" />
              </div>
              <span class='dropdown-item-internal'>Help</span>
            </a>
          </li>
          <hr />
          <li class="dropdown-item-wrapper" (click)="handleLogout()">
            <span class="dropdown-item-action">
              <div class="icon-wrapper">
                <img src="assets/vectors/logout.svg" />
              </div>
              <span class='dropdown-item-internal'>Logout</span>
            </span>
          </li>
        </ul>
      </div>

      <!-- Public links -->

      <div *ngIf="showPublicItems" class="navigation-item">
        <a href="https://www.scmglobe.com/scm-globe-services/" target='_blank'>Services</a>
      </div>
      <div *ngIf="showPublicItems" class="navigation-item">
        <a href="https://www.scmglobe.com/?page_id=1485" target='_blank'>Blog</a>
      </div>
      <div *ngIf="showPublicItems" class="navigation-item">
        <a href="https://www.scmglobe.com/scm-globe-pricing/" target='_blank'>Pricing</a>
      </div>
      <div *ngIf="showPublicItems" class="navigation-item">
        <a href="https://www.scmglobe.com/contact-us/" target='_blank'>Contact Us</a>
      </div>
    </div>
  </div>
</div>