import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../services/authentication.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup | any;
  email: any;
  password: any;
  error: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  submitted = false;
  constructor(
    public router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private auth: AuthService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {

    if (this.auth.isLoggedIn()) {
      this.router.navigate(['/dashboard']);
      // console.log(this.auth.isLoggedIn());
    }

    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, Validators.required],
      validate: ''
    });
  }
  // convenience getter for easy access to form fields
  get f(): any { return this.loginForm.controls; }

  onLoginSubmit(): any {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    if (this.email === undefined) {
      return false;
    } else if (this.password === undefined) {
      return false;
    } else {
      const payload = {
        user: {
          email: this.email,
          password: this.password,
        }
      };
      this.spinner.show();
      this.authService.loginService(payload).subscribe((data: any) => {
        this.spinner.hide();
        if (data && !data.errors) {
          // const DT = moment(new Date()).format('DD-MMM-YYYY HH:mm');
          if (data.user.role) {
            localStorage.setItem('userRole', data.user.role);
          } else {
            localStorage.setItem('userRole', '');
          }
          // this.authService.storeUserData(data);
          this.auth.sendToken(data.user);
          this.router.navigate(['/dashboard']);
        } else {
          console.log('errors');
          this.Toast.fire({
            icon: 'error',
            title: data.errors
          });
        }
      }, (err: HttpErrorResponse) => {
        this.Toast.fire({
          icon: 'error',
          title: err?.error?.errors
        });
      });
    }
  }

}
