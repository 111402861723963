<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="dashboard container-custom">
  <div class="header2">
    <h4 class="f-normal">Account Management</h4>
  </div>
  <div class="row">
    <div class="col-10">
      <div class="supplyChain">
        <h4 class="f-normal">Supply Chains <span>Click the "Help" button in the upper right of any screen for the <a href="https://www.scmglobe.com/online-guide/" target="_blank"> Online User Guide</a></span></h4>
        <div class="supplyTable">

          <div class="row">
            <!--<div class="col-6">
              <button (click)="openSupplyChainModal()" class="btn btn-primary" style="margin-top: 10px;margin-left: 5px;">Create a New Supply Chain</button>
            </div>-->
            <div class="column col-6">
              <form class="form-horizontal">
                <div class="form-group">
                  <div class="col-3 col-sm-12" id="globalSearchSupplyChain">
                    <input
                      class="form-control"
                      type="text"
                      (input)="onChangeSupplyChain($event)"
                      placeholder="Global Search"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="column col-6">
              <div class="text-right top-btns">
                <a (click)="openSupplyChainModal()" class="btn btn-primary">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                  Create New
                </a>
                <a (click)="importSchainModal()" class="btn btn-primary">
                  <i class="fa fa-upload" aria-hidden="true"></i>
                  Import
                </a>
                <a (click)="exportSupplyChain()" class="btn btn-primary">
                  <i class="fa fa-download" aria-hidden="true"></i>
                  Export
                </a>
              </div>
            </div>
          </div>

          <ngx-table #table [configuration]="configuration1"
                     [data]="data"
                     [columns]="columns"
                     (event)="eventEmitted($event)">
          </ngx-table>
          
          <ng-template #actionTpl let-row let-rowIndex="rowIndex">
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="editSupplyChain(rowIndex)">Edit</div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="goToSimulation()">Simulate</div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="openSupplyHistoryModal()" >Save</div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="deleteSupplyChain(rowIndex)">Delete</div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" *ngIf="isAdmin()" (click)="addToLibrary()">Add to library</div>
          </ng-template>

        </div>
      </div>
      <div class="supplyChain">
        <h4 class="f-normal">Save States <span>Save States are a way to store supply chains for sharing and back up. If you mess up you can always import a previous save state and start again.</span></h4>
        <div class="supplyTable">

          <div class="row">
            <div class="column col-6">
              <form class="form-horizontal">
                <div class="form-group">
                  <div class="col-3 col-sm-12" id="globalSearch">
                    <input
                      class="form-control"
                      type="text"
                      (input)="onChange($event)"
                      placeholder="Global Search"
                    />
                  </div>
                </div>
              </form>
            </div>
            <div class="column col-6">
              <div class="text-right top-btns upload-btn-wrapper">
                <button class="btn btn-outline-primary">
                  <i class="fa fa-upload" aria-hidden="true"></i> Upload save file
                </button>
                <input (change)="uploadFile($event)" type="file" name="fileUpload" />
              </div>
            </div>
          </div>

          <ngx-table #tableHistory [configuration]="configuration2"
                     [data]="supplyChainHistory"
                     [columns]="columnsHistory"
                     (event)="historyEventEmitted($event)">
          </ngx-table>

          <ng-template #actionTplHistory let-row let-rowIndex="rowIndex">
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="restoreSupplyChain(rowIndex)">Restore</div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="downloadHistory(rowIndex)">Download Save State</div>
            <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="deleteSupplyChainHistory(rowIndex)">Delete</div>
          </ng-template>

        </div>
      </div>
    </div>
    <div class="col-2">
      <div class="helpLinks">
        <h4 class="f-normal">Helpful Links</h4>
        <p><a href="http://blog.scmglobe.com/?page_id=257" target="_blank">Getting Started</a></p>
        <p><a href="http://blog.scmglobe.com/?page_id=241" target="_blank">Video Quick Start</a></p>
        <p><a href="http://blog.scmglobe.com/?page_id=163" target="_blank">Case Studies</a></p>
        <p><a href="http://blog.scmglobe.com/?page_id=163" target="_blank">FAQ</a></p>
        <!--<hr>
        <p>Account Expires On: 2022-03-15</p>
        <button class="btn btn-outline-primary">Purchase More Time</button>-->
        <hr>
        <h4 class="f-normal">Support Ticket</h4>
        <p><a [routerLink]="['/supporttickets']">Open and View Tickets</a></p>
      </div>
    </div>
  </div>
</div>

<!-- Create supply chain modal start -->
<div bsModal id="supplyChainModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 id="dialog-sizes-name2" class="modal-title pull-left">Create New Supply Chain</h5>
        <button type="button" class="close pull-right" aria-label="Close" id="supplyChainCloseBtn" (click)="openSupplyChainModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Name<span class="required">*</span></label>
          <input type="text" [(ngModel)]="supplyChainName" placeholder="Enter here" class="form-control">
          <small>NOTE: Name cannot be less than 4 characters</small>
        </div>
        <button [disabled]="supplyChainName.length <= 3" (click)="createSupplyChain()" type="button" class="btn btn-success">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Create supply chain modal end -->
<!-- Create supply history modal start -->
<div bsModal id="supplyHistoryModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Add Supply Chain History</h5>
        <button type="button" class="close pull-right" aria-label="Close" id="supplyHistoryCloseBtn" (click)="openSupplyHistoryModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label>Name<span class="required">*</span></label>
          <input type="text" [(ngModel)]="supplyHistoryName" placeholder="Enter here" class="form-control">
        </div>
        <button [disabled]="supplyHistoryName.length <= 3" (click)="addSupplyHistory()" type="button" class="btn btn-success">Save</button>
      </div>
    </div>
  </div>
</div>

<!-- Create supply history modal end -->

<!-- import modal start -->
<div bsModal id="importSupplyChainModal" data-backdrop="static" data-keyboard="false" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="dialog-sizes-name2">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title pull-left">Import Supply Chain</h5>
        <button type="button" class="close pull-right" aria-label="Close" (click)="importSchainModal()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div>
          <p>Please import 4 files SupplyChain, Product, Facility and Vehicle in csv format.</p>
          <input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="text/csv" />
        </div>
        <br>
        <button (click)="importScFn()" type="button" class="btn btn-success">Upload Files</button>
      </div>
    </div>
  </div>
</div>

<!-- import modal end -->
