import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {APIDefinition, Columns, Config, DefaultConfig} from 'ngx-easy-table';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {AdminService} from '../../services/admin.service';
declare var $: any;

@Component({
  selector: 'app-announcement',
  templateUrl: './announcement.component.html',
  styleUrls: ['./announcement.component.css']
})
export class AnnouncementComponent implements OnInit {
  @ViewChild('announcementTable', { static: true }) private announcementTable!: APIDefinition;
  @ViewChild('announcementActionTpl', { static: true }) announcementActionTpl!: TemplateRef<any>;
  public announcementConfig: Config | any;
  public announcementColumns: Columns[] | any;
  announcementList: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  selectedRowData: any;
  modalTypeFn = 'add';
  content = '';
  status = false;
  announcementUpdate = false;

  constructor(
    private spinner: NgxSpinnerService,
    private adminService: AdminService
  ) { }

  ngOnInit(): void {
    this.announcementConfig = { ...DefaultConfig };
    this.announcementColumns = [
      { key: 'content', title: 'Content' },
      { key: 'active', title: 'Status' },
      { key: 'action', title: 'Actions', cellTemplate: this.announcementActionTpl }
    ];
    this.getAnnouncementList();
  }
  getAnnouncementList(): any {
    this.spinner.show();
    this.adminService.getAnnouncementService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        // console.log('GET ALL ANNOUNCEMENT');
        this.announcementList = res.announcements;
      }
    });
    /*this.announcementList = [
      {
        content: '111111 There is a problem currently with server response time and we are working on it...',
        status: false,
        id: 1
      },
      {
        content: '222222 There is a problem currently with server response time and we are working on it...',
        status: false,
        id: 2
      },
      {
        content: '333333 There is a problem currently with server response time and we are working on it...',
        status: false,
        id: 3
      }
    ];*/
  }
  onEvent(event: { event: string; value: any }): void {
    this.selectedRowData = event.value.row;
  }
  openAddAnnouncementModal( action: any): any {
    this.modalTypeFn = action;
    if (action === 'edit') {
      setTimeout(() => {
        this.content = this.selectedRowData.content;
        this.status = this.selectedRowData.active;
        this.announcementUpdate = true;
      }, 500);
    } else {
      this.content = '';
      this.status = false;
      this.selectedRowData = '';
      this.announcementUpdate = false;
    }
    $('#announcementAddModal').modal('toggle');
  }
  closeAnnouncementModal(): any {
    $('#announcementAddModal').modal('toggle');
    this.content = '';
    this.status = false;
    this.selectedRowData = '';
  }
  addEditAnnouncement(): any {
    if (!this.content) {
      this.Toast.fire({
        icon: 'error',
        title: 'Please provide content.'
      });
      return;
    }
    const payload = {
      content: this.content,
      active: this.status
    };
    console.log('payload---', payload);
    const ids = {
      update: this.announcementUpdate,
      id: this.selectedRowData.id
    };
    this.spinner.show();
    this.adminService.addUpdateAnnouncementService(payload, ids).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        console.log('hitting add/update');
        this.closeAnnouncementModal();
        this.getAnnouncementList();
      }
    });
  }
  deleteAnnouncement(rowIndex: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      customClass: {
        container: 'swalConfirmCls',
        confirmButton: 'btn btn-outline-danger',
        cancelButton: 'btn btn-outline-primary'
      },
      showCancelButton: true,
      confirmButtonColor: '#f96a74',
      cancelButtonColor: '#4f9d8d',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id: this.selectedRowData.id
        };
        this.spinner.show();
        this.adminService.deleteAnnouncementService(payload).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.getAnnouncementList();
            this.Toast.fire({
              icon: 'success',
              title: 'Deleted successfully'
            });
          }
        });
        // console.log('Deleted API not added...');
      }
    });
  }

}
