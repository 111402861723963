import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ReCaptcha2Component } from 'ngx-captcha';
import { NgxSpinnerService } from 'ngx-spinner';
import { RegistrationPayload } from 'src/types/services/authentication-service.types';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth.service';
import { AuthenticationService } from '../../services/authentication.service';

declare var $: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  styles: [
    `
      :host >>> .tooltip-inner {
        background-color: #009688;
        color: #fff;
      }
      :host >>> .tooltip.top .tooltip-arrow:before,
      :host >>> .tooltip.top .tooltip-arrow {
        border-top-color: #009688;
      }
    `,
  ],
})
export class RegisterComponent implements OnInit {
  regiForm: FormGroup | any;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio' = 'image';
  public useGlobalDomain = false;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;
  // @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component | undefined;
  siteKey: any = '6Ld3TVcUAAAAAGXDtehfiqoRpyzQEzkDGLtvH8qp';

  firstName = '';
  email = '';
  password = '';
  confirmPass = '';
  submitted = false;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private auth: AuthService,
    private spinner: NgxSpinnerService
  ) {}
  // convenience getter for easy access to form fields
  get f(): any {
    return this.regiForm.controls;
  }
  ngOnInit(): void {
    this.regiForm = this.fb.group({
      firstname: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(6)]],
      confirmpass: [null, [Validators.required, Validators.minLength(6)]],
      // recaptcha: ['', Validators.required],
      validate: '',
    });
  }
  onRegiSubmit(): any {
    this.submitted = true;
    if (this.regiForm.invalid) {
      return false;
    }
    if (this.password !== this.confirmPass) {
      alert('Password and confirm password not matched.');
      return false;
    }
    const payload: RegistrationPayload = {
      user: {
        full_name: this.firstName,
        email: this.email,
        password: this.password,
        password_confirmation: this.password,
      },
    };
    this.spinner.show();
    this.authService.registrationService(payload).subscribe((data: any) => {
      this.spinner.hide();

      if (data && !data.errors) {
        this.auth.sendToken(data.user);

        this.router.navigate(['/dashboard']);
      } else {
        let errorMsg = '';
        if (data.errors) {
          if (data.errors.email && data.errors.email.length > 0) {
            errorMsg = 'Email ' + data.errors.email[0];
          }
        }
        console.log('errors');
        this.Toast.fire({
          icon: 'error',
          title: errorMsg,
        });
      }
    });
  }

  openTermModal(): void {
    $('#termsModal').modal('toggle');
  }
}
