import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import {DashboardService} from '../../services/dashboard.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-viewticket',
  templateUrl: './viewticket.component.html',
  styleUrls: ['./viewticket.component.css']
})
export class ViewticketComponent implements OnInit {
  sub: any;
  paramsId: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  ticketDetailData: any;
  replyText = '';
  commentList: any = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private dashService: DashboardService,
    private spinner: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.sub = this.activatedRoute.params.subscribe(params => {
      if (params && params.id) {
        // console.log(params.id);
        this.paramsId = parseInt(params.id, 10);
        this.getTicketDetails(this.paramsId);
      }
    });
  }
  getTicketDetails(ticketId: any): any {
    // get details
    console.log('ticketId:---', ticketId);
    const payload = {
      id: ticketId
    };
    this.spinner.show();
    this.dashService.getTicketDetailService(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.ticketDetailData = res.ticket;
        // this.commentList = this.ticketDetailData.tickets_comments;
      }
    });
  }
  replyComment(): void {
    const payload = {
      id: this.paramsId,
      ticket_comment: this.replyText
    };
    this.spinner.show();
    this.dashService.saveTicketReplyService(payload).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        console.log(res);
        this.Toast.fire({
          icon: 'success',
          title: 'Sent successfully'
        });
        this.replyText = '';
        this.getTicketDetails(this.paramsId);
      }
    });
  }

}
