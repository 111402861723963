import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseMessage } from 'src/types/network/generic.types';
import { UserPayload } from 'src/types/services/users-service.types';
import { Links } from '../links.module';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  constructor(private http: HttpClient) {}

  addUpdateUserService(payload: any, payId: any): any {
    if (payId.update === false) {
      const url = Links.registerUrl;
      return this.http.post(url, payload).pipe(map((res: any) => res));
    } else {
      const url = Links.registerUrl + '/' + payId.id;
      return this.http.put(url, payload).pipe(map((res: any) => res));
    }
  }

  getAllUserService(): any {
    return this.http.get(Links.registerUrl).pipe(map((res: any) => res));
  }

  getActiveUserService(): any {
    /*return this.http.get(Links.supplyChainUrl + '/')
      .pipe(map((res: any) =>  res));*/
    return true;
  }

  getAnnouncementService(): any {
    return this.http.get(Links.announcements).pipe(map((res: any) => res));
  }

  addUpdateAnnouncementService(payload: any, payId: any): any {
    if (payId.update === false) {
      const url = Links.announcements;
      return this.http.post(url, payload).pipe(map((res: any) => res));
    } else {
      const url = Links.announcements + '/' + payId.id;
      return this.http.put(url, payload).pipe(map((res: any) => res));
    }
  }

  deleteAnnouncementService(payload: any): any {
    const deleteLink = `${Links.usersUrl}/create`;
    return this.http.delete(deleteLink).pipe(map((res: any) => res));
  }

  createUser(payload: UserPayload): Observable<ResponseMessage> {
    return this.http.post<ResponseMessage>(`${Links.usersUrl}/create`, payload);
  }

  editUser(userId: number, payload: UserPayload): Observable<ResponseMessage> {
    return this.http.put<ResponseMessage>(`${Links.usersUrl}/${userId}`, payload);
  }

  deleteUser(userId: number): Observable<ResponseMessage> {
    return this.http.delete<ResponseMessage>(`${Links.usersUrl}/${userId}`);
  }
}
