import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private readonly spinner: NgxSpinnerService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem('loginToken');
    if (token) {
      request = request.clone({
        setHeaders: { AUTHTOKEN: token },
      });
    }

    return next.handle(request).pipe(
      map(event => {
        if (event instanceof HttpResponse && event.body.key === 'SESSION_EXPIRED') {
          this.handleSessionExpired();
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  private handleSessionExpired(): void {
    Swal.fire({
      title: 'Session Expired',
      text: 'Your session has expired, please login again.',
      icon: 'warning',
      confirmButtonText: 'Ok',
    }).then(() => {
      localStorage.removeItem('loginToken');
      this.router.navigate(['/login']);
    });
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.authService.logout();
    } else {
      Swal.fire({
        icon: 'error',
        title: this.getErrorMessage(error),
        text: this.getErrorText(error),
        showConfirmButton: true,
      });
    }

    this.spinner.hide();
    console.error('Error:', error);
    return throwError(error);
  }

  private getErrorMessage(error: HttpErrorResponse): string {
    return error && error.error && error.error.error ? error.error.error : `Error ${error.status}`;
  }

  private getErrorText(error: HttpErrorResponse): string {
    return error && error.error && typeof error.error === 'string' ? error.error : error.message;
  }
}
