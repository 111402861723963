<div class="editInp">
  <div *ngIf="editing">
    <input class="inpFocus" style="width: 50px;"
      [required]="required"
      (blur)="onBlur($event)"
      [name]="value"
      [(ngModel)]="value"
      [placeholder]="label"
    />
  </div>
  <div *ngIf="!editing">
    <span>{{value}}</span>
    <a
      href="#"
      (click)="beginEdit(value, $event)"
      (focus)="beginEdit(value, $event)"
      tabindex="0"
    >
      &nbsp;<i class="fa fa-pencil"></i></a
    >
  </div>
</div>
