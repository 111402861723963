<ngx-spinner type = "ball-clip-rotate-multiple"></ngx-spinner>
<div class="libraryCls container-custom">
  <div class="row">
    <div class="col-12">
      <div class="ticketTable">
        <h4> Support Ticket
          <!--<a (click)="openAddModal('add')" class="btn btn-primary addButton">
            <i class="fa fa-plus" aria-hidden="true"></i>
            Add New
          </a>-->
        </h4>
        <!--<p>After importing you can go to your Account page and begin editing them.</p>-->
        <ngx-table #tickettable [configuration]="ticketConfig"
                   [data]="ticketData"
                   [columns]="ticketColumns"
                   (event)="onEvent($event)">
        </ngx-table>

        <ng-template #actionTpl let-row let-rowIndex="rowIndex">
          <div class="btn btn-sm btn-outline-primary waves-effect waves-light" (click)="viewTicket()">View</div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
