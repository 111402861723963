import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
declare var $: any;

const VALUE_ACCESSOR = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => InlineEditTableComponent),
  multi: true
};

@Component({
  selector: 'app-inline-edit-table',
  templateUrl: './inline-edit-table.component.html',
  providers: [VALUE_ACCESSOR],
  styleUrls: ['./inline-edit-table.component.css']
})
export class InlineEditTableComponent implements ControlValueAccessor {
  @Input() label = 'Enter value here';
  @Input() required = true;
  private value1 = '';
  private preValue = '';
  public editing = false;
  public onChange: any = Function.prototype;
  public onTouched: any = Function.prototype;
  @Output() emitBlurEvent = new EventEmitter();
  @Output() emitOnEditEvent = new EventEmitter();
  constructor() { }

  get value(): any {
    return this.value1;
  }

  set value(v: any) {
    if (v !== this.value1) {
      this.value1 = v;
      this.onChange(v);
    }
  }

  writeValue(value: any): void {
    this.value1 = value;
  }

  public registerOnChange(fn: (_: any) => {}): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => {}): void {
    this.onTouched = fn;
  }

  onBlur($event: Event): void {
    this.editing = false;
    if ( this.value1 === ''){
      this.value1 = 'No value available';
    } else {
      console.log(this.value1);
    }
    this.emitBlurEvent.emit();
  }

  beginEdit(value: any, e: any): void {
    this.preValue = value;
    this.editing = true;
    setTimeout(() => {
      $('.inpFocus').focus();
    }, 300);
    this.emitOnEditEvent.emit();
  }

}
