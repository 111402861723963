import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SupplyChain } from 'src/types/services/supply-chain-service.types';
import { Links } from '../links.module';

@Injectable({
  providedIn: 'root',
})
export class SupplyChainService {
  constructor(private readonly httpClient: HttpClient) {}

  getFacilitiesBySupplyChainID(supplyChainID: number): Observable<any> {
    return this.httpClient.get(`${Links.supplyChainUrl}/${supplyChainID}/facilities`);
  }

  getSupplyChainByID(supplyChainID: number): Observable<SupplyChain> {
    return this.httpClient.get<SupplyChain>(`${Links.supplyChainUrl}/${supplyChainID}`);
  }
}
