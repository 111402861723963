<!--<div class="header">
  <div class="container">
    <div class="topnavbar">
      <nav class="navbar navbar-expand-lg navbar-light bg-none plr-0">
        <a class="navbar-brand">SCM Globe</a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item active">
              <a class="nav-link" href="https://www.scmglobe.com/scm-globe-services/" target="_blank">Services</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.scmglobe.com/?page_id=1485" target="_blank">Blog</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.scmglobe.com/scm-globe-pricing/" target="_blank">Pricing</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="https://www.scmglobe.com/contact-us/" target="_blank">Contact Us</a>
            </li>
            <li class="nav-item">
              <a class="nav-link pointer" (click)="logoutCall()">Logout</a>
            </li>
            <li class="nav-item dropdown">
              <div class="form-group">
                <select class="form-control" id="exampleFormControlSelect1">
                  <option>English</option>
                  <option>English</option>
                  <option>English</option>
                  <option>English</option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</div>-->

<app-application-header></app-application-header>

<div>
  <router-outlet></router-outlet>
</div>
<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <p class="copyright-text">
          Copyright (C) 2024 <a href="https://www.scmglobe.com/">SCM Globe Corporation</a> All rights reserved
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <a href="#" (click)="openBugReport($event)">Open a Bug Report</a>
      </div>
    </div>
  </div>
</footer>