<ngx-spinner type="ball-clip-rotate-multiple"></ngx-spinner>
<div class="login-page">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="loginBox">
          <div class="card">
            <article class="card-body">
              <h4 class="card-title text-center mb-4 mt-1">Login</h4>
              <hr>
              <!--<p class="text-success text-center">Some message goes here</p>-->
              <form [formGroup]="loginForm" (submit)="onLoginSubmit()" autocomplete="off">
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"> <i class="fa fa-user"></i> </span>
                    </div>
                    <input [(ngModel)]="email" formControlName="email" class="form-control" placeholder="Email"
                      type="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }">
                    <div *ngIf="loginForm.get('email') && ( loginForm.get('email')?.touched || loginForm.get('email')?.dirty ) && loginForm.get('email')?.invalid && loginForm.get('email')?.errors" class="errors">
                      <div *ngIf="f.email.errors.required">Email is required</div>
                      <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                  </div> <!-- input-group.// -->
                </div> <!-- form-group// -->
                <div class="form-group">
                  <div class="input-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"> <i class="fa fa-lock"></i> </span>
                    </div>
                    <input [(ngModel)]="password" formControlName="password" class="form-control" placeholder="******"
                      type="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }">
                    <div *ngIf="loginForm.get('password') && ( loginForm.get('password')?.touched || loginForm.get('password')?.dirty ) && loginForm.get('password')?.invalid && loginForm.get('password')?.errors" class="errors">
                      <div *ngIf="f.password.errors.required">password is required</div>
                    </div>
                  </div> <!-- input-group.// -->
                </div> <!-- form-group// -->
                <div class="form-group">
                  <button type="submit" class="btn btn-primary btn-block"> Login </button>
                  <!--<div><a class="pointer" >Purchase New Account</a></div>
                  <div><a class="pointer" >Activate Prepaid Account</a></div>-->
                </div> <!-- form-group// -->
                <div class="clearfix"></div>
                <div class="login-actions d-flex justify-content-center">
                  <p class="text-center"><a [routerLink]="['/register']">Create an account</a></p>
                  <span>&nbsp;|&nbsp;</span>
                  <p class="text-center"><a [routerLink]="['/forgotpassword']">Forgot password?</a></p>
                </div>
              </form>
            </article>
          </div> <!-- card.// -->
        </div>
      </div>
    </div>
  </div>
</div>
