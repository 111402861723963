import {ChangeDetectorRef, Component, ElementRef, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {APIDefinition, Columns, Config, DefaultConfig} from 'ngx-easy-table';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {DashboardService} from '../../services/dashboard.service';
import * as moment from '../dashboard/dashboard.component';
import {SimulationService} from '../../services/simulation.service';
declare var $: any;

@Component({
  selector: 'app-facilityvehicletype',
  templateUrl: './facilityvehicletype.component.html',
  styleUrls: ['./facilityvehicletype.component.css'],
})
export class FacilityvehicletypeComponent implements OnInit {
  @ViewChild('table', { static: true }) private table!: APIDefinition;
  @ViewChild('actionTpl', { static: true }) actionTpl!: TemplateRef<any>;
  @ViewChild('imgTpl', { static: true }) imgTpl!: TemplateRef<any>;
  public facilityTypeConfig: Config | any;
  public columns: Columns[] | any;
  facilityTypeData: any;
  @ViewChild('vehicleTable', { static: true }) private vehicleTable!: APIDefinition;
  @ViewChild('vehicleActionTpl', { static: true }) vehicleActionTpl!: TemplateRef<any>;
  public vehicleTypeConfig: Config | any;
  public vehicleColumns: Columns[] | any;
  vehicleTypeData: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef | any;
  typeName = '';
  modalType: any;
  imagePreview: any;
  modalTypeFn: any;
  selectedRowData: any;
  transportMethod: any = true; // Default value to "By Road"
  speed: any = 0;
  carryVolume: any = 0;
  maxWeight: any = 0;
  costPerKm: any = 0;
  carbonPerKm: any = 0;

  storageCapacity: any = 0;
  totalArea: any = 0;
  laborCost: any = 0;
  energyCost: any = 0;
  optCost: any = 0;
  rentCost: any = 0;
  carbon: any = 0;

  constructor(
    private spinner: NgxSpinnerService,
    private simulationService: SimulationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.facilityTypeConfig = { ...DefaultConfig };
    this.columns = [
      { key: 'name', title: 'Name' },
      { key: 'icon', title: 'Icon', cellTemplate: this.imgTpl },
      { key: 'action', title: 'Actions', cellTemplate: this.actionTpl },
    ];
    this.vehicleTypeConfig = { ...DefaultConfig };
    this.vehicleColumns = [
      { key: 'name', title: 'Name' },
      { key: 'icon', title: 'Icon', cellTemplate: this.imgTpl },
      { key: 'action', title: 'Actions', cellTemplate: this.vehicleActionTpl },
    ];
    this.getFacilityTypeList();
    this.getVehicleTypeList();
  }
  getFacilityTypeList(): void {
    this.spinner.show();
    this.simulationService.getFacilityTypesService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.facilityTypeData = res.facility_types;
        this.cdr.detectChanges();
      }
    });
  }
  getVehicleTypeList(): void {
    this.spinner.show();
    this.simulationService.getVehicleTypesService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.vehicleTypeData = res.vehicle_types;
        this.cdr.detectChanges();
      }
    });
  }
  onEvent(event: { event: string; value: any }, type: any): void {
    this.selectedRowData = event.value.row;
    this.typeName = this.selectedRowData.name;
    this.imagePreview = this.selectedRowData.icon;
    if (type === 'vehicle') {
      this.transportMethod = this.selectedRowData?.route_draw_type;
      this.speed = this.selectedRowData.speed;
      this.carryVolume = this.selectedRowData.max_volume;
      this.maxWeight = this.selectedRowData.max_weight;
      this.costPerKm = this.selectedRowData.cost_per_distance;
      this.carbonPerKm = this.selectedRowData.carbon_output;
    } else if (type) {
      this.storageCapacity = this.selectedRowData?.storage_capacity;
      this.totalArea = this.selectedRowData?.total_area;
      this.laborCost = this.selectedRowData?.labor_cost / 60;
      this.energyCost = this.selectedRowData?.energy_cost / 60;
      this.optCost = this.selectedRowData.operation_cost / 60;
      this.rentCost = this.selectedRowData.rent_cost / 60;
      this.carbon = this.selectedRowData.carbon_output / 60;
    }
  }
  openTypeAddModal(type: any, fn: any, index: any): any {
    if (fn === 'add') {
      this.modalType = type;
      this.modalTypeFn = fn;
      if (type === 'vehicle') {
        this.typeName = '';
        this.transportMethod = true; // Default value to "By Road"
        this.speed = 0;
        this.carryVolume = 0;
        this.maxWeight = 0;
        this.costPerKm = 0;
        this.carbonPerKm = 0;
      } else if (type === 'facility') {
        this.storageCapacity = 0;
        this.totalArea = 0;
        this.laborCost = 0;
        this.energyCost = 0;
        this.optCost = 0;
        this.rentCost = 0;
        this.carbon = 0;
      }
      $('#typeAddModal').modal('toggle');
    } else if (fn === 'edit') {
      this.modalType = type;
      this.modalTypeFn = fn;
      $('#typeAddModal').modal('toggle');
      // get data by id
    } else {
      this.modalType = '';
      this.modalTypeFn = '';
    }
  }
  closeTypeAddModal(): any {
    $('#typeAddModal').modal('toggle');
    this.modalType = '';
    this.modalTypeFn = '';
    this.imagePreview = '';
    this.selectedRowData = '';
  }
  preview(): any {
    // this.imagePreview = URL.createObjectURL(this.fileUpload.nativeElement.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      // const output = document.getElementById('imgP');
      this.imagePreview = reader.result;
    };
    reader.readAsDataURL(this.fileUpload.nativeElement.files[0]);
  }
  addType(type: any, call: any): any {
    if (this.fileUpload.nativeElement.files?.length <= 0 && !this.imagePreview) {
      this.Toast.fire({
        icon: 'warning',
        text: 'Please upload image/icon file.',
      });
    }
    const formData = new FormData();
    if (type === 'facility') {
      for (const file of this.fileUpload.nativeElement.files) {
        formData.append('facility_type[icon]', file);
      }
      formData.append('facility_type[name]', this.typeName);
      formData.append('facility_type[storage_capacity]', this.storageCapacity);
      formData.append('facility_type[total_area]', String(this.totalArea));
      formData.append('facility_type[labor_cost]', this.laborCost);
      formData.append('facility_type[energy_cost]', this.energyCost);
      formData.append('facility_type[operation_cost]', this.optCost);
      formData.append('facility_type[rent_cost]', this.rentCost);
      formData.append('facility_type[carbon_output]', this.carbon);
      let id = '';
      if (this.selectedRowData && this.selectedRowData.id) {
        id = this.selectedRowData.id;
      }
      this.spinner.show();
      this.simulationService.faciTypeAddUpdateService(formData, call, id).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.modalType = '';
          this.modalTypeFn = '';
          this.imagePreview = '';
          this.fileUpload.nativeElement.value = '';
          this.Toast.fire({
            icon: 'success',
            text: 'Successfully.',
          });
          this.closeTypeAddModal();
          this.getFacilityTypeList();
        }
      });
    } else if (type === 'vehicle') {
      for (const file of this.fileUpload.nativeElement.files) {
        formData.append('vehicle_type[icon]', file);
      }
      formData.append('vehicle_type[name]', this.typeName);
      formData.append('vehicle_type[route_draw_type]', String(this.transportMethod));
      formData.append('vehicle_type[speed]', this.speed);
      formData.append('vehicle_type[max_volume]', this.carryVolume);
      formData.append('vehicle_type[max_weight]', this.maxWeight);
      formData.append('vehicle_type[cost_per_distance]', this.costPerKm);
      formData.append('vehicle_type[carbon_output]', this.carbonPerKm);
      let id = '';
      if (this.selectedRowData && this.selectedRowData.id) {
        id = this.selectedRowData.id;
      }
      this.spinner.show();
      this.simulationService.vehicleTypeAddUpdateService(formData, call, id).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.modalType = '';
          this.modalTypeFn = '';
          this.imagePreview = '';
          this.fileUpload.nativeElement.value = '';
          this.Toast.fire({
            icon: 'success',
            text: 'Successfully.',
          });
          this.closeTypeAddModal();
          this.getVehicleTypeList();
        }
      });
    }
  }
  editFacilityType(rowIndex: number): void {
    if (this.facilityTypeData && this.facilityTypeData[rowIndex].id) {
      // test edit
    }
  }
  deleteType(type: any, rowIndex: number): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      customClass: {
        container: 'swalConfirmCls',
        confirmButton: 'btn btn-outline-danger',
        cancelButton: 'btn btn-outline-primary',
      },
      showCancelButton: true,
      confirmButtonColor: '#f96a74',
      cancelButtonColor: '#4f9d8d',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        const payload = {
          id: this.selectedRowData.id,
          typeof: type,
        };
        this.spinner.show();
        this.simulationService.deleteTypeService(payload).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            if (type === 'facility') {
              this.getFacilityTypeList();
            } else {
              this.getVehicleTypeList();
            }
            this.Toast.fire({
              icon: 'success',
              title: 'Deleted successfully',
            });
          }
        });
      }
    });
  }
}
