import {ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {APIDefinition, Columns, Config, DefaultConfig} from 'ngx-easy-table';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {SimulationService} from '../../services/simulation.service';
import {DashboardService} from '../../services/dashboard.service';
import * as moment from 'moment';
declare var $: any;

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.css']
})
export class LibraryComponent implements OnInit {
  @ViewChild('librarytable', { static: true }) private librarytable!: APIDefinition;
  @ViewChild('actionTpl', { static: true }) actionTpl!: TemplateRef<any>;
  public libraryConfig: Config | any;
  public libraryColumns: Columns[] | any;
  libraryData: any = [];
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  selectedRowData: any;
  libraryName = '';

  constructor(
    private spinner: NgxSpinnerService,
    private dashService: DashboardService,
    private cdr: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.libraryConfig = { ...DefaultConfig };
    this.libraryColumns = [
      { key: 'name', title: 'Name' },
      { key: 'created_at', title: 'Date' },
      { key: 'action', title: 'Actions', cellTemplate: this.actionTpl },
    ];
    this.getLibraryData();
  }
  getLibraryData(): void {
    // get data
    this.spinner.show();
    this.dashService.getLibraryListService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.libraryData = res.libraries;
        this.libraryData.forEach((library: any) => {
          library.created_at = moment(library.created_at).format('MMMM D, YYYY h:mm A');
        });
      }
    });
  }
  onEvent(event: { event: string; value: any }): void {
    this.selectedRowData = event.value.row;
  }
  openImportModal(): void {
    this.libraryName = '';
    $('#libraryImportModal').modal('toggle');
  }
  importLibrary(): void {
    if (this.libraryName) {
      const payload = {
        id: this.selectedRowData.id,
        name: this.libraryName
      };
      this.spinner.show();
      this.dashService.importLibraryService(payload).subscribe((res: any) => {
        this.spinner.hide();
        if (res) {
          this.Toast.fire({
            icon: 'success',
            title: 'Imported successfully'
          });
          $('#libraryImportModal').modal('toggle');
        }
      });
    }
  }
  deleteLibrary(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      customClass: {
        container: 'swalConfirmCls',
        confirmButton: 'btn btn-outline-danger',
        cancelButton: 'btn btn-outline-primary'
      },
      showCancelButton: true,
      confirmButtonColor: '#f96a74',
      cancelButtonColor: '#4f9d8d',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          id: this.selectedRowData.id,
        };
        this.spinner.show();
        this.dashService.deleteLibraryService(payload).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.getLibraryData();
            this.Toast.fire({
              icon: 'success',
              title: 'Deleted successfully'
            });
          }
        });
      }
    });
  }
  isAdmin(): any {
    if (localStorage.getItem('userRole')) {
      if (localStorage.getItem('userRole') === 'admin') {
        return true;
      } else {
        return false;
      }
    }
  }

}
