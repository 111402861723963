import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {APIDefinition, Columns, Config, DefaultConfig} from 'ngx-easy-table';
import Swal from 'sweetalert2';
import {NgxSpinnerService} from 'ngx-spinner';
import {SimulationService} from '../../services/simulation.service';
declare var $: any;

@Component({
  selector: 'app-timeinterval',
  templateUrl: './timeinterval.component.html',
  styleUrls: ['./timeinterval.component.css']
})
export class TimeintervalComponent implements OnInit {
  @ViewChild('table', { static: true }) private table!: APIDefinition;
  @ViewChild('actionTpl', { static: true }) actionTpl!: TemplateRef<any>;
  public intervalConfig: Config | any;
  public intervalcolumns: Columns[] | any;
  intervalData: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  selectedRowData: any;
  name = '';
  value = '';
  modalTypeFn: any;

  constructor(
    private spinner: NgxSpinnerService,
    private simulationService: SimulationService,
  ) { }

  ngOnInit(): void {
    this.intervalConfig = { ...DefaultConfig };
    this.intervalcolumns = [
      { key: 'name', title: 'Name' },
      { key: 'value', title: 'Interval (In Minutes)' },
      { key: 'action', title: 'Actions', cellTemplate: this.actionTpl },
    ];
    this.getTimeInterval();
  }
  getTimeInterval(): any {
    this.spinner.show();
    this.simulationService.getTimeIntervalDataService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.intervalData = res.time_intervals;
      }
    });
  }
  onEvent(event: { event: string; value: any }): void {
    this.selectedRowData = event.value.row;
    this.name = this.selectedRowData.name;
    this.value = this.selectedRowData.value;
  }
  openIntervalAddModal(type: any): any {
    this.name = '';
    this.value = '';
    if (type === 'add') {
      this.modalTypeFn = type;
      $('#IntervalAddModal').modal('toggle');
    } else if (type === 'edit') {
      this.modalTypeFn = type;
      $('#IntervalAddModal').modal('toggle');
      // get data by id
    } else {
      this.modalTypeFn = '';
    }
  }
  closeIntervalAddModal(): any {
    $('#IntervalAddModal').modal('toggle');
    this.modalTypeFn = '';
    this.selectedRowData = '';
  }
  addUpdateInterval(type: any): any {
    const payload = {
      name: this.name,
      value: this.value
    };
    let id = '';
    if (type === 'edit') {
      id = this.selectedRowData.id;
    }
    this.spinner.show();
    this.simulationService.addUpdateTimeIntervalService(payload, type, id).subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.getTimeInterval();
        this.Toast.fire({
          icon: 'success',
          text: 'Successfully.'
        });
        $('#IntervalAddModal').modal('toggle');
      }
    });
  }
  deleteInterval(): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      customClass: {
        container: 'swalConfirmCls',
        confirmButton: 'btn btn-outline-danger',
        cancelButton: 'btn btn-outline-primary'
      },
      showCancelButton: true,
      confirmButtonColor: '#f96a74',
      cancelButtonColor: '#4f9d8d',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner.show();
        this.simulationService.deleteIntervalService(this.selectedRowData.id).subscribe((res: any) => {
          this.spinner.hide();
          if (res) {
            this.getTimeInterval();
            this.Toast.fire({
              icon: 'success',
              text: 'Deleted Successfully.'
            });
          }
        });
      }
    });
  }

}
