import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {AuthService} from './auth.service';
import {map} from 'rxjs/operators';

@Injectable({providedIn: 'root'})
export class AiSuggestService {
  constructor(
    private auth: AuthService,
    private http: HttpClient,
  ){}
  host = 'https://engine-services.staging.scmglobe.com'; // need env variable for this.
  suggestUrl = `${this.host}/suggest`;
  simSuggestUrl = `${this.host}/suggest_sim`;

  /**
   * 
   * @param request_model_id number - id of the simulation running in the browser
   * @param name string - name of the simulation running in the browser
   */
  aiSuggest(id: number, name: string): any {
    const token = this.auth.getToken();
    if (!token) {
      return new Error('Not authorized, please refresh browser or login again');
    }
    if (!id) {
      return new Error('No supply chain id');
    }
    if (!name) {
      return new Error('No supply chain name');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
    });
    return this.http.post(
      this.suggestUrl, 
      {
        request_model_id: id, 
        name
      }, 
      {
        headers
      },
    ).pipe(map((res: any) => res));
  }

  /**
   * 
   * @param simulation_id number - id of the simulation running in the browser
   * @param errors string - list of errors the simulation returned due to supply chain issues
   */
  aiSimSuggest(id: number, errors: string): any {
    const token = this.auth.getToken();
    if (!token) {
      return new Error('Not authorized, please refresh browser or login again');
    }
    if (!id) {
      return new Error('No supply chain id');
    }
    if (!errors) {
      return new Error('No errors with supply chain');
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*',
    });
    return this.http.post(
      this.simSuggestUrl, 
      {
        simulation_id: id,
        errors
      }, 
      {
        headers
      },
    ).pipe(map((res: any) => res));
  }
}
