import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { API, APIDefinition, Columns, Config, DefaultConfig } from 'ngx-easy-table';
import { NgxSpinnerService } from 'ngx-spinner';
import { OpenAddUserModalType } from 'src/types/components/users.types';
import { RoleName, UserDto } from 'src/types/dtos/user.types';
import { ResponseMessage } from 'src/types/network/generic.types';
import { UserPayload } from 'src/types/services/users-service.types';
import Swal, { SweetAlertOptions } from 'sweetalert2';
import { AdminService } from '../../services/admin.service';
import { getExpirationDate, validateUserData } from './data/create-user.data';
declare var $: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  @ViewChild('allUsersTable', { static: true }) private allUsersTable!: APIDefinition;
  @ViewChild('allUsersActionTpl', { static: true }) allUsersActionTpl!: TemplateRef<any>;
  public allUsersConfig: Config | any;
  public allUserColumns: Columns[] | any;
  allUserList: any;
  @ViewChild('ActiveUsersTable', { static: true }) private ActiveUsersTable!: APIDefinition;
  @ViewChild('activeUsersActionTpl', { static: true }) activeUsersActionTpl!: TemplateRef<any>;
  public activeUsersConfig: Config | any;
  public activeUserColumns: Columns[] | any;
  activeUserList: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: toast => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    },
  });
  selectedRowData!: UserDto;
  modalTypeFn: OpenAddUserModalType = 'add';
  userName = '';
  userEmail = '';
  userExpireDate: any = new Date();
  userRole: RoleName = RoleName.Admin;
  userPassword: null | string = null;
  roleList = ['admin', 'user', 'professor'];
  noExpiration = false;
  isProUser = false;
  updateUser = false;

  constructor(
    private spinner: NgxSpinnerService,
    private readonly adminService: AdminService
  ) {}

  ngOnInit(): void {
    this.allUsersConfig = { ...DefaultConfig };
    this.allUserColumns = [
      { key: 'email', title: 'Email' },
      { key: 'full_name', title: 'Name' },
      { key: 'expiration_date', title: 'Expires' },
      { key: 'action', title: 'Actions', cellTemplate: this.allUsersActionTpl },
    ];
    this.activeUsersConfig = { ...DefaultConfig };
    this.activeUserColumns = [
      { key: 'email', title: 'Email' },
      { key: 'full_name', title: 'Name' },
      { key: 'expiration_date', title: 'Expires' },
      { key: 'action', title: 'Actions', cellTemplate: this.activeUsersActionTpl },
    ];
    this.getAllUserList();
    this.getActivateUserList();
  }
  getAllUserList(): void {
    this.spinner.show();
    this.adminService.getAllUserService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        this.allUserList = res.users;
        // get active users (no_expiration=true)
        this.activeUserList = this.allUserList.filter((user: any) => user.no_expiration === true);
      }
    });
    /*this.allUserList = [
      {
        user_email: 'anu@gmail.com',
        user_name: 'Anu Gangede1',
        user_expires: '2020-08-22',
        id: 1
      },
      {
        user_email: 'anu@gmail.com',
        user_name: 'Anu Gangede2',
        user_expires: '2020-08-22',
        id: 2
      },
      {
        user_email: 'anu@gmail.com',
        user_name: 'Anu Gangede3',
        user_expires: '2020-08-22',
        id: 3
      }
    ];*/
  }
  getActivateUserList(): void {
    /*this.spinner.show();
    this.adminService.getActiveUserService().subscribe((res: any) => {
      this.spinner.hide();
      if (res) {
        console.log('GET ACTIVE USERS');
      }
    });*/
    /*this.activeUserList = [
      {
        user_email: 'anu@gmail.com',
        user_name: 'Anu Gangede1',
        user_expires: '2020-08-22',
        id: 1
      },
      {
        user_email: 'anu@gmail.com',
        user_name: 'Anu Gangede2',
        user_expires: '2020-08-22',
        id: 2
      },
      {
        user_email: 'anu@gmail.com',
        user_name: 'Anu Gangede3',
        user_expires: '2020-08-22',
        id: 3
      }
    ];*/
  }
  onChange(event: Event): void {
    this.allUsersTable.apiEvent({
      type: API.onGlobalSearch,
      value: (event.target as HTMLInputElement).value,
    });
  }
  onChangeActive(event: Event): void {
    this.ActiveUsersTable.apiEvent({
      type: API.onGlobalSearch,
      value: (event.target as HTMLInputElement).value,
    });
  }
  onEvent(event: { event: string; value: any }): void {
    this.selectedRowData = event.value.row;
  }

  openAddUserModal(action: OpenAddUserModalType): void {
    this.modalTypeFn = action;

    setTimeout(() => {
      if (action === 'add') {
        this.userName = '';
        this.userEmail = '';
        this.userExpireDate = new Date();
        this.userPassword = null;
        this.userRole = RoleName.Admin;
        this.noExpiration = false;
        this.updateUser = false;
      }

      if (action === 'edit') {
        this.userName = this.selectedRowData.full_name;
        this.userEmail = this.selectedRowData.email;
        this.noExpiration = this.selectedRowData.no_expiration;
        this.userExpireDate = this.selectedRowData.expiration_date
          ? new Date(this.selectedRowData.expiration_date)
          : null;
        this.userPassword = this.selectedRowData.password;
        this.userRole = this.selectedRowData.role;
        this.updateUser = true;
      }

      $('#userAddModal').modal('toggle');
    }, 300);
  }

  closeUserAddModal(): any {
    this.userName = '';
    this.userEmail = '';
    this.userExpireDate = new Date();
    this.userPassword = '';
    // this.isProUser = this.selectedRowData.id;
    this.noExpiration = false;
    this.updateUser = false;
    $('#userAddModal').modal('toggle');
  }

  changeRole(role: RoleName): void {
    this.userRole = role;
  }

  addEditUser(): void {
    const payload: UserPayload = {
      user: {
        full_name: this.userName,
        email: this.userEmail,
        password: this.userPassword,
        password_confirmation: this.userPassword,
        expiration_date: getExpirationDate(this.noExpiration, this.userExpireDate),
        no_expiration: this.noExpiration,
        role: this.userRole,
      },
    };

    const { success, message } = validateUserData(payload, this.modalTypeFn);

    if (!success && message) {
      this.Toast.fire({
        icon: 'error',
        title: message,
      });

      return;
    }

    this.spinner.show();

    if (this.modalTypeFn === 'add') {
      this.adminService.createUser(payload).subscribe({
        next: () => {
          this.Toast.fire({
            icon: 'success',
            title: 'User was created successfully',
          });

          this.getAllUserList();
        },
        complete: () => {
          this.spinner.hide();
          this.closeUserAddModal();
        },
        error: err => {
          this.spinner.hide();
          this.closeUserAddModal();

          console.error('Error creating a new user:', err);

          return this.Toast.fire({
            icon: 'error',
            title: 'Error creating a new user. Please try again',
          });
        },
      });
    }

    if (this.modalTypeFn === 'edit') {
      const userId = this.selectedRowData.id;

      this.adminService.editUser(userId, payload).subscribe({
        next: res => {
          console.log(res);
          this.Toast.fire({
            icon: 'success',
            title: 'User was edited successfully',
          });

          this.getAllUserList();
        },
        complete: () => {
          this.spinner.hide();
          this.closeUserAddModal();
        },
        error: (response: HttpErrorResponse) => {
          const { error, status } = response;

          this.spinner.hide();
          this.closeUserAddModal();

          console.error('Error editing user:', response);

          return this.Toast.fire({
            icon: 'error',
            title: status !== 500 ? error.message : 'Error editing new user. Please try again',
          });
        },
      });
    }
  }

  deleteUser(user: UserDto): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You won\u0027t be able to revert this!',
      icon: 'warning',
      customClass: {
        container: 'swalConfirmCls',
        confirmButton: 'btn btn-outline-danger',
        cancelButton: 'btn btn-outline-primary',
      },
      showCancelButton: true,
      confirmButtonColor: '#f96a74',
      cancelButtonColor: '#4f9d8d',
      confirmButtonText: 'Yes, delete it!',
    }).then(result => {
      if (result.isConfirmed) {
        this.spinner.show();

        const userId = user.id;

        this.adminService.deleteUser(userId).subscribe({
          next: ({ success }: ResponseMessage) => {
            const toastPayload: SweetAlertOptions = {
              icon: success ? 'success' : 'error',
              title: success ? 'Deleted successfully' : 'Failed to delete user',
            };

            if (success) {
              this.getAllUserList();
            }

            this.Toast.fire(toastPayload);
          },
          complete: () => {
            this.spinner.hide();
          },
          error: (response: HttpErrorResponse) => {
            const { error, status } = response;

            this.spinner.hide();
            this.closeUserAddModal();

            console.error('Error deleting user:', response);

            return this.Toast.fire({
              icon: 'error',
              title: status !== 500 ? error.message : 'Error deleting user. Please try again',
            });
          },
        });
      }
    });
  }
}
