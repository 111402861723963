import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-application-header',
  templateUrl: './application-header.component.html',
  styleUrls: ['./application-header.component.css'],
})
export class ApplicationHeaderComponent implements OnInit {
  public isOpen = false;
  public activeLink!: string;
  public showInternalItems = true;
  public showPublicItems = false;
  public showAdminItems = false;
  private readonly publicUrls: Array<string> = ['/login', '/register', '/forgotpassword'];
  public selectedPage = '';

  constructor(
    private router: Router,
    private eRef: ElementRef,
    private readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.isOpen = false;
        // TODO: Retrieve information from API
        const role = localStorage.getItem('userRole');

        this.showInternalItems = !this.publicUrls.includes(this.router.url);
        this.showPublicItems = this.publicUrls.includes(this.router.url);
        this.showAdminItems = role === 'admin';
        this.selectedPage = this.router.url.includes('/supplyChain/') ? '- EDIT -' : this.router.url.includes('/simulation/') ? '- SIMULATE -' : '';

        this.activeLink = this.router.url;
      }
    });
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }

  @HostListener('document:click', ['$event'])
  closeDropdown(event: MouseEvent): void {
    const settingsElement = this.eRef.nativeElement.querySelector('#settings-dropdown');

    if (this.showInternalItems && this.isOpen) {
      if (!settingsElement.contains(event.target)) {
        this.isOpen = false;
      }
    }
  }

  handleLogout(): void {
    this.authService.logout();
  }
}
