import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import Swal from 'sweetalert2';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthenticationService} from '../../services/authentication.service';
import {AuthService} from '../../services/auth.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.css']
})
export class ForgotpasswordComponent implements OnInit {
  forgotForm: FormGroup | any;
  email: any;
  error: any;
  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  });
  submitted = false;
  emailSentSuccess = false;

  constructor(
    public router: Router,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthenticationService,
    private auth: AuthService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.forgotForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      validate: ''
    });
  }
  // convenience getter for easy access to form fields
  get f(): any { return this.forgotForm.controls; }
  onSubmit(): any {
    this.submitted = true;
    if (this.forgotForm.invalid) {
      return;
    }
    if (this.email === undefined) {
      return false;
    } else {
      const payload = {
        email: this.email
      };
      this.spinner.show();
      this.authService.forgotPassService(payload).subscribe((data: any) => {
        this.spinner.hide();
        if (data && !data.errors) {
          this.emailSentSuccess = true;
        } else {
          console.log('errors');
          this.Toast.fire({
            icon: 'error',
            title: data.errors
          });
        }
      });
    }
  }

}
