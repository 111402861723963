import { environment } from '../environments/environment';


export class Links {
  /***********dev*************/
  public static baseUrl = environment.baseUrl;
  public static loginUrl = Links.baseUrl + '/api/v1/users/sign_in';
  public static registerUrl = Links.baseUrl + '/api/v1/users';
  public static forgotPass = Links.baseUrl + '/api/v1/users/password';
  public static supplyChainUrl = Links.baseUrl + '/api/v1/supply_chains';
  public static supplyChainHistoryUrl = Links.baseUrl + '/api/v1/supply_chain_histories';
  public static facilityTypes = Links.baseUrl + '/api/v1/facility_types';
  public static vehicleTypes = Links.baseUrl + '/api/v1/vehicle_types';
  public static libraryUrl = Links.baseUrl + '/api/v1/supply_chains/library';
  public static timeInverval = Links.baseUrl + '/api/v1/time_intervals';
  public static simulationUrl = Links.baseUrl + '/api/v1/simulation';
  public static ticketsUrl = Links.baseUrl + '/api/v1/tickets';
  public static announcements = Links.baseUrl + '/api/v1/announcements';
  public static usersUrl = Links.baseUrl + '/api/v1/users';
  public static aiSuggestUrl = Links.baseUrl + '/api/v1/supply_chains/suggest';
  /***********dev**************/
}
